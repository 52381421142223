<mat-chip-listbox class="mat-mdc-chip-set-stacked" aria-label="Color selection">
    <mat-chip-option class="tagus">
        Tagus
    </mat-chip-option>
    <mat-chip-option color="primary" selected>
        Primary
    </mat-chip-option>
    <mat-chip-option color="accent">
        Accent
    </mat-chip-option>
    <mat-chip-option color="warn">
        Warn
    </mat-chip-option>
</mat-chip-listbox>