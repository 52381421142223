<div class="chart">
    <apx-chart
        [series]="chartOptions.series!"
        [chart]="chartOptions.chart!"
        [legend]="chartOptions.legend!"
        [labels]="chartOptions.labels!"
        [tooltip]="chartOptions.tooltip!"
        [dataLabels]="chartOptions.dataLabels!"
        [stroke]="chartOptions.stroke!"
        [colors]="chartOptions.colors!"
        [responsive]="chartOptions.responsive!"
    ></apx-chart>
</div>