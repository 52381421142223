<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Gallery</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Gallery</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Gallery - 1</h5>
    </mat-card-header>
    <mat-card-content>
        <div class="row gallery-list">
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/img/gallery/gallery1.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/img/gallery/gallery2.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/img/gallery/gallery3.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/img/gallery/gallery4.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/img/gallery/gallery5.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/img/gallery/gallery6.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/img/gallery/gallery7.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/img/gallery/gallery8.jpg" class="border-radius" alt="gallery-image">
            </div>
        </div>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Gallery - 2</h5>
    </mat-card-header>
    <mat-card-content>
        <div class="row gallery-list">
            <div class="col-lg-4 col-sm-4 col-md-4 mb-25">
                <img src="assets/img/gallery/gallery1.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-4 col-sm-4 col-md-4 mb-25">
                <img src="assets/img/gallery/gallery2.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-4 col-sm-4 col-md-4 mb-25">
                <img src="assets/img/gallery/gallery3.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-4 col-sm-4 col-md-4 mb-25">
                <img src="assets/img/gallery/gallery4.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-4 col-sm-4 col-md-4 mb-25">
                <img src="assets/img/gallery/gallery5.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-4 col-sm-4 col-md-4 mb-25">
                <img src="assets/img/gallery/gallery6.jpg" class="border-radius" alt="gallery-image">
            </div>
        </div>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Gallery - 3</h5>
    </mat-card-header>
    <mat-card-content>
        <div class="row gallery-list">
            <div class="col-lg-4 col-sm-4 col-md-4 mb-25">
                <img src="assets/img/gallery/gallery1.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-4 col-sm-4 col-md-4 mb-25">
                <img src="assets/img/gallery/gallery2.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-4 col-sm-4 col-md-4 mb-25">
                <img src="assets/img/gallery/gallery3.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-6 col-sm-4 col-md-4 mb-25">
                <img src="assets/img/gallery/gallery4.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-6 col-sm-4 col-md-4 mb-25">
                <img src="assets/img/gallery/gallery5.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/img/gallery/gallery6.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/img/gallery/gallery7.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/img/gallery/gallery8.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/img/gallery/gallery9.jpg" class="border-radius" alt="gallery-image">
            </div>
        </div>
    </mat-card-content>
</mat-card>