<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Wizard</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Wizard</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-content>
        <mat-stepper #stepper class="tagus-wizard">
            <mat-step>
                <ng-template matStepLabel>Step 1</ng-template>
                <form>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">First Name</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-user-3-line"></i>
                                    <mat-label>Enter name</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Last Name</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-user-3-line"></i>
                                    <mat-label>Enter name</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Email Address <span>*</span></label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-mail-line"></i>
                                    <mat-label>Enter email address</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Phone</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-phone-line"></i>
                                    <mat-label>Enter phone number</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Address</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-map-pin-line"></i>
                                    <mat-label>Enter location</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Country</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-map-2-line"></i>
                                    <mat-label>Enter country</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5">
                        <button mat-flat-button matStepperNext class="tagus">Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Step 2</ng-template>
                <form>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">First Name</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-user-3-line"></i>
                                    <mat-label>Enter name</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Last Name</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-user-3-line"></i>
                                    <mat-label>Enter name</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Email Address <span>*</span></label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-mail-line"></i>
                                    <mat-label>Enter email address</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Phone</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-phone-line"></i>
                                    <mat-label>Enter phone number</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Address</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-map-pin-line"></i>
                                    <mat-label>Enter location</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Country</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-map-2-line"></i>
                                    <mat-label>Enter country</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5">
                        <button mat-flat-button matStepperPrevious class="gray me-15">Back</button>
                        <button mat-flat-button matStepperNext class="tagus">Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Step 3</ng-template>
                <form>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">First Name</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-user-3-line"></i>
                                    <mat-label>Enter name</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Last Name</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-user-3-line"></i>
                                    <mat-label>Enter name</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Email Address <span>*</span></label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-mail-line"></i>
                                    <mat-label>Enter email address</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Phone</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-phone-line"></i>
                                    <mat-label>Enter phone number</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Address</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-map-pin-line"></i>
                                    <mat-label>Enter location</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Country</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-map-2-line"></i>
                                    <mat-label>Enter country</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5">
                        <button mat-flat-button matStepperPrevious class="gray me-15">Back</button>
                        <button mat-flat-button matStepperNext class="tagus">Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Step 4</ng-template>
                <p>You are now done.</p>
                <div>
                    <button mat-flat-button matStepperPrevious class="gray me-15">Back</button>
                    <button mat-flat-button (click)="stepper.reset()" color="warn">Reset</button>
                </div>
            </mat-step>
        </mat-stepper>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-content>
        <mat-stepper class="tagus-wizard" #stepper2 orientation="vertical">
            <mat-step>
                <ng-template matStepLabel>Step 1</ng-template>
                <form>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">First Name</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-user-3-line"></i>
                                    <mat-label>Enter name</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Last Name</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-user-3-line"></i>
                                    <mat-label>Enter name</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Email Address <span>*</span></label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-mail-line"></i>
                                    <mat-label>Enter email address</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Phone</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-phone-line"></i>
                                    <mat-label>Enter phone number</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Address</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-map-pin-line"></i>
                                    <mat-label>Enter location</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Country</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-map-2-line"></i>
                                    <mat-label>Enter country</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5">
                        <button mat-flat-button matStepperNext class="tagus">Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Step 2</ng-template>
                <form>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">First Name</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-user-3-line"></i>
                                    <mat-label>Enter name</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Last Name</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-user-3-line"></i>
                                    <mat-label>Enter name</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Email Address <span>*</span></label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-mail-line"></i>
                                    <mat-label>Enter email address</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Phone</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-phone-line"></i>
                                    <mat-label>Enter phone number</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Address</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-map-pin-line"></i>
                                    <mat-label>Enter location</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Country</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-map-2-line"></i>
                                    <mat-label>Enter country</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5">
                        <button mat-flat-button matStepperPrevious class="gray me-15">Back</button>
                        <button mat-flat-button matStepperNext class="tagus">Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Step 3</ng-template>
                <form>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">First Name</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-user-3-line"></i>
                                    <mat-label>Enter name</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Last Name</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-user-3-line"></i>
                                    <mat-label>Enter name</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Email Address <span>*</span></label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-mail-line"></i>
                                    <mat-label>Enter email address</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Phone</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-phone-line"></i>
                                    <mat-label>Enter phone number</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Address</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-map-pin-line"></i>
                                    <mat-label>Enter location</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">Country</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-map-2-line"></i>
                                    <mat-label>Enter country</mat-label>
                                    <input matInput>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5">
                        <button mat-flat-button matStepperPrevious class="gray me-15">Back</button>
                        <button mat-flat-button matStepperNext class="tagus">Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Step 4</ng-template>
                <p>You are now done.</p>
                <div>
                    <button mat-flat-button matStepperPrevious class="gray me-15">Back</button>
                    <button mat-flat-button (click)="stepper2.reset()" color="warn">Reset</button>
                </div>
            </mat-step>
        </mat-stepper>
    </mat-card-content>
</mat-card>