<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Cart</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Products</li>
        <li class="breadcrumb-item fw-semibold">Cart</li>
    </ol>
</div>

<div
    class="row justify-content-center"
    [class.component-dark-theme]="themeService.isDark()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="col-lg-7 col-xl-8 col-md-12">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header>
                <h5 class="mb-0">Shopping Cart</h5>
            </mat-card-header>
            <mat-card-content>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="fw-semibold gray-color text-start">Product</th>
                                <th class="fw-semibold gray-color">Price</th>
                                <th class="fw-semibold gray-color">Size</th>
                                <th class="fw-semibold gray-color">Quantity</th>
                                <th class="fw-semibold gray-color">Total Amount</th>
                                <th class="fw-semibold gray-color">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-start">
                                    <a routerLink="/" class="d-flex align-items-center product-info">
                                        <img src="assets/img/recent-orders/product1.jpg" class="border-radius me-12" width="50" alt="product">
                                        <div class="title">
                                            <h5 class="fw-semibold mb-5">Laptop Macos Pro</h5>
                                            <span class="d-block muted-color fw-medium">
                                                Color: <span class="gray-color">Pink</span>
                                            </span>
                                        </div>
                                    </a>
                                </td>
                                <td class="gray-color">$100.00</td>
                                <td class="gray-color">M</td>
                                <td class="gray-color">02</td>
                                <td class="gray-color">$200.00</td>
                                <td class="gray-color">
                                    <a href="#" class="action-btn">
                                        <i class="ri-delete-bin-7-line"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-start">
                                    <a routerLink="/" class="d-flex align-items-center product-info">
                                        <img src="assets/img/recent-orders/product2.jpg" class="border-radius me-12" width="50" alt="product">
                                        <div class="title">
                                            <h5 class="fw-semibold mb-5">Smart Camera XD6</h5>
                                            <span class="d-block muted-color fw-medium">
                                                Color: <span class="gray-color">Red</span>
                                            </span>
                                        </div>
                                    </a>
                                </td>
                                <td class="gray-color">$50.00</td>
                                <td class="gray-color">XL</td>
                                <td class="gray-color">03</td>
                                <td class="gray-color">$150.00</td>
                                <td class="gray-color">
                                    <a href="#" class="action-btn">
                                        <i class="ri-delete-bin-7-line"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-start">
                                    <a routerLink="/" class="d-flex align-items-center product-info">
                                        <img src="assets/img/recent-orders/product3.jpg" class="border-radius me-12" width="50" alt="product">
                                        <div class="title">
                                            <h5 class="fw-semibold mb-5">Pixi 8 Wireless Airphone</h5>
                                            <span class="d-block muted-color fw-medium">
                                                Color: <span class="gray-color">White</span>
                                            </span>
                                        </div>
                                    </a>
                                </td>
                                <td class="gray-color">$100.00</td>
                                <td class="gray-color">L</td>
                                <td class="gray-color">01</td>
                                <td class="gray-color">$100.00</td>
                                <td class="gray-color">
                                    <a href="#" class="action-btn">
                                        <i class="ri-delete-bin-7-line"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-start">
                                    <a routerLink="/" class="d-flex align-items-center product-info">
                                        <img src="assets/img/recent-orders/product4.jpg" class="border-radius me-12" width="50" alt="product">
                                        <div class="title">
                                            <h5 class="fw-semibold mb-5">Jebble Smart Watch</h5>
                                            <span class="d-block muted-color fw-medium">
                                                Color: <span class="gray-color">Black</span>
                                            </span>
                                        </div>
                                    </a>
                                </td>
                                <td class="gray-color">$100.00</td>
                                <td class="gray-color">M</td>
                                <td class="gray-color">02</td>
                                <td class="gray-color">$200.00</td>
                                <td class="gray-color">
                                    <a href="#" class="action-btn">
                                        <i class="ri-delete-bin-7-line"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <form class="mt-25 note-box">
                    <label class="d-block mb-12 fw-semibold gray-color">Add Note :</label>
                    <div class="form-group">
                        <mat-form-field appearance="fill">
                            <i class="ri-edit-2-line"></i>
                            <mat-label>Some demo text...</mat-label>
                            <textarea matInput rows="5"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="text-end">
                        <button mat-button type="submit" class="default-btn">
                            Checkout
                        </button>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-5 col-xl-4 col-md-12">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header>
                <h5 class="mb-0">Order Summary</h5>
            </mat-card-header>
            <mat-card-content>
                <ul class="list ps-0 mb-0 mt-0">
                    <li class="gray-color d-flex align-items-center justify-content-space-between">
                        Sub Total :
                        <span class="fw-semibold">$760.00</span>
                    </li>
                    <li class="gray-color d-flex align-items-center justify-content-space-between">
                        Discount :
                        <span class="fw-semibold">$60.00</span>
                    </li>
                    <li class="gray-color d-flex align-items-center justify-content-space-between">
                        Shipping Charge :
                        <span class="fw-semibold">$50.00</span>
                    </li>
                    <li class="gray-color d-flex align-items-center justify-content-space-between">
                        Estimated Tax :
                        <span class="fw-semibold">$00.00</span>
                    </li>
                    <li class="fw-semibold d-flex align-items-center justify-content-space-between">
                        Total (USD) :
                        <span class="fw-semibold">$870.00</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
        <mat-card class="mb-25 tagus-card">
            <mat-card-header>
                <h5 class="mb-0">Have A Promo Code?</h5>
            </mat-card-header>
            <mat-card-content>
                <form class="note-box">
                    <label class="d-block mb-12 fw-semibold gray-color">Apply Coupon</label>
                    <div class="form-group">
                        <mat-form-field appearance="fill">
                            <i class="ri-braces-line"></i>
                            <mat-label>Coupon code</mat-label>
                            <input matInput>
                        </mat-form-field>
                    </div>
                    <div class="text-end">
                        <button mat-button type="submit" class="default-btn">
                            Apply
                        </button>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>