<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Tree</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">UI Kit</li>
        <li class="breadcrumb-item fw-semibold">Tree</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Tree with Nested Nodes</h5>
    </mat-card-header>
    <mat-card-content>
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                {{node.name}}
            </mat-tree-node>
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                <div class="mat-tree-node">
                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>
                    {{node.name}}
                </div>
                <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
                    <ng-container matTreeNodeOutlet></ng-container>
                </div>
            </mat-nested-tree-node>
        </mat-tree>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Tree With Partially Loaded Data</h5>
    </mat-card-header>
    <mat-card-content>
        <app-tree-with-pld></app-tree-with-pld>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Tree With Dynamic Data</h5>
    </mat-card-header>
    <mat-card-content>
        <app-tree-with-dd></app-tree-with-dd>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Tree With Checkbox</h5>
    </mat-card-header>
    <mat-card-content>
        <app-tree-with-checkbox></app-tree-with-checkbox>
    </mat-card-content>
</mat-card>