<form class="example-form">
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>State</mat-label>
        <input matInput
            aria-label="State"
            [matAutocomplete]="auto"
            [formControl]="stateCtrl"
        >
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let state of filteredStates | async" [value]="state.name">
                <img alt="flag" class="example-option-img" [src]="state.flag" width="25">
                <span>{{state.name}}</span> |
                <small>Population: {{state.population}}</small>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <br>
    <mat-slide-toggle
        [checked]="stateCtrl.disabled"
        (change)="stateCtrl.disabled ? stateCtrl.enable() : stateCtrl.disable()"
    >
        Disable Input?
    </mat-slide-toggle>
</form>