<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Avatars</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">UI Kit</li>
        <li class="breadcrumb-item fw-semibold">Avatars</li>
    </ol>
</div>

<div class="row">
    <div class="col-xl-4 col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Single User</h5>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-space-between">
                    <div class="d-flex align-items-center">
                        <img src="assets/img/user/user22.jpg" width="57" class="rounded-circle" alt="user">
                        <div class="ms-15">
                            <h5 class="mb-5">Andrew Burns</h5>
                            <span class="d-block gray-color">Programmer</span>
                        </div>
                    </div>
                    <div class="socials">
                        <a href="#" target="_blank" class="d-inline-block main-color w-35 h-35 rounded-circle lh-38 text-center bg-gray bg-main-hover-color"><i class="flaticon-facebook-app-symbol lh-1"></i></a>
                        <a href="#" target="_blank" class="ms-5 d-inline-block main-color w-35 h-35 rounded-circle lh-38 text-center bg-gray bg-main-hover-color"><i class="flaticon-twitter lh-1"></i></a>
                        <a href="#" target="_blank" class="ms-5 d-inline-block main-color w-35 h-35 rounded-circle lh-38 text-center bg-gray bg-main-hover-color"><i class="flaticon-linkedin lh-1"></i></a>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xl-4 col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Group User</h5>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-space-between">
                    <div class="avatars-list d-flex align-items-center">
                        <img src="assets/img/user/user22.jpg" width="45" class="rounded-circle" alt="user">
                        <img src="assets/img/user/user23.jpg" width="45" class="rounded-circle" alt="user">
                        <img src="assets/img/user/user24.jpg" width="45" class="rounded-circle" alt="user">
                        <div class="bg-gray rounded-circle d-inline-block text-center position-relative"><i class="flaticon-plus"></i></div>
                    </div>
                    <a mat-flat-button routerLink="." class="gray small">View Details</a>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xl-4 col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Single User With Badge</h5>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-space-between">
                    <div class="avatars-list-with-badge d-flex align-items-center">
                        <div class="rounded-circle position-relative">
                            <img src="assets/img/user/user22.jpg" class="rounded-circle" alt="user">
                        </div>
                        <div class="rounded-circle position-relative off">
                            <img src="assets/img/user/user23.jpg" class="rounded-circle" alt="user">
                        </div>
                    </div>
                    <a mat-flat-button routerLink="." class="gray small">View Details</a>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Avatar Size Rounded-Circle</h5>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center">
                    <img src="assets/img/user/user11.jpg" class="rounded-circle me-20" width="85" alt="user">
                    <img src="assets/img/user/user11.jpg" class="rounded-circle me-20" width="75" alt="user">
                    <img src="assets/img/user/user11.jpg" class="rounded-circle me-20" width="65" alt="user">
                    <img src="assets/img/user/user11.jpg" class="rounded-circle me-20" width="55" alt="user">
                    <img src="assets/img/user/user11.jpg" class="rounded-circle me-20" width="45" alt="user">
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Avatar Size Square</h5>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center">
                    <img src="assets/img/user/user12.jpg" class="border-radius me-20" width="85" alt="user">
                    <img src="assets/img/user/user12.jpg" class="border-radius me-20" width="75" alt="user">
                    <img src="assets/img/user/user12.jpg" class="border-radius me-20" width="65" alt="user">
                    <img src="assets/img/user/user12.jpg" class="border-radius me-20" width="55" alt="user">
                    <img src="assets/img/user/user12.jpg" class="border-radius me-20" width="45" alt="user">
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Avatar Size Rounded-Circle</h5>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center">
                    <div class="rounded-circle justify-content-center bg-gray align-items-center d-flex fw-bold main-color text-center w-85 h-85 me-20">
                        Ab
                    </div>
                    <div class="rounded-circle justify-content-center bg-gray align-items-center d-flex fw-bold main-color text-center w-75 h-75 me-20">
                        Ab
                    </div>
                    <div class="rounded-circle justify-content-center bg-gray align-items-center d-flex fw-bold main-color text-center w-65 h-65 me-20">
                        Ab
                    </div>
                    <div class="rounded-circle justify-content-center bg-gray align-items-center d-flex fw-bold main-color text-center w-55 h-55 me-20">
                        Ab
                    </div>
                    <div class="rounded-circle justify-content-center bg-gray align-items-center d-flex fw-bold main-color text-center w-45 h-45 me-20">
                        Ab
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Avatar Size Square</h5>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center">
                    <div class="border-radius justify-content-center bg-gray align-items-center d-flex fw-bold main-color text-center w-85 h-85 me-20">
                        Ab
                    </div>
                    <div class="border-radius justify-content-center bg-gray align-items-center d-flex fw-bold main-color text-center w-75 h-75 me-20">
                        Ab
                    </div>
                    <div class="border-radius justify-content-center bg-gray align-items-center d-flex fw-bold main-color text-center w-65 h-65 me-20">
                        Ab
                    </div>
                    <div class="border-radius justify-content-center bg-gray align-items-center d-flex fw-bold main-color text-center w-55 h-55 me-20">
                        Ab
                    </div>
                    <div class="border-radius justify-content-center bg-gray align-items-center d-flex fw-bold main-color text-center w-45 h-45 me-20">
                        Ab
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Total Avatars</h5>
            </mat-card-header>
            <mat-card-content>
                <div class="avatars-list d-flex align-items-center">
                    <img src="assets/img/user/user22.jpg" width="45" class="rounded-circle" alt="user">
                    <img src="assets/img/user/user23.jpg" width="45" class="rounded-circle" alt="user">
                    <img src="assets/img/user/user24.jpg" width="45" class="rounded-circle" alt="user">
                    <img src="assets/img/user/user21.jpg" width="45" class="rounded-circle" alt="user">
                    <div class="bg-gray rounded-circle d-inline-block text-center position-relative">
                        +20
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Icon Avatars</h5>
            </mat-card-header>
            <mat-card-content class="example-buttons">
                <button mat-mini-fab class="tagus">
                    <mat-icon>favorite</mat-icon>
                </button>
                <button mat-mini-fab color="primary">
                    <mat-icon>favorite</mat-icon>
                </button>
                <button mat-mini-fab color="accent">
                    <mat-icon>favorite</mat-icon>
                </button>
                <button mat-mini-fab color="warn">
                    <mat-icon>favorite</mat-icon>
                </button>
                <button mat-mini-fab disabled>
                    <mat-icon>favorite</mat-icon>
                </button>
                <a mat-mini-fab routerLink=".">
                    <mat-icon>favorite</mat-icon>
                </a>
                <button mat-mini-fab class="gray">
                    <mat-icon>favorite</mat-icon>
                </button>
            </mat-card-content>
        </mat-card>
    </div>
</div>