import { Component } from '@angular/core';

@Component({
    selector: 'app-datepicker-custom-icon',
    templateUrl: './datepicker-custom-icon.component.html',
    styleUrls: ['./datepicker-custom-icon.component.scss']
})
export class DatepickerCustomIconComponent {



}