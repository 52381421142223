<div class="row">
    <div class="col-lg-6 col-md-12">
        <mat-form-field class="example-form-field example-full-width" appearance="fill">
            <mat-label>First campaign</mat-label>
            <mat-date-range-input
                [formGroup]="campaignOne"
                [rangePicker]="campaignOnePicker"
                [comparisonStart]="campaignTwo.value.start"
                [comparisonEnd]="campaignTwo.value.end">
                <input matStartDate placeholder="Start date" formControlName="start">
                <input matEndDate placeholder="End date" formControlName="end">
            </mat-date-range-input>
            <mat-hint class="mt-5">MM/DD/YYYY – MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
            <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
        </mat-form-field>
    </div>
    <div class="col-lg-6 col-md-12">
        <mat-form-field class="example-form-field example-full-width" appearance="fill">
            <mat-label>Second campaign</mat-label>
            <mat-date-range-input
                [formGroup]="campaignTwo"
                [rangePicker]="campaignTwoPicker"
                [comparisonStart]="campaignOne.value.start"
                [comparisonEnd]="campaignOne.value.end">
                <input matStartDate placeholder="Start date" formControlName="start">
                <input matEndDate placeholder="End date" formControlName="end">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="campaignTwoPicker"></mat-datepicker-toggle>
            <mat-hint class="mt-5">MM/DD/YYYY – MM/DD/YYYY</mat-hint>
            <mat-date-range-picker #campaignTwoPicker></mat-date-range-picker>
        </mat-form-field>
    </div>
</div>