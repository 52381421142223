import { Component } from '@angular/core';

@Component({
    selector: 'app-basic-form',
    templateUrl: './basic-form.component.html',
    styleUrls: ['./basic-form.component.scss']
})
export class BasicFormComponent {

    hide = true;

}