<mat-drawer-container class="example-container" [hasBackdrop]="hasBackdrop.value" [class.component-dark-theme]="themeService.isDark()">
    <mat-drawer #drawer [mode]="mode.value">I'm a drawer</mat-drawer>
    <mat-drawer-content>
        <mat-form-field appearance="fill">
            <mat-label>Sidenav mode</mat-label>
            <mat-select #mode value="side">
                <mat-option value="side">Side</mat-option>
                <mat-option value="over">Over</mat-option>
                <mat-option value="push">Push</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Has backdrop</mat-label>
            <mat-select #hasBackdrop>
                <mat-option>Unset</mat-option>
                <mat-option [value]="true">True</mat-option>
                <mat-option [value]="false">False</mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-raised-button (click)="drawer.toggle()">Toggle drawer</button>
    </mat-drawer-content>
</mat-drawer-container>