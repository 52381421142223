<div class="chart">
    <apx-chart
        [series]="chartOptions.series!"
        [chart]="chartOptions.chart!"
        [yaxis]="chartOptions.yaxis!"
        [xaxis]="chartOptions.xaxis!"
        [labels]="chartOptions.labels!"
        [stroke]="chartOptions.stroke!"
        [legend]="chartOptions.legend!"
        [grid]="chartOptions.grid!"
        [markers]="chartOptions.markers!"
        [fill]="chartOptions.fill!"
        [tooltip]="chartOptions.tooltip!"
    ></apx-chart>
</div>