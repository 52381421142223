<mat-card class="mb-25 tagus-card enrolled-created-box">
    <mat-card-content>
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms">
            <mat-tab label="Enrolled">
                <app-lms-enrolled></app-lms-enrolled>
            </mat-tab>
            <mat-tab label="Created">
                <app-lms-created></app-lms-created>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>