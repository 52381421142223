import {Component, OnInit} from '@angular/core';
import {ErrorService} from "../error.service";
import { ErrorTypeEnum} from "../../../../../enums/error-type.enum";

@Component({
  selector: 'app-global-error',
  templateUrl: './global-error.component.html',
  styleUrl: './global-error.component.css'
})
export class GlobalErrorComponent implements OnInit {
  errors: string[] = [];

  constructor(
    private errorService: ErrorService,
  ) {}

  ngOnInit() {
    // подписка на ошибки из сервиса
    this.errorService.getErrors(ErrorTypeEnum.Global).subscribe((errors) => {
      this.errors = errors;
    });
  }
}
