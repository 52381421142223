import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from "../../environments/environment";
import { jwtDecode } from "jwt-decode";
import { isPlatformBrowser } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class AuthStorageService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  /* ** Token ** */
  setToken(user: any) {
    if (isPlatformBrowser(this.platformId)) { // проверяем запущен ли код в браузере (SSR)
      this.removeToken();
      localStorage.setItem(environment.user_key, user.data.access_token);
    }
  }

  getToken(): any {
    if (isPlatformBrowser(this.platformId)) { // проверяем запущен ли код в браузере (SSR)
      const cachedData = localStorage.getItem(environment.user_key);
      return cachedData || null;
    }
  }

  removeToken(): void {
    localStorage.removeItem(environment.user_key);
  }

  // получаем данные юзера по токену
  getUserFromToken(): any {
    if (this.getToken()) {
      // расшифровываем токен
      const tokenData = jwtDecode(this.getToken());
      if (tokenData && tokenData.exp) {
        // unix-время в миллисекундах
        const expirationTimestamp = tokenData.exp * 1000;

        // для теста истекшой даты
        // const futureTimestamp = Date.now() + 60 * 24 * 60 * 60 * 1000; // 100 дней в миллисекундах

        // смотрим, истекла ли дата токена
        if (Date.now() <= expirationTimestamp) {
          return tokenData;
        } else {
          this.removeToken();
        }
      }
    }
    return null;
  }
}
