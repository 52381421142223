import {AngularEditorConfig, UploadResponse} from "@kolkov/angular-editor";
import {environment} from "../../environments/environment";
import {map} from "rxjs/operators";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class ToolbarEditor2ConfigService {
    constructor(private http: HttpClient) {
    }

    public getConfig(): AngularEditorConfig {
        return {
            editable: true,
            spellcheck: true,
            height: '150px',
            minHeight: '0',
            maxHeight: 'auto',
            width: 'auto',
            minWidth: '0',
            translate: 'yes',
            enableToolbar: true,
            showToolbar: true,
            placeholder: '',
            defaultParagraphSeparator: '',
            defaultFontName: '',
            defaultFontSize: '',
            fonts: [
                {class: 'arial', name: 'Arial'},
            ],
            customClasses: [
                {
                    name: 'quote',
                    class: 'quote',
                },
                {
                    name: 'redText',
                    class: 'redText'
                },
                {
                    name: 'titleText',
                    class: 'titleText',
                    tag: 'h1',
                },
            ],
            upload: (file) => {
                const url = environment.backendUrl + '/api/v1/requests/upload-files';
                const uploadData: FormData = new FormData();
                uploadData.append('image', file, file.name);
                return this.http
                    .post<{ file: string; url: string }>(url, uploadData, {
                        observe: 'response',
                    })
                    .pipe(
                        map((response: any) => {
                            const imageUrl = environment.backendUrl + '/uploads/' + response.body.data;
                            return {
                                ...response,
                                body: {imageUrl},
                            } as HttpResponse<UploadResponse>;
                        }),
                    );
            },
            uploadWithCredentials: false,
            sanitize: false,
            toolbarPosition: 'top',
            toolbarHiddenButtons: [
                ['fontSize']
            ]
        };
    }
}
