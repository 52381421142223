<div class="row">
    <div class="col-md-4">
        <mat-card class="single-stats-box tagus-card mb-25">
            <div class="d-flex justify-content-space-between">
                <span class="title gray-color fw-semibold">Total Invested</span>
                <div class="icon">
                    <i class="ri-money-dollar-circle-line"></i>
                </div>
            </div>
            <h3 class="text-center">$25,890</h3>
            <p class="up d-flex align-items-center justify-content-space-between">
                Up from past week
                <span><i class="flaticon-trend"></i> +1.3%</span>
            </p>
        </mat-card>
    </div>
    <div class="col-md-4">
        <mat-card class="single-stats-box tagus-card mb-25">
            <div class="d-flex justify-content-space-between">
                <span class="title gray-color fw-semibold">Total Changed</span>
                <div class="icon bg2">
                    <i class="ri-arrow-up-down-fill"></i>
                </div>
            </div>
            <h3 class="text-center">$10,324</h3>
            <p class="down d-flex align-items-center justify-content-space-between">
                Up from past week
                <span><i class="flaticon-trend-1"></i> -2.5%</span>
            </p>
        </mat-card>
    </div>
    <div class="col-md-4">
        <mat-card class="single-stats-box tagus-card mb-25">
            <div class="d-flex justify-content-space-between">
                <span class="title gray-color fw-semibold">Day Changed</span>
                <div class="icon bg3">
                    <i class="ri-bar-chart-2-line"></i>
                </div>
            </div>
            <h3 class="text-center">103</h3>
            <p class="up d-flex align-items-center justify-content-space-between">
                Up from past week
                <span><i class="flaticon-trend"></i> +0.4%</span>
            </p>
        </mat-card>
    </div>
</div>