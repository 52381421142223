<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Input</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">UI Kit</li>
        <li class="breadcrumb-item fw-semibold">Input</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Inputs</h5>
            </mat-card-header>
            <mat-card-content>
                <form>
                    <mat-form-field class="example-full-width">
                        <mat-label>Favorite food</mat-label>
                        <input matInput placeholder="Ex. Pizza" value="Sushi">
                    </mat-form-field>
                    <mat-form-field class="example-full-width">
                        <mat-label>Leave a comment</mat-label>
                        <textarea matInput placeholder="Ex. It makes me feel..."></textarea>
                    </mat-form-field>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Input with Hints</h5>
            </mat-card-header>
            <mat-card-content>
                <form>
                    <mat-form-field class="example-full-width">
                        <mat-label>Message</mat-label>
                        <input matInput #message maxlength="256" placeholder="Ex. I need help with...">
                        <mat-hint align="start" class="mt-5"><strong>Don't disclose personal info</strong> </mat-hint>
                        <mat-hint align="end" class="mt-5">{{message.value.length}} / 256</mat-hint>
                    </mat-form-field>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Input with Clear Button</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field class="example-full-width">
                    <mat-label>Clearable input</mat-label>
                    <input matInput type="text" [(ngModel)]="value">
                    <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Input with Error Message</h5>
            </mat-card-header>
            <mat-card-content>
                <form>
                    <mat-form-field class="example-full-width">
                        <mat-label>Email</mat-label>
                        <input type="email" matInput [formControl]="emailFormControl" placeholder="Ex. pat@example.com">
                        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                        Please enter a valid email address
                        </mat-error>
                        <mat-error *ngIf="emailFormControl.hasError('required')">
                        Email is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Inputs with Prefixes & Suffixes</h5>
            </mat-card-header>
            <mat-card-content>
                <form>
                    <mat-form-field class="example-full-width">
                        <mat-label>Telephone</mat-label>
                        <span matPrefix>+1 &nbsp;</span>
                        <input type="tel" matInput placeholder="555-555-1234">
                        <mat-icon matSuffix>mode_edit</mat-icon>
                    </mat-form-field>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-12 col-md-12">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Inputs in a Form</h5>
            </mat-card-header>
            <mat-card-content>
                <form class="example-form">
                    <mat-form-field class="example-full-width">
                        <mat-label>Company (disabled)</mat-label>
                        <input matInput disabled value="Google">
                    </mat-form-field>
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <mat-form-field class="example-full-width">
                                <mat-label>First name</mat-label>
                                <input matInput>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <mat-form-field class="example-full-width">
                                <mat-label>Long Last Name That Will Be Truncated</mat-label>
                                <input matInput>
                            </mat-form-field>
                        </div>
                    </div>
                    <mat-form-field class="example-full-width">
                        <mat-label>Address</mat-label>
                        <textarea matInput placeholder="Ex. 100 Main St">1600 Amphitheatre Pkwy</textarea>
                    </mat-form-field>
                    <mat-form-field class="example-full-width">
                        <mat-label>Address 2</mat-label>
                        <textarea matInput></textarea>
                    </mat-form-field>
                    <div class="row">
                        <div class="col-lg-4 col-md-12">
                            <mat-form-field class="example-full-width">
                                <mat-label>City</mat-label>
                                <input matInput placeholder="Ex. San Francisco">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <mat-form-field class="example-full-width">
                                <mat-label>State</mat-label>
                                <input matInput placeholder="Ex. California">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <mat-form-field class="example-full-width">
                                <mat-label>Postal Code</mat-label>
                                <input matInput #postalCode maxlength="5" placeholder="Ex. 94105" value="94043">
                                <mat-hint align="end">{{postalCode.value.length}} / 5</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>