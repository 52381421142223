<div class="chart">
    <apx-chart
        [series]="chartOptions.series!"
        [chart]="chartOptions.chart!"
        [plotOptions]="chartOptions.plotOptions!"
        [xaxis]="chartOptions.xaxis!"
        [yaxis]="chartOptions.yaxis!"
        [grid]="chartOptions.grid!"
        [colors]="chartOptions.colors!"
    ></apx-chart>
</div>