<div class="create-dialog-box">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">Create Folder</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>
    <form>
        <div class="form-group">
            <label class="d-block fw-semibold">Folder Name</label>
            <input type="text" class="input-folder-name" placeholder="Folder name">
        </div>
        <div class="text-end">
            <button type="submit"><i class="ri-add-fill"></i> Add Folder</button>
        </div>
    </form>
</div>