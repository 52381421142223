import { Component } from '@angular/core';

@Component({
    selector: 'app-datepicker-pc',
    templateUrl: './datepicker-pc.component.html',
    styleUrls: ['./datepicker-pc.component.scss']
})
export class DatepickerPcComponent {



}