<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Customers</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Customers</li>
    </ol>
</div>

<app-ac-audience-overview></app-ac-audience-overview>

<app-ac-status></app-ac-status>

<mat-card class="mb-25 tagus-card">
    <mat-card-header>
        <h5 class="mb-0">Customer Details</h5>
        <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="monthMenu">
            <i class="ri-more-fill"></i>
        </button>
        <mat-menu #monthMenu="matMenu" class="monthMenu" xPosition="before">
            <button mat-menu-item type="button" class="bg-transparent border-none">Today</button>
            <button mat-menu-item type="button" class="bg-transparent border-none">Last Month</button>
            <button mat-menu-item type="button" class="bg-transparent border-none">Last Year</button>
            <button mat-menu-item type="button" class="bg-transparent border-none">All Time</button>
        </mat-menu>
    </mat-card-header>
    <mat-card-content>
        <div class="teamMembersListTable tagus-mat-table mat-elevation-z8">
            <table mat-table [dataSource]="dataSource">

                <!-- User Column -->
                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef class="text-start">User</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        <div class="d-flex align-items-center user-info">
                            <mat-checkbox></mat-checkbox>
                            <img [src]="element.user.userImage" class="rounded-circle" width="45" alt="user">
                            <div class="title">
                                {{element.user.userName}}
                                <span class="muted-color fw-normal d-block">{{element.user.userDesignation}}</span>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <!-- Task Progress Column -->
                <ng-container matColumnDef="progress">
                    <th mat-header-cell *matHeaderCellDef>Task Progress</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        <span class="d-block gray-color mb-5 fw-normal">{{element.progress}}%</span>
                        <mat-progress-bar mode="determinate" value="{{element.progress}}"></mat-progress-bar>
                    </td>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>Email</th>
                    <td mat-cell *matCellDef="let element"><a href="mailto:{{element.email}}">{{element.email}}</a></td>
                </ng-container>

                <!-- Role Column -->
                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef>Role</th>
                    <td mat-cell *matCellDef="let element">{{element.role}}</td>
                </ng-container>

                <!-- Gender Column -->
                <ng-container matColumnDef="gender">
                    <th mat-header-cell *matHeaderCellDef>Gender</th>
                    <td mat-cell *matCellDef="let element">{{element.gender}}</td>
                </ng-container>

                <!-- Projects Column -->
                <ng-container matColumnDef="projects">
                    <th mat-header-cell *matHeaderCellDef>Projects</th>
                    <td mat-cell *matCellDef="let element">{{element.projects}}</td>
                </ng-container>

                <!-- Rating Column -->
                <ng-container matColumnDef="rating">
                    <th mat-header-cell *matHeaderCellDef>Rating</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="rating">
                            <i class="{{element.rating.star}}"></i>
                            <i class="{{element.rating.star2}}"></i>
                            <i class="{{element.rating.star3}}"></i>
                            <i class="{{element.rating.star4}}"></i>
                            <i class="{{element.rating.star5}}"></i>
                        </div>
                    </td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>Action</th>
                    <td mat-cell *matCellDef="let element">
                        <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                            <i class="{{element.action}}"></i>
                        </button>
                        <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-share-line"></i> Share
                            </button>
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-link"></i> Get Sharable Link
                            </button>
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-edit-line"></i> Rename
                            </button>
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-delete-bin-line"></i> Remove
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator
                [pageSizeOptions]="[10, 15, 20]"
                showFirstLastButtons
            >
            </mat-paginator>
        </div>
    </mat-card-content>
</mat-card>