<div class="myTasksTable mat-elevation-z8 tagus-mat-table">
    <table mat-table [dataSource]="dataSource">

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="text-start">Name</th>
            <td mat-cell *matCellDef="let element" class="text-start">
                <div class="d-flex align-items-center name-info">
                    <mat-checkbox></mat-checkbox>
                    {{element.name}}
                </div>
            </td>
        </ng-container>

        <!-- Deadline Column -->
        <ng-container matColumnDef="deadline">
            <th mat-header-cell *matHeaderCellDef>Deadline</th>
            <td mat-cell *matCellDef="let element">{{element.deadline}}</td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">
                <span class="badge fw-medium" *ngIf="element.status.active">{{element.status.active}}</span>
                <span class="badge pending fw-medium" *ngIf="element.status.pending">{{element.status.pending}}</span>
                <span class="badge completed fw-medium" *ngIf="element.status.completed">{{element.status.completed}}</span>
            </td>
        </ng-container>

        <!-- Assignee Column -->
        <ng-container matColumnDef="assignee">
            <th mat-header-cell *matHeaderCellDef>Assignee</th>
            <td mat-cell *matCellDef="let element">
                <img [src]="element.assignee.img" matTooltip="{{element.assignee.name}}" class="rounded-circle" alt="icon">
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator
        [pageSizeOptions]="[7, 9, 11, 14]"
        showFirstLastButtons
    >
    </mat-paginator>
</div>