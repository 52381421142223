<mat-card class="mb-25 tagus-card">
    <mat-card-header>
        <h5 class="mb-0">Team Members List</h5>
        <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="monthMenu">
            <i class="ri-more-fill"></i>
        </button>
        <mat-menu #monthMenu="matMenu" class="dotMenu monthMenu" xPosition="before">
            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                <i class="ri-add-circle-line"></i> Add
            </button>
            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                <i class="ri-delete-bin-6-line"></i> Delete
            </button>
            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                <i class="ri-restart-line"></i> Refresh
            </button>
        </mat-menu>
    </mat-card-header>
    <mat-card-content>
        <div class="teamMembersListTable tagus-mat-table mat-elevation-z8">
            <table mat-table [dataSource]="dataSource">

                <!-- User Column -->
                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef class="text-start">User</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        <div class="d-flex align-items-center user-info">
                            <img [src]="element.user.userImage" class="rounded-circle" width="45" alt="user">
                            <div class="title">
                                {{element.user.userName}}
                                <span class="muted-color fw-normal d-block">{{element.user.userDesignation}}</span>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>Email</th>
                    <td mat-cell *matCellDef="let element"><a href="mailto:{{element.email}}">{{element.email}}</a></td>
                </ng-container>

                <!-- Role Column -->
                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef>Role</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="role-info position-relative">
                            <img [src]="element.role.icon" alt="icon">
                            {{element.role.roleTitle}}
                        </div>
                    </td>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="badge" *ngIf="element.status.active">{{element.status.active}}</span>
                        <span class="badge inactive" *ngIf="element.status.inactive">{{element.status.inactive}}</span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
            </table>
            <mat-paginator
                [pageSizeOptions]="[4, 8, 12]"
                showFirstLastButtons
            >
            </mat-paginator>
        </div>
    </mat-card-content>
</mat-card>