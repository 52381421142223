<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Compose Email</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Email</li>
        <li class="breadcrumb-item fw-semibold">Compose Email</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-4 col-xxl-3">

        <app-email-sidebar></app-email-sidebar>

    </div>
    <div class="col-lg-8 col-xxl-9">

        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">New Email</h5>
            </mat-card-header>
            <mat-card-content>
                <div class="tagus-form-group without-icon">
                    <mat-form-field appearance="fill">
                        <mat-label>To</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="tagus-form-group without-icon">
                    <mat-form-field appearance="fill">
                        <mat-label>Subject</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="tagus-form-group mb-20">
                    <quill-editor [styles]="{height: '250px'}" (onFocus)="focus($event)" (onEditorChanged)="changedEditor($event)" (onBlur)="blur($event)" (onEditorCreated)="created($event)"></quill-editor>
                </div>
                <div class="tagus-form-group">
                    <input type="file">
                </div>
                <div class="btn-box">
                    <button mat-flat-button class="tagus">Send</button>
                    <button mat-flat-button color="warn">Discard</button>
                </div>
            </mat-card-content>
        </mat-card>

    </div>
</div>