<header
    class="header-area bg-white border-bottom-radius box-shadow mb-25"
    [ngClass]="{'active': isToggled, 'sticky': isSticky}"
    [class.component-dark-theme]="themeService.isDark()"
    [class.right-sidebar]="themeService.isRightSidebar()"
    [class.hide-sidebar]="themeService.isHideSidebar()"
    [class.dark-header]="themeService.isHeaderDark()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.card-border-radius]="themeService.isCardBorderRadius()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="d-md-flex align-items-center justify-content-space-between">
        <div class="header-left-side d-flex align-items-center">
            <div class="burger-menu" (click)="toggle()" [ngClass]="{'active': isToggled}">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </div>
        </div>
        <ul class="header-right-side d-flex align-items-center mt-0 mb-0">
            <li class="d-none d-lg-block">
                <div class="date-btn main-color fw-semibold position-relative">
                    <i class="flaticon-calendar"></i> {{ formattedDate }}
                </div>
            </li>
            <li>
                <button mat-icon-button [matMenuTriggerFor]="profileMenu" class="profile-btn main-color border-none bg-transparent position-relative">
                    <img src="assets/img/albatros/user.png" class="rounded-circle" alt="admin">
                    Адміністратор
                    <span class="fw-semibold" *ngIf="user && user.phone">
                        {{ user.phone }}
                    </span>
                </button>
                <mat-menu class="profile-menu-dropdown" #profileMenu="matMenu" xPosition="before">
                    <ul class="m-0">
                        <li>
                            <a (click)="logout()" style="cursor: pointer">
                                <i class="ri-shut-down-line"></i> Вийти
                            </a>
                        </li>
                    </ul>
                </mat-menu>
            </li>
        </ul>
    </div>
</header>
