<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Сторiнки</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Дашборд
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Каталог</li>
        <li class="breadcrumb-item gray-color">Сторiнки</li>
        <li class="breadcrumb-item fw-semibold">Редагування сторiнки</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header>
        <h5 class="mb-0">Редагування сторiнки</h5>
        <div class="d-flex align-items-center">
            <button mat-raised-button class="tagus small margin-right-10px" form="form" type="submit">Зберегти</button>
            <a class="small" mat-stroked-button routerLink="/main/catalog/pages" color="warn">Вийти</a>
        </div>
    </mat-card-header>
    <mat-card-content>
        <app-local-error></app-local-error>
        <app-local-success></app-local-success>
        <div class="alert alert-primary" role="alert">
            👀 Переглянути сторiнку -
            <a [href]="domain + '/' + page.url" target="_blank" style="text-decoration: underline;">
                {{ page.name_ua }}
            </a>
        </div>
        <form [formGroup]="form" (submit)="onFormSubmit(form)" id="form">
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                <!-- Загальне -->
                <mat-tab label="Загальне"><br>
                    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                        <!-- УКР -->
                        <mat-tab label="УКР"><br>

                            <mat-form-field class="width-100">
                                <mat-label>Назва</mat-label>
                                <input formControlName="name_ua" matInput>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['name_ua'].invalid) || (form.controls['name_ua'].invalid && form.controls['name_ua'].touched)">
                                    <ng-container *ngIf="form.controls['name_ua'].errors?.['required']">
                                        Обов'язкове поле
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['name_ua'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['name_ua'].errors?.['maxlength']">
                                        Поле може містити максимум 255 символів
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>

                            <div class="width-100">
                                <label class="d-block mb-12 fw-semibold gray-color">Контент</label>
<!--                                <div class="NgxEditor__Wrapper mb-20">-->
<!--                                    <ngx-editor-menu [editor]="editorDescriptionUa" [toolbar]="toolbar"></ngx-editor-menu>-->
<!--                                    <ngx-editor [editor]="editorDescriptionUa" formControlName="content_ua" [placeholder]="''"></ngx-editor>-->
<!--                                </div>-->
                                <angular-editor formControlName="content_ua" [config]="editorConfig"></angular-editor>
                            </div>

                            <mat-form-field class="width-100">
                                <mat-label>Мета title</mat-label>
                                <input formControlName="meta_title_ua" matInput>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['meta_title_ua'].invalid) || (form.controls['meta_title_ua'].invalid && form.controls['meta_title_ua'].touched)">
                                    <ng-container *ngIf="form.controls['meta_title_ua'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['meta_title_ua'].errors?.['maxlength']">
                                        Поле може містити максимум 255 символів
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="width-100">
                                <mat-label>Мета description</mat-label>
                                <textarea formControlName="meta_description_ua" rows="5" matInput></textarea>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['meta_description_ua'].invalid) || (form.controls['meta_description_ua'].invalid && form.controls['meta_description_ua'].touched)">
                                    <ng-container *ngIf="form.controls['meta_description_ua'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['meta_description_ua'].errors?.['maxlength']">
                                        Поле може містити максимум 255 символів
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>

                        </mat-tab>
                        <mat-tab label="РУС"><br>

                            <mat-form-field class="width-100">
                                <mat-label>Назва</mat-label>
                                <input formControlName="name_ru" matInput>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['name_ru'].invalid) || (form.controls['name_ru'].invalid && form.controls['name_ru'].touched)">
                                    <ng-container *ngIf="form.controls['name_ru'].errors?.['required']">
                                        Обов'язкове поле
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['name_ru'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['name_ru'].errors?.['maxlength']">
                                        Поле може містити максимум 255 символів
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>

                            <div class="width-100">
                                <label class="d-block mb-12 fw-semibold gray-color">Контент</label>
<!--                                <div class="NgxEditor__Wrapper mb-20">-->
<!--                                    <ngx-editor-menu [editor]="editorDescriptionRu" [toolbar]="toolbar"></ngx-editor-menu>-->
<!--                                    <ngx-editor [editor]="editorDescriptionRu" formControlName="content_ru" [placeholder]="''"></ngx-editor>-->
<!--                                </div>-->
                                <angular-editor formControlName="content_ru" [config]="editorConfig"></angular-editor>
                            </div>

                            <mat-form-field class="width-100">
                                <mat-label>Мета title</mat-label>
                                <input formControlName="meta_title_ru" matInput>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['meta_title_ru'].invalid) || (form.controls['meta_title_ru'].invalid && form.controls['meta_title_ru'].touched)">
                                    <ng-container *ngIf="form.controls['meta_title_ru'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['meta_title_ru'].errors?.['maxlength']">
                                        Поле може містити максимум 255 символів
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="width-100">
                                <mat-label>Мета description</mat-label>
                                <textarea formControlName="meta_description_ru" rows="5" matInput></textarea>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['meta_description_ru'].invalid) || (form.controls['meta_description_ru'].invalid && form.controls['meta_description_ru'].touched)">
                                    <ng-container *ngIf="form.controls['meta_description_ru'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['meta_description_ru'].errors?.['maxlength']">
                                        Поле може містити максимум 255 символів
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>

                        </mat-tab>
                    </mat-tab-group>
                </mat-tab>
                <!-- Зв'язки -->
                <mat-tab label="Зв'язки"><br>
                    <hr/>

                </mat-tab>
                <!-- Дизайн -->
                <mat-tab label="Дизайн"><br>
                    <div class="row" style="margin: 0">

                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="d-block mb-12 fw-semibold gray-color">Зображення</label>
                            <input type="file" (change)="uploadImage($event, 'imageCover')" accept="image/*" formControlName="image">
                            <br/>
                            <img *ngIf="imageCover" [src]="backendUrl + '/uploads/' + imageCover" class="border-radius mt-15" width="200">
                        </div>
                    </div>

                </mat-tab>
                <!-- SEO -->
                <mat-tab label="SEO"><br>

                    <mat-form-field class="width-100">
                        <mat-label>URL</mat-label>
                        <input formControlName="url" matInput>
                        <mat-error *ngIf="(formWasSubmitted && form.controls['url'].invalid) || (form.controls['url'].invalid && form.controls['url'].touched)">
                            <ng-container *ngIf="form.controls['url'].errors?.['required']">
                                Обов'язкове поле
                            </ng-container>
                            <ng-container *ngIf="form.controls['url'].errors?.['pattern']">
                                Поле повинно містити тільки латинські літери, цифри та прочерки
                            </ng-container>
                            <ng-container *ngIf="form.controls['url'].errors?.['minlength']">
                                Поле може містити мінімум 2 символи
                            </ng-container>
                            <ng-container *ngIf="form.controls['url'].errors?.['maxlength']">
                                Поле може містити максимум 255 символів
                            </ng-container>
                        </mat-error>
                    </mat-form-field>

                </mat-tab>
                <!-- OpenGraph -->
                <mat-tab label="OpenGraph"><br>
                    <div class="row" style="margin: 0">

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field class="width-100">
                                <mat-label>Заголовок Facebook, Twitter (укр)</mat-label>
                                <input formControlName="opengraph_header_ua" matInput>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field class="width-100">
                                <mat-label>Заголовок Facebook, Twitter (рус)</mat-label>
                                <input formControlName="opengraph_header_ru" matInput>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field class="width-100">
                                <mat-label>Опис Facebook, Twitter (укр)</mat-label>
                                <textarea formControlName="opengraph_description_ua" rows="5" matInput></textarea>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field class="width-100">
                                <mat-label>Опис Facebook, Twitter (рус)</mat-label>
                                <textarea formControlName="opengraph_description_ru" rows="5" matInput></textarea>
                            </mat-form-field>
                        </div>

                        <div class="width-100">
                            <label class="d-block mb-12 fw-semibold gray-color">Зображення Facebook, Twitter</label>
                            <input type="file" (change)="uploadImage($event, 'imageOpenGraph')" accept="image/*" formControlName="opengraph_image">
                            <br/>
                            <img *ngIf="imageOpenGraph" [src]="backendUrl + '/uploads/' + imageOpenGraph" class="border-radius mt-15" width="200">
                        </div>

                    </div>
                </mat-tab>
            </mat-tab-group>
        </form>

    </mat-card-content>
</mat-card>
