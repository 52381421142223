<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Фільтри</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Дашборд
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Каталог</li>
        <li class="breadcrumb-item gray-color">Фільтри</li>
        <li class="breadcrumb-item fw-semibold">Редагування фільтру</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header>
        <h5 class="mb-0">Редагування фільтру</h5>
        <div class="d-flex align-items-center">
            <button mat-raised-button class="tagus small margin-right-10px" form="form" type="submit">Зберегти</button>
            <a class="small" mat-stroked-button routerLink="/main/catalog/filters" color="warn">Вийти</a>
        </div>
    </mat-card-header>
    <mat-card-content>
        <app-local-error></app-local-error>
        <app-local-success></app-local-success>
        <form [formGroup]="form" (submit)="onFormSubmit(form)" id="form">
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                <!-- Загальне -->
                <mat-tab label="Загальне"><br>
                    <div class="row" style="margin: 0;">

                        <mat-form-field class="example-full-width">
                            <mat-label>Назва (укр)</mat-label>
                            <input formControlName="name_ua" matInput>
                            <mat-error *ngIf="(formWasSubmitted && form.controls['name_ua'].invalid) || (form.controls['name_ua'].invalid && form.controls['name_ua'].touched)">
                                <ng-container *ngIf="form.controls['name_ua'].errors?.['required']">
                                    Обов'язкове поле
                                </ng-container>
                                <ng-container *ngIf="form.controls['name_ua'].errors?.['minlength']">
                                    Поле може містити мінімум 2 символи
                                </ng-container>
                                <ng-container *ngIf="form.controls['name_ua'].errors?.['maxlength']">
                                    Поле може містити максимум 255 символів
                                </ng-container>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="example-full-width">
                            <mat-label>Назва (рус)</mat-label>
                            <input formControlName="name_ru" matInput>
                            <mat-error *ngIf="(formWasSubmitted && form.controls['name_ru'].invalid) || (form.controls['name_ru'].invalid && form.controls['name_ru'].touched)">
                                <ng-container *ngIf="form.controls['name_ru'].errors?.['required']">
                                    Обов'язкове поле
                                </ng-container>
                                <ng-container *ngIf="form.controls['name_ru'].errors?.['minlength']">
                                    Поле може містити мінімум 2 символи
                                </ng-container>
                                <ng-container *ngIf="form.controls['name_ru'].errors?.['maxlength']">
                                    Поле може містити максимум 255 символів
                                </ng-container>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="width-100">
                            <mat-label>URL</mat-label>
                            <input formControlName="url" matInput>
                            <mat-error *ngIf="(formWasSubmitted && form.controls['url'].invalid) || (form.controls['url'].invalid && form.controls['url'].touched)">
                                <ng-container *ngIf="form.controls['url'].errors?.['required']">
                                    Обов'язкове поле
                                </ng-container>
                                <ng-container *ngIf="form.controls['url'].errors?.['pattern']">
                                    Поле повинно містити тільки латинські літери, цифри та прочерки
                                </ng-container>
                                <ng-container *ngIf="form.controls['url'].errors?.['minlength']">
                                    Поле може містити мінімум 2 символи
                                </ng-container>
                                <ng-container *ngIf="form.controls['url'].errors?.['maxlength']">
                                    Поле може містити максимум 255 символів
                                </ng-container>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="width-100">
                            <mat-label>Статус</mat-label>
                            <mat-select formControlName="status">
                                <mat-option [value]="1">Активно</mat-option>
                                <mat-option [value]="0">Не активно</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="width-100">
                            <mat-label>Показувати у категоріях</mat-label>
                            <br/>
                            <a style="color: lightseagreen;margin-right: 10px;cursor: pointer" (click)="selectAllCategories()">
                                ✅ Вибрати всі значення
                            </a>
                            <a style="color: darkred;cursor: pointer;" (click)="clearAllCategories()">
                                ❌ Зняти всі значення
                            </a>
                            <div style="max-height: 200px;overflow-y: auto">
                                <ng-container *ngFor="let category of categoriesForDropdown">
                                    <mat-checkbox
                                        class="example-margin"
                                        style="display: block"
                                        [value]="category._id"
                                        (change)="updateSelectedCategories(category._id)"
                                        [checked]="isSelectedCategories(category._id)"
                                    >
                                        {{ category.name_ua }}
                                    </mat-checkbox>
                                </ng-container>
                            </div>
                        </div>

                    </div>
                </mat-tab>
                <!-- Значення -->
                <mat-tab label="Значення"><br>
                    <div class="row" style="margin: 0;">
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <button type="button" mat-raised-button class="tagus" (click)="sync()">Синхронізувати</button>
                        </div>
                    </div>

                    <div *ngFor="let addedFilterValue of addedFilterValues; let i = index">
                        <hr/>
                        <div class="row" style="margin: 0">
                            <div class="col-lg-4 col-md-4 col-sm-4">
                                <mat-form-field class="width-100">
                                    <mat-label>Значення (укр)</mat-label>
                                    <input matInput (change)="onUpdateFilterValue(i, 'value_ua', $event)" [value]="addedFilterValues[i].value_ua || null">
                                </mat-form-field>
                                <div *ngIf="formWasSubmitted && !fieldValueUaInFilterValues[i].isValid" class="alert alert-danger" role="alert">
                                    Обов'язкове поле
                                </div>

                                <mat-form-field class="width-100">
                                    <mat-label>Оригінальна назва (укр)</mat-label>
                                    <input matInput disabled value="{{ addedFilterValues[i].name_ua }}">
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4">
                                <mat-form-field class="width-100">
                                    <mat-label>Значення (рус)</mat-label>
                                    <input matInput (change)="onUpdateFilterValue(i, 'value_ru', $event)" [value]="addedFilterValues[i].value_ru || null">
                                </mat-form-field>
                                <div *ngIf="formWasSubmitted && !fieldValueRuInFilterValues[i].isValid" class="alert alert-danger" role="alert">
                                    Обов'язкове поле
                                </div>

                                <mat-form-field class="width-100">
                                    <mat-label>Оригінальна назва (рус)</mat-label>
                                    <input matInput disabled value="{{ addedFilterValues[i].name_ru }}">
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4">
                                <mat-form-field appearance="fill" class="width-100">
                                    <mat-label>Статус</mat-label>
                                    <mat-select [(value)]="addedFilterValues[i].status" (selectionChange)="onUpdateFilterValue(i, 'status', $event)">
                                        <mat-option [value]="true">Активно</mat-option>
                                        <mat-option [value]="false">Не активно</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="formWasSubmitted && !fieldValueStatusInFilterValues[i].isValid" class="alert alert-danger" role="alert">
                                    Обов'язкове поле
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </form>
    </mat-card-content>
</mat-card>
