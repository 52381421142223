<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Remixicon</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Icons</li>
        <li class="breadcrumb-item fw-semibold">Remixicon</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card" [class.component-dark-theme]="themeService.isDark()">
    <mat-card-content>
        <div class="icon-demo">
            <span class="fw-semibold me-10">Use example:</span>
            <code>
                &lt;<span class="red">i</span> <span class="green">class</span>=<span class="yellow">&quot;ri-home-line&quot;</span>&gt;&lt;/<span
                class="red">i</span>&gt;
            </code> 
            <span class="ms-10 me-10 gray-color">or</span>
            <code>
                &lt;<span class="red">span</span> <span class="green">class</span>=<span class="yellow">&quot;ri-home-line&quot;</span>&gt;&lt;/<span class="red">span</span>&gt;
            </code>
        </div>
        <div class="row justify-content-center">
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-home-heart-line"></i>
                    <span class="d-block gray-color">ri-home-heart-line</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-store-fill"></i>
                    <span class="d-block gray-color">ri-store-fill</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-mail-line"></i>
                    <span class="d-block gray-color">ri-mail-line</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-award-fill"></i>
                    <span class="d-block gray-color">ri-award-fill</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-bookmark-3-line"></i>
                    <span class="d-block gray-color">ri-bookmark-3-line</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-pie-chart-2-fill"></i>
                    <span class="d-block gray-color">ri-pie-chart-2-fill</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-flag-line"></i>
                    <span class="d-block gray-color">ri-flag-line</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-reply-all-line"></i>
                    <span class="d-block gray-color">ri-reply-all-line</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-creative-commons-line"></i>
                    <span class="d-block gray-color">ri-creative-commons-line</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-chat-2-line"></i>
                    <span class="d-block gray-color">ri-chat-2-line</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-message-2-line"></i>
                    <span class="d-block gray-color">ri-message-2-line</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-contrast-fill"></i>
                    <span class="d-block gray-color">ri-contrast-fill</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-scissors-line"></i>
                    <span class="d-block gray-color">ri-scissors-line</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-focus-3-line"></i>
                    <span class="d-block gray-color">ri-focus-3-line</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-layout-4-fill"></i>
                    <span class="d-block gray-color">ri-layout-4-fill</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-layout-grid-fill"></i>
                    <span class="d-block gray-color">ri-layout-grid-fill</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-code-line"></i>
                    <span class="d-block gray-color">ri-code-line</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-bug-2-fill"></i>
                    <span class="d-block gray-color">ri-bug-2-fill</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-git-repository-line"></i>
                    <span class="d-block gray-color">ri-git-repository-line</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-git-repository-private-line"></i>
                    <span class="d-block gray-color">ri-git-repository-private-line</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-mac-line"></i>
                    <span class="d-block gray-color">ri-mac-line</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-hard-drive-line"></i>
                    <span class="d-block gray-color">ri-hard-drive-line</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-sd-card-mini-line"></i>
                    <span class="d-block gray-color">ri-sd-card-mini-line</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-sensor-line"></i>
                    <span class="d-block gray-color">ri-sensor-line</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-fingerprint-fill"></i>
                    <span class="d-block gray-color">ri-fingerprint-fill</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-wifi-line"></i>
                    <span class="d-block gray-color">ri-wifi-line</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-signal-wifi-off-fill"></i>
                    <span class="d-block gray-color">ri-signal-wifi-off-fill</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-task-fill"></i>
                    <span class="d-block gray-color">ri-task-fill</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-book-open-fill"></i>
                    <span class="d-block gray-color">ri-book-open-fill</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-file-download-fill"></i>
                    <span class="d-block gray-color">ri-file-download-fill</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-folder-open-fill"></i>
                    <span class="d-block gray-color">ri-folder-open-fill</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-bold"></i>
                    <span class="d-block gray-color">ri-bold</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-superscript"></i>
                    <span class="d-block gray-color">ri-superscript</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-double-quotes-l"></i>
                    <span class="d-block gray-color">ri-double-quotes-l</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-a-b"></i>
                    <span class="d-block gray-color">ri-a-b</span>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <i class="ri-number-4"></i>
                    <span class="d-block gray-color">ri-number-4</span>
                </div>
            </div>
        </div>
        <div class="text-center mt-25">
            <a mat-raised-button href="https://remixicon.com/" class="tagus" target="_blank">View All Icons</a>
        </div>
    </mat-card-content>
</mat-card>