<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Line Charts</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Apexcharts</li>
        <li class="breadcrumb-item fw-semibold">Line Charts</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Line Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-basic-line-chart></app-basic-line-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Zoomable Line Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-zoomable-line-chart></app-zoomable-line-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Gradient Line Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-gradient-line-chart></app-gradient-line-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Dashed Line Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-dashed-line-chart></app-dashed-line-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Stepline Line Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-stepline-line-chart></app-stepline-line-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Brush Line Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-brush-line-chart></app-brush-line-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Syncing Line Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-syncing-line-chart></app-syncing-line-chart>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Area Charts</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Apexcharts</li>
        <li class="breadcrumb-item fw-semibold">Area Charts</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Area Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-basic-area-chart></app-basic-area-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Spline Area Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-spline-area-chart></app-spline-area-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Datetime Area Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-datetime-area-chart></app-datetime-area-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Negative Values Area Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-negative-area-chart></app-negative-area-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Stacked Area Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-stacked-area-chart></app-stacked-area-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Missing Null Values Area Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-missing-area-chart></app-missing-area-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Github Style Area Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-github-area-chart></app-github-area-chart>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Column Charts</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Apexcharts</li>
        <li class="breadcrumb-item fw-semibold">Column Charts</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Column Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-basic-column-chart></app-basic-column-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Data Labels Column Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-datalabels-column-chart></app-datalabels-column-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Stacked Column Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-stacked-column-chart></app-stacked-column-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Range Column Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-range-column-chart></app-range-column-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Rotated Labels Column Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-rotated-labels-column-chart></app-rotated-labels-column-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Distributed Column Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-distributed-column-chart></app-distributed-column-chart>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Mixed Charts</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Apexcharts</li>
        <li class="breadcrumb-item fw-semibold">Mixed Charts</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Line & Column Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-line-column-chart></app-line-column-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Multiple Y-Axis Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-multiple-yaxis-chart></app-multiple-yaxis-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Line & Area Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-line-area-chart></app-line-area-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Line, Column & Area Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-line-column-area-chart></app-line-column-area-chart>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Pie Charts</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Apexcharts</li>
        <li class="breadcrumb-item fw-semibold">Pie Charts</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Pie Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-basic-pie-chart></app-basic-pie-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Pie Donut Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-pie-donut-chart></app-pie-donut-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Pie Monochrome Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-pie-monochrome-chart></app-pie-monochrome-chart>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">RadialBar Charts</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Apexcharts</li>
        <li class="breadcrumb-item fw-semibold">RadialBar Charts</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic RadialBar Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-basic-radialbar-chart></app-basic-radialbar-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Multiple RadialBar Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-multiple-radialbar-chart></app-multiple-radialbar-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Custom Angle Circle RadialBar Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-circle-radialbar-chart></app-circle-radialbar-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Gradient RadialBar Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-gradient-radialbar-chart></app-gradient-radialbar-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Strocked Circular Gauge RadialBar Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-stroked-radialbar-chart></app-stroked-radialbar-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Semi Circular Gauge RadialBar Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-circular-radialbar-chart></app-circular-radialbar-chart>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Radar Charts</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Apexcharts</li>
        <li class="breadcrumb-item fw-semibold">Radar Charts</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Radar Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-basic-radar-chart></app-basic-radar-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Multiple Radar Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-multiple-radar-chart></app-multiple-radar-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Polygon Radar Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-polygon-radar-chart></app-polygon-radar-chart>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Polar Charts</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Apexcharts</li>
        <li class="breadcrumb-item fw-semibold">Polar Charts</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Polar Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-basic-polar-chart></app-basic-polar-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Monochrome Polar Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-monochrome-polar-chart></app-monochrome-polar-chart>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Extra Charts</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Apexcharts</li>
        <li class="breadcrumb-item fw-semibold">Extra Charts</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Range Area Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-basic-range-area-chart></app-basic-range-area-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Timeline Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-basic-timeline-chart></app-basic-timeline-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Candlestick Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-basic-candlestick-chart></app-basic-candlestick-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Boxplot Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-basic-boxplot-chart></app-basic-boxplot-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Bubble Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-basic-bubble-chart></app-basic-bubble-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Scatter Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-basic-scatter-chart></app-basic-scatter-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Heatmap Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-basic-heatmap-chart></app-basic-heatmap-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Treemap Chart</h5>
            </mat-card-header>
            <mat-card-content>
                <app-basic-treemap-chart></app-basic-treemap-chart>
            </mat-card-content>
        </mat-card>
    </div>
</div>