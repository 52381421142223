<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Налаштування</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Дашборд
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Система</li>
        <li class="breadcrumb-item fw-semibold">Налаштування</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header>
        <h5 class="mb-0">Налаштування</h5>
        <div class="d-flex align-items-center">
            <button mat-raised-button class="tagus small margin-right-10px" form="form" type="submit">Зберегти</button>
            <a class="small" mat-stroked-button routerLink="/main/catalog/categories" color="warn">Вийти</a>
        </div>
    </mat-card-header>
    <app-local-success></app-local-success>
    <app-local-error></app-local-error>
    <mat-card-content>
        <form [formGroup]="form" (submit)="onFormSubmit(form)" id="form">
            <div class="row" style="margin: 0">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <mat-form-field class="width-100">
                        <mat-label>Виводити блок «Залишилося n-товарів» при залишку</mat-label>
                        <input formControlName="min_quantity_notify" matInput>
                        <mat-error *ngIf="(formWasSubmitted && form.controls['min_quantity_notify'].invalid) || (form.controls['min_quantity_notify'].invalid && form.controls['min_quantity_notify'].touched)">
<!--                            <ng-container *ngIf="form.controls['min_quantity_notify'].errors?.['required']">-->
<!--                                Обов'язкове полеmin_quantity_notify-->
<!--                            </ng-container>-->
                            <ng-container *ngIf="form.controls['min_quantity_notify'].errors?.['pattern']">
                                Поле може містити тільки цифри
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="alert alert-primary" role="alert">
                        Значення, при якому буде показуватися блок «Залишилося n-товарів»,
                        або залиште його пустим
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <mat-form-field class="width-100">
                        <mat-label>Мінімальна сумма замовлення (грн)</mat-label>
                        <input formControlName="min_order_sum" matInput>
                        <mat-error *ngIf="(formWasSubmitted && form.controls['min_order_sum'].invalid) || (form.controls['min_order_sum'].invalid && form.controls['min_order_sum'].touched)">
                            <ng-container *ngIf="form.controls['min_order_sum'].errors?.['pattern']">
                                Поле може містити тільки цифри
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="alert alert-primary" role="alert">
                        Якщо поле пусте, функція буде відключена
                    </div>
                </div>
            </div>
        </form>
    </mat-card-content>
</mat-card>
