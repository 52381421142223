import { Component } from '@angular/core';

@Component({
  selector: 'app-po-stats',
  templateUrl: './po-stats.component.html',
  styleUrls: ['./po-stats.component.scss']
})
export class PoStatsComponent {

}
