<div class="chart">
    <apx-chart
        [series]="chartOptions.series!"
        [chart]="chartOptions.chart!"
        [xaxis]="chartOptions.xaxis!"
        [stroke]="chartOptions.stroke!"
        [tooltip]="chartOptions.tooltip!"
        [dataLabels]="chartOptions.dataLabels!"
        [fill]="chartOptions.fill!"
        [yaxis]="chartOptions.yaxis!"
        [title]="chartOptions.title!"
        [legend]="chartOptions.legend!"
    ></apx-chart>
</div>