<form class="example-form">
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Number</mat-label>
        <input type="text"
            placeholder="Pick one"
            aria-label="Number"
            matInput
            [formControl]="myControl"
            [matAutocomplete]="auto"
        >
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of options" [value]="option">
                {{option}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>