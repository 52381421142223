<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Замовлення</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Дашборд
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Продажi</li>
        <li class="breadcrumb-item fw-semibold">Замовлення</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <app-local-success></app-local-success>
    <app-modal-confirmation *ngIf="showConfirmation" (confirmed)="onConfirmationRemove($event)"></app-modal-confirmation>
    <mat-card-content>
        <div class="mat-elevation-z8 tagus-mat-table">
            <table mat-table [dataSource]="dataSource">

                <ng-container matColumnDef="order_number">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Номер</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        <div class="d-flex align-items-center">
                            <a [routerLink]="['/main/sales/orders/edit/', element._id]" class="d-flex align-items-center product-info">
                                {{element.order_number}}
                            </a>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="client">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Клієнт</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        <div class="d-flex align-items-center">
                            {{element.client_firstname}} {{element.client_lastname}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Статус</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        <div class="d-flex align-items-center">
                            <ng-container *ngIf="element.status === 'in_process'">В обробцi</ng-container>
                            <ng-container *ngIf="element.status === 'not_payed'">Не сплачений</ng-container>
                            <ng-container *ngIf="element.status === 'refund'">Повернення</ng-container>
                            <ng-container *ngIf="element.status === 'delivered'">Доставлено</ng-container>
                            <ng-container *ngIf="element.status === 'failed'">Невдалий</ng-container>
                            <ng-container *ngIf="element.status === 'canceled'">Скасовано</ng-container>
                            <ng-container *ngIf="element.status === 'sent'">Відправлено</ng-container>
                            <ng-container *ngIf="element.status === 'accepted'">Прийнятий</ng-container>
                            <ng-container *ngIf="element.status === 'completed'">Завершений</ng-container>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="total_sum">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Загалом</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        <div class="d-flex align-items-center">
                            {{element.total_sum}} ₴
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Дата додавання</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        <div class="d-flex align-items-center">
                            {{ element.created_at | date:'dd.MM.yy HH:mm' }}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="updated_at">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Дата редагування</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        <div class="d-flex align-items-center">
                            {{ element.updated_at | date:'dd.MM.yy HH:mm' }}
                        </div>
                    </td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="action">
                    <th style="display: flex;justify-content: flex-end" mat-header-cell *matHeaderCellDef>Дія</th>
                    <td style="display: flex;justify-content: flex-end" mat-cell *matCellDef="let element">
                        <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                            <i class="ri-more-fill"></i>
                        </button>
                        <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                            <a [routerLink]="['/main/sales/orders/edit/', element._id]" mat-menu-item class="bg-transparent gray-color border-none position-relative">
                                <i class="ri-edit-line"></i> Редагувати
                            </a>
                            <button (click)="remove(element._id)" mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-delete-bin-line"></i> Видалити
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator
                [pageSizeOptions]="[10, 15, 20]"
                showFirstLastButtons
            >
            </mat-paginator>
        </div>
    </mat-card-content>
</mat-card>
