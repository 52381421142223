<form class="example-form">
    <input type="text"
        placeholder="Search for a street"
        [formControl]="control"
        [matAutocomplete]="auto"
        class="example-input"
    >
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let street of filteredStreets | async" [value]="street">
            {{street}}
        </mat-option>
    </mat-autocomplete>
</form>