import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Editor, Toolbar} from "ngx-editor";
import {TOOLBAR_CONFIG} from "../../../../configs/toolbar.config";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../../../services/api.service";
import {UtilityService} from "../../../../services/utility.service";
import {ErrorService} from "../../../common/notifications/errors/error.service";
import {SuccessService} from "../../../common/notifications/success/success.service";
import {ErrorTypeEnum} from "../../../../enums/error-type.enum";
import {DEFAULT_MESSAGES} from "../../../../constants/default-messages";
import {EndpointTypeEnum} from "../../../../enums/endpoint-type.enum";
import {AngularEditorConfig, UploadResponse} from "@kolkov/angular-editor";
import {ToolbarEditor2ConfigService} from "../../../../configs/toolbar-editor-2.config";

@Component({
  selector: 'app-main-page-edit',
  templateUrl: './main-page-edit.component.html',
  styleUrl: './main-page-edit.component.scss'
})
export class MainPageEditComponent implements OnInit, OnDestroy {
    backendUrl: string = environment.backendUrl;
    domain: string = environment.domain;
    page: any;
    pageId: any = this.route.snapshot.paramMap.get('id');
    form!: FormGroup;
    formWasSubmitted = false;
    imageCover: string;
    imageOpenGraph: string;
    editorDescriptionRu: Editor;
    editorDescriptionUa: Editor;
    toolbar: Toolbar = TOOLBAR_CONFIG;
    editorConfig: AngularEditorConfig;

    @ViewChild('input') input: ElementRef<HTMLInputElement>;

    constructor(
        private router: Router,
        private apiService: ApiService,
        private utilityService: UtilityService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private errorService: ErrorService,
        private successService: SuccessService,
        private toolbarEditor2ConfigService: ToolbarEditor2ConfigService,
    ) {
        this.editorConfig = this.toolbarEditor2ConfigService.getConfig();
        this.initForm();
    }

    // инициализация формы
    initForm(): void {
        // инициализируем поля из формы и валидируем
        this.form = this.formBuilder.group({
            // ru
            name_ru:               ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
            content_ru:            [''],
            meta_title_ru:         ['', [Validators.minLength(2), Validators.maxLength(255)]],
            meta_description_ru:   ['', [Validators.minLength(2), Validators.maxLength(255)]],
            // ua
            name_ua:               ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
            content_ua:            [''],
            meta_title_ua:         ['', [Validators.minLength(2), Validators.maxLength(255)]],
            meta_description_ua:   ['', [Validators.minLength(2), Validators.maxLength(255)]],
            // общие поля
            image:                 ['', [Validators.pattern(/\.(jpg|png|svg)$/i)]],
            url:                   ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255), Validators.pattern(/^[a-z0-9-]+$/i)]],
            opengraph_header_ru:   [''],
            opengraph_header_ua:   [''],
            opengraph_description_ru: [''],
            opengraph_description_ua: [''],
            opengraph_image:       ['', [Validators.pattern(/\.(jpg|png|svg)$/i)]],
        });
    }

    // подписка на изменения в поле name для генерации url
    ngOnInit() {
        this.editorDescriptionRu = new Editor();
        this.editorDescriptionUa = new Editor();

        if (this.pageId !== null) {
            this.getPage(this.pageId);
        }
    }

    ngOnDestroy(): void {
        this.editorDescriptionRu.destroy();
        this.editorDescriptionUa.destroy();
    }

    // получение данных для формы
    getPage(pageId: string) {
        this.apiService
            .get(EndpointTypeEnum.Main, '/pages', true, pageId)
            .subscribe({
                next: (response: any) => {
                    this.page = response.data;
                    this.imageCover = this.page.image;
                    this.imageOpenGraph = this.page.opengraph_image;
                    // установка значения полей
                    this.form.patchValue({
                        name_ru: this.page.name_ru,
                        name_ua: this.page.name_ua,
                        content_ru: this.page.content_ru,
                        content_ua: this.page.content_ua,
                        meta_title_ru: this.page.meta_title_ru,
                        meta_title_ua: this.page.meta_title_ua,
                        meta_description_ru: this.page.meta_description_ru,
                        meta_description_ua: this.page.meta_description_ua,
                        url: this.page.url,
                        opengraph_header_ru:  this.page.opengraph_header_ru,
                        opengraph_header_ua: this.page.opengraph_header_ua,
                        opengraph_description_ru: this.page.opengraph_description_ru,
                        opengraph_description_ua:  this.page.opengraph_description_ua,
                    });
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    // отправка формы
    onFormSubmit(form: FormGroup): void {
        this.formWasSubmitted = true;

        // если есть ошибки валидации, не даем отправить форму
        if (form.invalid) {
            this.errorService.addError(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.FORM_ERROR]);
            return;
        }

        // получаем значения из формы
        const formValue = form.value;
        formValue.image = this.imageCover;
        formValue.opengraph_image = this.imageOpenGraph;

        // отправка формы
        this.apiService
            .put(EndpointTypeEnum.Main, '/pages', true, form.value, this.pageId)
            .subscribe({
                next: (response: any) => {
                    this.getPage(this.pageId);
                    this.successService.add(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.SUCCESS]);
                },
                error: (err: any) => {
                    this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                },
            });
    }

    uploadImage(event: any, targetVariable: 'imageCover' | 'imageOpenGraph') {
        if (event.target.files.length !== 0) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('image', file);
            this.apiService
                .postMultipartFormData(EndpointTypeEnum.Requests, '/upload-files', true, formData)
                .subscribe({
                    next: (response: any) => {
                        this[targetVariable] = response.data;
                        this.errorService.clearErrors(ErrorTypeEnum.Local);
                    },
                    error: (err: any) => {
                        this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                    },
                });
        } else {
            targetVariable === 'imageCover' ? this.imageCover = '' : this.imageOpenGraph = '';
            if (targetVariable === 'imageCover') {
                this.imageCover = '';
            } else if (targetVariable === 'imageOpenGraph') {
                this.imageOpenGraph = '';
            }
        }
    }
}
