<app-sa-stats></app-sa-stats>

<div class="row">
    <div class="col-lg-6">

        <app-sa-revenue-summary></app-sa-revenue-summary>

    </div>
    <div class="col-lg-6">

        <app-sa-sales-analytics></app-sa-sales-analytics>

    </div>
</div>

<app-sa-audience-overview></app-sa-audience-overview>

<div class="row">
    <div class="col-xxl-8">

        <app-sa-all-projects></app-sa-all-projects>

    </div>
    <div class="col-xxl-4">

        <app-sa-messages></app-sa-messages>

    </div>
</div>

<div class="row justify-content-center">
    <div class="col-md-6 col-xxl-4">
        <app-milestones-overview></app-milestones-overview>
    </div>
    <div class="col-md-6 col-xxl-4">
        <app-sa-completed-tasks></app-sa-completed-tasks>
    </div>
    <div class="col-md-6 col-xxl-4">
        <app-sa-tasks-performance></app-sa-tasks-performance>
    </div>
</div>