<mat-form-field appearance="fill">
    <mat-label>Horizontal position</mat-label>
    <mat-select [(value)]="horizontalPosition">
        <mat-option value="start">Start</mat-option>
        <mat-option value="center">Center</mat-option>
        <mat-option value="end">End</mat-option>
        <mat-option value="left">Left</mat-option>
        <mat-option value="right">Right</mat-option>
    </mat-select>
</mat-form-field>
<mat-form-field appearance="fill">
    <mat-label>Vertical position</mat-label>
    <mat-select [(value)]="verticalPosition">
        <mat-option value="top">Top</mat-option>
        <mat-option value="bottom">Bottom</mat-option>
    </mat-select>
</mat-form-field>

<button mat-stroked-button (click)="openSnackBar()" aria-label="Show an example snack-bar">
    Pool party!
</button>