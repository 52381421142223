<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Tooltip</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">UI Kit</li>
        <li class="breadcrumb-item fw-semibold">Tooltip</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Tooltip</h5>
            </mat-card-header>
            <mat-card-content>
                <button mat-raised-button
                    matTooltip="Info about the action"
                    aria-label="Button that displays a tooltip when focused or hovered over"
                >
                    Action
                </button>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Tooltip with a Show & Hide Delay</h5>
            </mat-card-header>
            <mat-card-content>
                <app-tooltip-show-hide-delay></app-tooltip-show-hide-delay>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Positioned Tooltips</h5>
            </mat-card-header>
            <mat-card-content class="example-buttons">
                <button mat-flat-button matTooltip="Info about the action" matTooltipPosition="after">After</button>
                <button mat-flat-button class="tagus" matTooltip="Info about the action" matTooltipPosition="before">Before</button>
                <button mat-flat-button color="primary" matTooltip="Info about the action" matTooltipPosition="above">Above</button>
                <button mat-flat-button color="accent" matTooltip="Info about the action" matTooltipPosition="below">Below</button>
                <button mat-flat-button color="warn" matTooltip="Info about the action" matTooltipPosition="left">Left</button>
                <button mat-flat-button class="gray" matTooltip="Info about the action" matTooltipPosition="right">Right</button>
            </mat-card-content>
        </mat-card>
    </div>
</div>