<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Breadcrumb</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">UI Kit</li>
        <li class="breadcrumb-item fw-semibold">Breadcrumb</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Basic Breadcrumb</h5>
    </mat-card-header>
    <mat-card-content>
        <nav aria-label="t-breadcrumb">
            <ol class="t-breadcrumb">
                <li class="t-breadcrumb-item active" aria-current="page">Home</li>
            </ol>
        </nav>

        <nav aria-label="t-breadcrumb">
            <ol class="t-breadcrumb">
                <li class="t-breadcrumb-item"><a routerLink=".">Home</a></li>
                <li class="t-breadcrumb-item active" aria-current="page">Library</li>
            </ol>
        </nav>

        <nav aria-label="t-breadcrumb">
            <ol class="t-breadcrumb">
                <li class="t-breadcrumb-item"><a routerLink=".">Home</a></li>
                <li class="t-breadcrumb-item"><a routerLink=".">Library</a></li>
                <li class="t-breadcrumb-item active" aria-current="page">Data</li>
            </ol>
        </nav>

        <nav aria-label="t-breadcrumb">
            <ol class="t-breadcrumb">
                <li class="t-breadcrumb-item"><a routerLink=".">Home</a></li>
                <li class="t-breadcrumb-item"><a routerLink=".">Library</a></li>
                <li class="t-breadcrumb-item"><a routerLink=".">Tagus</a></li>
                <li class="t-breadcrumb-item active" aria-current="page">Data</li>
            </ol>
        </nav>
    </mat-card-content>
</mat-card>