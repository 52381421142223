<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Team</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Projects</li>
        <li class="breadcrumb-item fw-semibold">Team</li>
    </ol>
</div>

<div class="row" [class.component-rtl-enabled]="themeService.isRTLEnabled()">
    <div class="col-sm-6">
        <mat-card class="mb-25 p-team-card tagus-card">
            <mat-card-header>
                <div>
                    <h5 class="mb-8">UI/UX Design Team</h5>
                    <p>Contrary to popular belief, lorem Ipsum is not simply</p>
                </div>
                <div>
                    <button type="button" mat-button class="position-relative dot-btn p-0 border-none" [matMenuTriggerFor]="monthMenu">
                        <i class="ri-more-fill"></i>
                    </button>
                    <mat-menu #monthMenu="matMenu" class="monthMenu" xPosition="before">
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Open Project
                        </button>
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Edit
                        </button>
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Delete
                        </button>
                    </mat-menu>
                </div>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-space-between">
                    <div class="d-flex align-items-center">
                        <img src="assets/img/user/user12.jpg" class="rounded-circle" width="60" alt="user">
                        <div class="title ms-15">
                            <h5 class="mb-5">Jason Haston</h5>
                            <span class="d-block main-color">Team Leader</span>
                        </div>
                    </div>
                    <div class="list">
                        <div class="images d-flex align-items-center">
                            <img src="assets/img/user/user15.jpg" class="rounded-circle" width="40" alt="user">
                            <img src="assets/img/user/user17.jpg" class="rounded-circle" width="40" alt="user">
                            <img src="assets/img/user/user16.jpg" class="rounded-circle" width="40" alt="user">
                            <div class="d-inline-block rounded-circle fw-semibold text-center">+4</div>
                        </div>
                    </div>
                </div>
                <div class="info d-flex align-items-center justify-content-space-between">
                    <div>
                        <span class="d-block gray-color fs-14 mb-5">245</span>
                        <span class="d-block gray-color fs-16 fw-semibold">Design Project</span>
                    </div>
                    <div>
                        <span class="d-block gray-color fs-14 mb-5">1085/765</span>
                        <span class="d-block gray-color fs-16 fw-semibold">Task Reports</span>
                    </div>
                    <div>
                        <a mat-raised-button class="gray" routerLink="/">View Details <i class="flaticon-chevron"></i></a>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6">
        <mat-card class="mb-25 p-team-card tagus-card">
            <mat-card-header>
                <div>
                    <h5 class="mb-8">Frontend Developer Team</h5>
                    <p>Contrary to popular belief, lorem Ipsum is not simply</p>
                </div>
                <div>
                    <button type="button" mat-button class="position-relative dot-btn p-0 border-none" [matMenuTriggerFor]="monthMenu">
                        <i class="ri-more-fill"></i>
                    </button>
                    <mat-menu #monthMenu="matMenu" class="monthMenu" xPosition="before">
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Open Project
                        </button>
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Edit
                        </button>
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Delete
                        </button>
                    </mat-menu>
                </div>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-space-between">
                    <div class="d-flex align-items-center">
                        <img src="assets/img/user/user8.jpg" class="rounded-circle" width="60" alt="user">
                        <div class="title ms-15">
                            <h5 class="mb-5">Willie Miller</h5>
                            <span class="d-block main-color">Team Leader</span>
                        </div>
                    </div>
                    <div class="list">
                        <div class="images d-flex align-items-center">
                            <img src="assets/img/user/user24.jpg" class="rounded-circle" width="40" alt="user">
                            <img src="assets/img/user/user23.jpg" class="rounded-circle" width="40" alt="user">
                            <img src="assets/img/user/user22.jpg" class="rounded-circle" width="40" alt="user">
                            <div class="d-inline-block rounded-circle fw-semibold text-center">+4</div>
                        </div>
                    </div>
                </div>
                <div class="info d-flex align-items-center justify-content-space-between">
                    <div>
                        <span class="d-block gray-color fs-14 mb-5">321</span>
                        <span class="d-block gray-color fs-16 fw-semibold">Design Project</span>
                    </div>
                    <div>
                        <span class="d-block gray-color fs-14 mb-5">2342/213</span>
                        <span class="d-block gray-color fs-16 fw-semibold">Task Reports</span>
                    </div>
                    <div>
                        <a mat-raised-button class="gray" routerLink="/">View Details <i class="flaticon-chevron"></i></a>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6">
        <mat-card class="mb-25 p-team-card tagus-card">
            <mat-card-header>
                <div>
                    <h5 class="mb-8">Web Design Team</h5>
                    <p>Contrary to popular belief, lorem Ipsum is not simply</p>
                </div>
                <div>
                    <button type="button" mat-button class="position-relative dot-btn p-0 border-none" [matMenuTriggerFor]="monthMenu">
                        <i class="ri-more-fill"></i>
                    </button>
                    <mat-menu #monthMenu="matMenu" class="monthMenu" xPosition="before">
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Open Project
                        </button>
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Edit
                        </button>
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Delete
                        </button>
                    </mat-menu>
                </div>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-space-between">
                    <div class="d-flex align-items-center">
                        <img src="assets/img/user/user19.jpg" class="rounded-circle" width="60" alt="user">
                        <div class="title ms-15">
                            <h5 class="mb-5">Jordan Stevenson</h5>
                            <span class="d-block main-color">Team Leader</span>
                        </div>
                    </div>
                    <div class="list">
                        <div class="images d-flex align-items-center">
                            <img src="assets/img/user/user21.jpg" class="rounded-circle" width="40" alt="user">
                            <img src="assets/img/user/user20.jpg" class="rounded-circle" width="40" alt="user">
                            <img src="assets/img/user/user19.jpg" class="rounded-circle" width="40" alt="user">
                            <div class="d-inline-block rounded-circle fw-semibold text-center">+4</div>
                        </div>
                    </div>
                </div>
                <div class="info d-flex align-items-center justify-content-space-between">
                    <div>
                        <span class="d-block gray-color fs-14 mb-5">542</span>
                        <span class="d-block gray-color fs-16 fw-semibold">Design Project</span>
                    </div>
                    <div>
                        <span class="d-block gray-color fs-14 mb-5">4521/234</span>
                        <span class="d-block gray-color fs-16 fw-semibold">Task Reports</span>
                    </div>
                    <div>
                        <a mat-raised-button class="gray" routerLink="/">View Details <i class="flaticon-chevron"></i></a>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6">
        <mat-card class="mb-25 p-team-card tagus-card">
            <mat-card-header>
                <div>
                    <h5 class="mb-8">Backend Developer Team</h5>
                    <p>Contrary to popular belief, lorem Ipsum is not simply</p>
                </div>
                <div>
                    <button type="button" mat-button class="position-relative dot-btn p-0 border-none" [matMenuTriggerFor]="monthMenu">
                        <i class="ri-more-fill"></i>
                    </button>
                    <mat-menu #monthMenu="matMenu" class="monthMenu" xPosition="before">
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Open Project
                        </button>
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Edit
                        </button>
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Delete
                        </button>
                    </mat-menu>
                </div>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-space-between">
                    <div class="d-flex align-items-center">
                        <img src="assets/img/user/user9.jpg" class="rounded-circle" width="60" alt="user">
                        <div class="title ms-15">
                            <h5 class="mb-5">Marnie Flowers</h5>
                            <span class="d-block main-color">Team Leader</span>
                        </div>
                    </div>
                    <div class="list">
                        <div class="images d-flex align-items-center">
                            <img src="assets/img/user/user18.jpg" class="rounded-circle" width="40" alt="user">
                            <img src="assets/img/user/user17.jpg" class="rounded-circle" width="40" alt="user">
                            <img src="assets/img/user/user16.jpg" class="rounded-circle" width="40" alt="user">
                            <div class="d-inline-block rounded-circle fw-semibold text-center">+4</div>
                        </div>
                    </div>
                </div>
                <div class="info d-flex align-items-center justify-content-space-between">
                    <div>
                        <span class="d-block gray-color fs-14 mb-5">245</span>
                        <span class="d-block gray-color fs-16 fw-semibold">Design Project</span>
                    </div>
                    <div>
                        <span class="d-block gray-color fs-14 mb-5">1085/765</span>
                        <span class="d-block gray-color fs-16 fw-semibold">Task Reports</span>
                    </div>
                    <div>
                        <a mat-raised-button class="gray" routerLink="/">View Details <i class="flaticon-chevron"></i></a>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6">
        <mat-card class="mb-25 p-team-card tagus-card">
            <mat-card-header>
                <div>
                    <h5 class="mb-8">Marketing Team</h5>
                    <p>Contrary to popular belief, lorem Ipsum is not simply</p>
                </div>
                <div>
                    <button type="button" mat-button class="position-relative dot-btn p-0 border-none" [matMenuTriggerFor]="monthMenu">
                        <i class="ri-more-fill"></i>
                    </button>
                    <mat-menu #monthMenu="matMenu" class="monthMenu" xPosition="before">
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Open Project
                        </button>
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Edit
                        </button>
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Delete
                        </button>
                    </mat-menu>
                </div>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-space-between">
                    <div class="d-flex align-items-center">
                        <img src="assets/img/user/user22.jpg" class="rounded-circle" width="60" alt="user">
                        <div class="title ms-15">
                            <h5 class="mb-5">Alvarado Turner</h5>
                            <span class="d-block main-color">Team Leader</span>
                        </div>
                    </div>
                    <div class="list">
                        <div class="images d-flex align-items-center">
                            <img src="assets/img/user/user15.jpg" class="rounded-circle" width="40" alt="user">
                            <img src="assets/img/user/user14.jpg" class="rounded-circle" width="40" alt="user">
                            <img src="assets/img/user/user13.jpg" class="rounded-circle" width="40" alt="user">
                            <div class="d-inline-block rounded-circle fw-semibold text-center">+4</div>
                        </div>
                    </div>
                </div>
                <div class="info d-flex align-items-center justify-content-space-between">
                    <div>
                        <span class="d-block gray-color fs-14 mb-5">245</span>
                        <span class="d-block gray-color fs-16 fw-semibold">Design Project</span>
                    </div>
                    <div>
                        <span class="d-block gray-color fs-14 mb-5">1085/765</span>
                        <span class="d-block gray-color fs-16 fw-semibold">Task Reports</span>
                    </div>
                    <div>
                        <a mat-raised-button class="gray" routerLink="/">View Details <i class="flaticon-chevron"></i></a>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6">
        <mat-card class="mb-25 p-team-card tagus-card">
            <mat-card-header>
                <div>
                    <h5 class="mb-8">Creative Team</h5>
                    <p>Contrary to popular belief, lorem Ipsum is not simply</p>
                </div>
                <div>
                    <button type="button" mat-button class="position-relative dot-btn p-0 border-none" [matMenuTriggerFor]="monthMenu">
                        <i class="ri-more-fill"></i>
                    </button>
                    <mat-menu #monthMenu="matMenu" class="monthMenu" xPosition="before">
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Open Project
                        </button>
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Edit
                        </button>
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Delete
                        </button>
                    </mat-menu>
                </div>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-space-between">
                    <div class="d-flex align-items-center">
                        <img src="assets/img/user/user11.jpg" class="rounded-circle" width="60" alt="user">
                        <div class="title ms-15">
                            <h5 class="mb-5">Evangelina Mcclain</h5>
                            <span class="d-block main-color">Team Leader</span>
                        </div>
                    </div>
                    <div class="list">
                        <div class="images d-flex align-items-center">
                            <img src="assets/img/user/user12.jpg" class="rounded-circle" width="40" alt="user">
                            <img src="assets/img/user/user11.jpg" class="rounded-circle" width="40" alt="user">
                            <img src="assets/img/user/user10.jpg" class="rounded-circle" width="40" alt="user">
                            <div class="d-inline-block rounded-circle fw-semibold text-center">+4</div>
                        </div>
                    </div>
                </div>
                <div class="info d-flex align-items-center justify-content-space-between">
                    <div>
                        <span class="d-block gray-color fs-14 mb-5">245</span>
                        <span class="d-block gray-color fs-16 fw-semibold">Design Project</span>
                    </div>
                    <div>
                        <span class="d-block gray-color fs-14 mb-5">1085/765</span>
                        <span class="d-block gray-color fs-16 fw-semibold">Task Reports</span>
                    </div>
                    <div>
                        <a mat-raised-button class="gray" routerLink="/">View Details <i class="flaticon-chevron"></i></a>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6">
        <mat-card class="mb-25 p-team-card tagus-card">
            <mat-card-header>
                <div>
                    <h5 class="mb-8">Managing Team</h5>
                    <p>Contrary to popular belief, lorem Ipsum is not simply</p>
                </div>
                <div>
                    <button type="button" mat-button class="position-relative dot-btn p-0 border-none" [matMenuTriggerFor]="monthMenu">
                        <i class="ri-more-fill"></i>
                    </button>
                    <mat-menu #monthMenu="matMenu" class="monthMenu" xPosition="before">
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Open Project
                        </button>
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Edit
                        </button>
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Delete
                        </button>
                    </mat-menu>
                </div>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-space-between">
                    <div class="d-flex align-items-center">
                        <img src="assets/img/user/user15.jpg" class="rounded-circle" width="60" alt="user">
                        <div class="title ms-15">
                            <h5 class="mb-5">Candice Munoz</h5>
                            <span class="d-block main-color">Team Leader</span>
                        </div>
                    </div>
                    <div class="list">
                        <div class="images d-flex align-items-center">
                            <img src="assets/img/user/user9.jpg" class="rounded-circle" width="40" alt="user">
                            <img src="assets/img/user/user8.jpg" class="rounded-circle" width="40" alt="user">
                            <img src="assets/img/user/user7.jpg" class="rounded-circle" width="40" alt="user">
                            <div class="d-inline-block rounded-circle fw-semibold text-center">+4</div>
                        </div>
                    </div>
                </div>
                <div class="info d-flex align-items-center justify-content-space-between">
                    <div>
                        <span class="d-block gray-color fs-14 mb-5">245</span>
                        <span class="d-block gray-color fs-16 fw-semibold">Design Project</span>
                    </div>
                    <div>
                        <span class="d-block gray-color fs-14 mb-5">1085/765</span>
                        <span class="d-block gray-color fs-16 fw-semibold">Task Reports</span>
                    </div>
                    <div>
                        <a mat-raised-button class="gray" routerLink="/">View Details <i class="flaticon-chevron"></i></a>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6">
        <mat-card class="mb-25 p-team-card tagus-card">
            <mat-card-header>
                <div>
                    <h5 class="mb-8">Creative Directory</h5>
                    <p>Contrary to popular belief, lorem Ipsum is not simply</p>
                </div>
                <div>
                    <button type="button" mat-button class="position-relative dot-btn p-0 border-none" [matMenuTriggerFor]="monthMenu">
                        <i class="ri-more-fill"></i>
                    </button>
                    <mat-menu #monthMenu="matMenu" class="monthMenu" xPosition="before">
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Open Project
                        </button>
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Edit
                        </button>
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Delete
                        </button>
                    </mat-menu>
                </div>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-space-between">
                    <div class="d-flex align-items-center">
                        <img src="assets/img/user/user24.jpg" class="rounded-circle" width="60" alt="user">
                        <div class="title ms-15">
                            <h5 class="mb-5">Bernard Langley</h5>
                            <span class="d-block main-color">Team Leader</span>
                        </div>
                    </div>
                    <div class="list">
                        <div class="images d-flex align-items-center">
                            <img src="assets/img/user/user6.jpg" class="rounded-circle" width="40" alt="user">
                            <img src="assets/img/user/user5.jpg" class="rounded-circle" width="40" alt="user">
                            <img src="assets/img/user/user4.jpg" class="rounded-circle" width="40" alt="user">
                            <div class="d-inline-block rounded-circle fw-semibold text-center">+4</div>
                        </div>
                    </div>
                </div>
                <div class="info d-flex align-items-center justify-content-space-between">
                    <div>
                        <span class="d-block gray-color fs-14 mb-5">245</span>
                        <span class="d-block gray-color fs-16 fw-semibold">Design Project</span>
                    </div>
                    <div>
                        <span class="d-block gray-color fs-14 mb-5">1085/765</span>
                        <span class="d-block gray-color fs-16 fw-semibold">Task Reports</span>
                    </div>
                    <div>
                        <a mat-raised-button class="gray" routerLink="/">View Details <i class="flaticon-chevron"></i></a>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6">
        <mat-card class="mb-25 p-team-card tagus-card">
            <mat-card-header>
                <div>
                    <h5 class="mb-8">Angular Team</h5>
                    <p>Contrary to popular belief, lorem Ipsum is not simply</p>
                </div>
                <div>
                    <button type="button" mat-button class="position-relative dot-btn p-0 border-none" [matMenuTriggerFor]="monthMenu">
                        <i class="ri-more-fill"></i>
                    </button>
                    <mat-menu #monthMenu="matMenu" class="monthMenu" xPosition="before">
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Open Project
                        </button>
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Edit
                        </button>
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            Delete
                        </button>
                    </mat-menu>
                </div>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-space-between">
                    <div class="d-flex align-items-center">
                        <img src="assets/img/user/user23.jpg" class="rounded-circle" width="60" alt="user">
                        <div class="title ms-15">
                            <h5 class="mb-5">Kristie Hall</h5>
                            <span class="d-block main-color">Team Leader</span>
                        </div>
                    </div>
                    <div class="list">
                        <div class="images d-flex align-items-center">
                            <img src="assets/img/user/user3.jpg" class="rounded-circle" width="40" alt="user">
                            <img src="assets/img/user/user2.jpg" class="rounded-circle" width="40" alt="user">
                            <img src="assets/img/user/user1.jpg" class="rounded-circle" width="40" alt="user">
                            <div class="d-inline-block rounded-circle fw-semibold text-center">+4</div>
                        </div>
                    </div>
                </div>
                <div class="info d-flex align-items-center justify-content-space-between">
                    <div>
                        <span class="d-block gray-color fs-14 mb-5">245</span>
                        <span class="d-block gray-color fs-16 fw-semibold">Design Project</span>
                    </div>
                    <div>
                        <span class="d-block gray-color fs-14 mb-5">1085/765</span>
                        <span class="d-block gray-color fs-16 fw-semibold">Task Reports</span>
                    </div>
                    <div>
                        <a mat-raised-button class="gray" routerLink="/">View Details <i class="flaticon-chevron"></i></a>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>