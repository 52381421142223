<div class="example-container mat-elevation-z8">
    <div class="example-loading-shade"
        *ngIf="isLoadingResults || isRateLimitReached">
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
        <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
            GitHub's API rate limit has been reached. It will be reset in one minute.
        </div>
    </div>

    <div class="example-table-container">
        <table mat-table [dataSource]="data" class="example-table"
            matSort matSortActive="created" matSortDisableClear matSortDirection="desc">
        <!-- Number Column -->
        <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let row">{{row.number}}</td>
        </ng-container>

        <!-- Title Column -->
        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>Title</th>
            <td mat-cell *matCellDef="let row">{{row.title}}</td>
        </ng-container>

        <!-- State Column -->
        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef>State</th>
            <td mat-cell *matCellDef="let row">{{row.state}}</td>
        </ng-container>

        <!-- Created Column -->
        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Created
            </th>
            <td mat-cell *matCellDef="let row">{{row.created_at | date}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <mat-paginator [length]="resultsLength" [pageSize]="30" aria-label="Select page of GitHub search results"></mat-paginator>
</div>