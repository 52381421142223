<div class="mat-elevation-z8" [class.component-dark-theme]="themeService.isDark()">
    <table mat-table [dataSource]="dataSource">
        <!-- Position Column -->
        <ng-container matColumnDef="demo-position">
            <th mat-header-cell *matHeaderCellDef>No.</th>
            <td mat-cell *matCellDef="let element">{{element.position}}</td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="demo-name">
            <th mat-header-cell *matHeaderCellDef class="fs-16">Name</th>
            <td mat-cell *matCellDef="let element" class="fs-16">{{element.name}}</td>
        </ng-container>
    
        <!-- Weight Column -->
        <ng-container matColumnDef="demo-weight">
            <th mat-header-cell *matHeaderCellDef>Weight</th>
            <td mat-cell *matCellDef="let element"><i>{{element.weight}}</i></td>
        </ng-container>
    
        <!-- Symbol Column -->
        <ng-container matColumnDef="demo-symbol">
            <th mat-header-cell *matHeaderCellDef>Symbol </th>
            <td mat-cell *matCellDef="let element"><strong>{{element.symbol}}</strong></td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>