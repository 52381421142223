import { Component } from '@angular/core';

@Component({
  selector: 'app-saas-app',
  templateUrl: './saas-app.component.html',
  styleUrls: ['./saas-app.component.scss']
})
export class SaasAppComponent {

}
