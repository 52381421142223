<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Категорії</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Дашборд
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Каталог</li>
        <li class="breadcrumb-item fw-semibold">Категорії</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header>
        <h5 class="mb-0">Список категорій</h5>
        <div class="d-flex align-items-center">
            <a mat-flat-button class="default-btn tagus small" routerLink="/main/catalog/categories/create">
                <i class="ri-add-fill"></i> Додати
            </a>
        </div>
    </mat-card-header>
    <app-local-success></app-local-success>
    <app-modal-confirmation *ngIf="showConfirmation" (confirmed)="onConfirmationRemove($event)"></app-modal-confirmation>
    <mat-card-content>
        <div class="mat-elevation-z8 tagus-mat-table">
            <table mat-table [dataSource]="dataSource">

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Назва</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        <div class="d-flex align-items-center">
                            <a [routerLink]="['/main/catalog/categories/edit/', element._id]" class="d-flex align-items-center product-info">
                                {{element.name_ua}}
                            </a>
                        </div>
                    </td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="action">
                    <th style="display: flex;justify-content: flex-end" mat-header-cell *matHeaderCellDef>Дія</th>
                    <td style="display: flex;justify-content: flex-end" mat-cell *matCellDef="let element">
                        <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                            <i class="ri-more-fill"></i>
                        </button>
                        <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                            <a [routerLink]="['/main/catalog/categories/edit/', element._id]" mat-menu-item class="bg-transparent gray-color border-none position-relative">
                                <i class="ri-edit-line"></i> Редагувати
                            </a>
                            <button (click)="remove(element._id)" mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-delete-bin-line"></i> Видалити
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator
                [pageSizeOptions]="[10, 15, 20]"
                showFirstLastButtons
            >
            </mat-paginator>
        </div>
    </mat-card-content>
</mat-card>
