<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Button Toggle</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">UI Kit</li>
        <li class="breadcrumb-item fw-semibold">Button Toggle</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Button Toggle</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
                    <mat-button-toggle value="bold">Bold</mat-button-toggle>
                    <mat-button-toggle value="italic">Italic</mat-button-toggle>
                    <mat-button-toggle value="underline">Underline</mat-button-toggle>
                </mat-button-toggle-group>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Button Toggle Appearance</h5>
            </mat-card-header>
            <mat-card-content>
                <p>
                    Default appearance:
                    <mat-button-toggle-group class="ms-5" name="fontStyle" aria-label="Font Style">
                        <mat-button-toggle value="bold">Bold</mat-button-toggle>
                        <mat-button-toggle value="italic">Italic</mat-button-toggle>
                        <mat-button-toggle value="underline">Underline</mat-button-toggle>
                    </mat-button-toggle-group>
                </p>
                <p>
                    Legacy appearance:
                    <mat-button-toggle-group class="ms-5" appearance="legacy" name="fontStyle" aria-label="Font Style">
                        <mat-button-toggle value="bold">Bold</mat-button-toggle>
                        <mat-button-toggle value="italic">Italic</mat-button-toggle>
                        <mat-button-toggle value="underline">Underline</mat-button-toggle>
                    </mat-button-toggle-group>
                </p>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Exclusive Selection</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-button-toggle-group #group="matButtonToggleGroup">
                    <mat-button-toggle value="left" aria-label="Text align left">
                        <mat-icon>format_align_left</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle value="center" aria-label="Text align center">
                        <mat-icon>format_align_center</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle value="right" aria-label="Text align right">
                        <mat-icon>format_align_right</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle value="justify" disabled aria-label="Text align justify">
                        <mat-icon>format_align_justify</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <div class="mt-10">Selected value: {{group.value}}</div>
            </mat-card-content>
        </mat-card>
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Button Toggle Selection Mode</h5>
            </mat-card-header>
            <mat-card-content>
                <h5>Single selection</h5>
                <mat-button-toggle-group name="favoriteColor" aria-label="Favorite Color">
                    <mat-button-toggle value="red">Red</mat-button-toggle>
                    <mat-button-toggle value="green">Green</mat-button-toggle>
                    <mat-button-toggle value="blue">Blue</mat-button-toggle>
                </mat-button-toggle-group>
                <h5 class="mt-25">Multiple selection</h5>
                <mat-button-toggle-group name="ingredients" aria-label="Ingredients" multiple>
                    <mat-button-toggle value="flour">Flour</mat-button-toggle>
                    <mat-button-toggle value="eggs">Eggs</mat-button-toggle>
                    <mat-button-toggle value="sugar">Sugar</mat-button-toggle>
                </mat-button-toggle-group>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Button Toggle with Forms</h5>
            </mat-card-header>
            <mat-card-content>
                <div>
                    <h5>Button Toggle inside of a Template-driven form</h5>
                    <mat-button-toggle-group [(ngModel)]="fontStyle" aria-label="Font Style">
                        <mat-button-toggle value="bold">Bold</mat-button-toggle>
                        <mat-button-toggle value="italic">Italic</mat-button-toggle>
                        <mat-button-toggle value="underline">Underline</mat-button-toggle>
                    </mat-button-toggle-group>
                    <span class="fw-semibold d-block mt-10">Chosen value is: {{fontStyle}}</span>
                </div>
                <div class="mt-25">
                    <h5>Button Toggle inside of a Reactive form</h5>
                    <mat-button-toggle-group [formControl]="fontStyleControl" aria-label="Font Style">
                        <mat-button-toggle value="bold">Bold</mat-button-toggle>
                        <mat-button-toggle value="italic">Italic</mat-button-toggle>
                        <mat-button-toggle value="underline">Underline</mat-button-toggle>
                    </mat-button-toggle-group>
                    <span class="fw-semibold d-block mt-10">Chosen value is: {{fontStyleControl.value}}</span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>