<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Checkbox</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">UI Kit</li>
        <li class="breadcrumb-item fw-semibold">Checkbox</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-3 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Checkbox</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-checkbox class="example-margin"></mat-checkbox>
                <mat-checkbox class="example-margin" [disabled]="true"></mat-checkbox>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Checkbox with Text</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-checkbox class="example-margin">Check me!</mat-checkbox>
                <mat-checkbox class="example-margin" [disabled]="true">Check me!</mat-checkbox>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-5 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Color Checkbox</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-checkbox class="example-margin"></mat-checkbox>
                <mat-checkbox class="example-margin primary"></mat-checkbox>
                <mat-checkbox class="example-margin secondary"></mat-checkbox>
                <mat-checkbox class="example-margin success"></mat-checkbox>
                <mat-checkbox class="example-margin info"></mat-checkbox>
                <mat-checkbox class="example-margin warning"></mat-checkbox>
                <mat-checkbox class="example-margin danger"></mat-checkbox>
                <mat-checkbox class="example-margin light"></mat-checkbox>
                <mat-checkbox class="example-margin dark"></mat-checkbox>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Indeterminate Checkbox</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-checkbox class="example-margin" [checked]="allComplete" [color]="task.color" [indeterminate]="someComplete()" (change)="setAll($event.checked)">
                    {{task.name}}
                </mat-checkbox>
                <ul class="mb-0 mt-5">
                    <li *ngFor="let subtask of task.subtasks">
                        <mat-checkbox [(ngModel)]="subtask.completed" [color]="subtask.color" (ngModelChange)="updateAllComplete()">
                            {{subtask.name}}
                        </mat-checkbox>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Checkboxes with Reactive Forms</h5>
            </mat-card-header>
            <mat-card-content>
                <div [formGroup]="toppings">
                    <h5 class="mb-15">Select your toppings:</h5>
                    <p><mat-checkbox formControlName="pepperoni">Pepperoni</mat-checkbox></p>
                    <p><mat-checkbox formControlName="extracheese">Extra Cheese</mat-checkbox></p>
                    <p><mat-checkbox formControlName="mushroom">Mushroom</mat-checkbox></p>
                </div>
                <div class="mt-15" [formGroup]="toppings">
                    <h5 class="mb-15">You chose:</h5>
                    {{toppings.value | json}}
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>