<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Images</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">UI Kit</li>
        <li class="breadcrumb-item fw-semibold">Images</li>
    </ol>
</div>

<div class="row">
    <div class="col-xl-6 col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Avatar Size Rounded-Circle</h5>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center">
                    <img src="assets/img/user/user11.jpg" class="rounded-circle me-20" width="85" alt="user">
                    <img src="assets/img/user/user11.jpg" class="rounded-circle me-20" width="75" alt="user">
                    <img src="assets/img/user/user11.jpg" class="rounded-circle me-20" width="65" alt="user">
                    <img src="assets/img/user/user11.jpg" class="rounded-circle me-20" width="55" alt="user">
                    <img src="assets/img/user/user11.jpg" class="rounded-circle me-20" width="45" alt="user">
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Avatar Size Square</h5>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center">
                    <img src="assets/img/user/user12.jpg" class="border-radius me-20" width="85" alt="user">
                    <img src="assets/img/user/user12.jpg" class="border-radius me-20" width="75" alt="user">
                    <img src="assets/img/user/user12.jpg" class="border-radius me-20" width="65" alt="user">
                    <img src="assets/img/user/user12.jpg" class="border-radius me-20" width="55" alt="user">
                    <img src="assets/img/user/user12.jpg" class="border-radius me-20" width="45" alt="user">
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Avatar Size Square/Circle</h5>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center">
                    <img src="assets/img/user/user4.jpg" class="me-20" width="200" alt="user">
                    <img src="assets/img/user/user4.jpg" class="rounded-circle me-20" width="200" alt="user">
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Responsive Images</h5>
            </mat-card-header>
            <mat-card-content>
                <img src="assets/img/recent-orders/product-details1.jpg" alt="card">
            </mat-card-content>
        </mat-card>
    </div>
</div>