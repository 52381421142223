<div class="example-button-container">
    <button mat-raised-button (click)="formControl.disable()">Disable</button>
    <button mat-raised-button (click)="formControl.enable()">Enable</button>
</div>
<p class="mt-15">
    <i>Enter video keywords</i>
</p>
<mat-form-field appearance="fill" class="example-form-field">
    <mat-label>Video keywords</mat-label>
    <mat-chip-grid #chipGrid aria-label="Enter keywords" [formControl]="formControl" >
        <mat-chip-row *ngFor="let keyword of keywords" (removed)="removeKeyword(keyword)">
            {{keyword}}
            <button matChipRemove aria-label="'remove ' + keyword">
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip-row>
    </mat-chip-grid>
    <input placeholder="New keyword..."
        [matChipInputFor]="chipGrid"
        (matChipInputTokenEnd)="add($event)"
    />
</mat-form-field>
<p>The following keywords are entered: {{formControl.value}}</p>