<app-hd-stats></app-hd-stats>

<div class="row">
    <div class="col-xxl-8">

        <app-tickets-status></app-tickets-status>

        <div class="row">
            <div class="col-xxl-6">

                <app-average-time-call></app-average-time-call>

            </div>
            <div class="col-xxl-6">

                <app-support-status></app-support-status>

            </div>
        </div>
    </div>
    <div class="col-xxl-4">

        <app-customer-satisfaction></app-customer-satisfaction>

        <app-hd-activity></app-hd-activity>

    </div>
</div>

<app-agent-performance></app-agent-performance>