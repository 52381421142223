<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Inbox</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Email</li>
        <li class="breadcrumb-item fw-semibold">Inbox</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-4 col-xxl-3">

        <app-email-sidebar></app-email-sidebar>

    </div>
    <div class="col-lg-8 col-xxl-9">

        <mat-card
            class="mb-25 tagus-card"
            [class.component-dark-theme]="themeService.isDark()"
            [class.component-rtl-enabled]="themeService.isRTLEnabled()"
        >
            <mat-card-header class="mb-5">
                <h5 class="mb-0">Emails List</h5>
                <div class="info-list">
                    <button mat-icon-button>
                        <i class="ri-printer-line"></i>
                    </button>
                    <button mat-icon-button>
                        <i class="ri-information-line"></i>
                    </button>
                    <button mat-icon-button>
                        <i class="ri-delete-bin-6-line"></i>
                    </button>
                    <button mat-icon-button>
                        <i class="ri-folders-line"></i>
                    </button>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <i class="ri-more-2-fill"></i>
                    </button>
                    <mat-menu #menu="matMenu" class="dotMenu monthMenu" xPosition="before">
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            <i class="ri-add-circle-line"></i> Add
                        </button>
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            <i class="ri-delete-bin-6-line"></i> Delete
                        </button>
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                            <i class="ri-restart-line"></i> Refresh
                        </button>
                    </mat-menu>
                </div>
            </mat-card-header>
            <mat-card-content>
                <div class="myTasksTable mat-elevation-z8 tagus-mat-table">
                    <table mat-table [dataSource]="dataSource">
        
                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="checkbox">
                            <th mat-header-cell *matHeaderCellDef class="d-none">Checkbox</th>
                            <td mat-cell *matCellDef="let element">
                                <mat-checkbox></mat-checkbox>
                            </td>
                        </ng-container>
        
                        <!-- Star Column -->
                        <ng-container matColumnDef="star">
                            <th mat-header-cell *matHeaderCellDef class="d-none text-start">Star</th>
                            <td mat-cell *matCellDef="let element" class="text-start"><i class="{{element.star}}"></i></td>
                        </ng-container>
        
                        <!-- Title Column -->
                        <ng-container matColumnDef="title">
                            <th mat-header-cell *matHeaderCellDef class="d-none text-start">Title</th>
                            <td mat-cell *matCellDef="let element" class="fw-semibold text-start">{{element.title}}</td>
                        </ng-container>
        
                        <!-- Desc Column -->
                        <ng-container matColumnDef="desc">
                            <th mat-header-cell *matHeaderCellDef class="d-none text-start">Desc</th>
                            <td mat-cell *matCellDef="let element" class="text-start">
                                <a routerLink="/email/read" class="gray-color main-hover-color">{{element.desc}}</a>
                            </td>
                        </ng-container>
        
                        <!-- Date Column -->
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef class="text-end d-none">Date</th>
                            <td mat-cell *matCellDef="let element" class="text-end">{{element.date}}</td>
                        </ng-container>
        
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        
                    </table>
                    <mat-paginator
                        [pageSizeOptions]="[10, 20, 30, 40]"
                        showFirstLastButtons
                    >
                    </mat-paginator>
                </div>
            </mat-card-content>
        </mat-card>

    </div>
</div>