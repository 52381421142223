import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent {

    constructor(private _snackBar: MatSnackBar) {}

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action);
    }

}