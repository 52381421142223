<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Contacts</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Contacts</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card" [class.component-dark-theme]="themeService.isDark()">
    <mat-card-content>
        <div class="tagus-form-group">
            <mat-form-field appearance="fill">
                <i class="ri-search-line"></i>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" #input>
            </mat-form-field>
        </div>
        <div class="mat-elevation-z8 tagus-mat-table">
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox
                            (change)="$event ? toggleAllRows() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            [aria-label]="checkboxLabel()"
                        >
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox
                            (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row)"
                        >
                        </mat-checkbox>
                    </td>
                </ng-container>

                <!-- Image Column -->
                <ng-container matColumnDef="image">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Image</th>
                    <td mat-cell *matCellDef="let element">
                        <img [src]="element.image" alt="user" class="border-radius" width="40">
                    </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                    <td mat-cell *matCellDef="let element">{{element.name}}</td>
                </ng-container>

                <!-- Phone Number Column -->
                <ng-container matColumnDef="phoneNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone Number</th>
                    <td mat-cell *matCellDef="let element">{{element.phoneNumber}}</td>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                    <td mat-cell *matCellDef="let element">{{element.email}}</td>
                </ng-container>

                <!-- Date of Birth Column -->
                <ng-container matColumnDef="dateOfBirth">
                    <th mat-header-cell *matHeaderCellDef>Date of Birth</th>
                    <td mat-cell *matCellDef="let element">{{element.dateOfBirth}}</td>
                </ng-container>

                <!-- Address Column -->
                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
                    <td mat-cell *matCellDef="let element">{{element.address}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>

            </table>
            <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>