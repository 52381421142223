import { Component } from '@angular/core';

@Component({
  selector: 'app-lms-courses',
  templateUrl: './lms-courses.component.html',
  styleUrls: ['./lms-courses.component.scss']
})
export class LmsCoursesComponent {

}
