<div class="mat-elevation-z8" [class.component-dark-theme]="themeService.isDark()">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
        <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
            <th mat-header-cell *matHeaderCellDef>{{column}}</th>
            <td mat-cell *matCellDef="let element">{{element[column]}}</td>
        </ng-container>
        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                    <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" class="pt-0 pb-0" [attr.colspan]="columnsToDisplayWithExpand.length">
                <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="example-element-diagram">
                        <div class="example-element-position">{{element.position}}</div>
                        <div class="example-element-symbol">{{element.symbol}}</div>
                        <div class="example-element-name">{{element.name}}</div>
                        <div class="example-element-weight">{{element.weight}}</div>
                    </div>
                    <div class="example-element-description">
                        <p>{{element.description}}</p>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row" [class.example-expanded-row]="expandedElement === element" (click)="expandedElement = expandedElement === element ? null : element"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
</div>