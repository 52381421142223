import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    /* ** ProductPage ** */
    setProductPage(page: any) {
        this.removeProductPage();
        localStorage.setItem('product_page', page);
    }

    getProductPage(): any {
        const cachedData = localStorage.getItem('product_page');
        return cachedData || null;
    }

    removeProductPage(): void {
        localStorage.removeItem('product_page');
    }
}
