<mat-card
    class="mb-25 tagus-card top-students-box"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Top Students</h5>
        <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="monthMenu">
            <i class="ri-more-fill"></i>
        </button>
        <mat-menu #monthMenu="matMenu" class="monthMenu" xPosition="before">
            <button mat-menu-item type="button" class="bg-transparent border-none">Today</button>
            <button mat-menu-item type="button" class="bg-transparent border-none">Last Month</button>
            <button mat-menu-item type="button" class="bg-transparent border-none">Last Year</button>
            <button mat-menu-item type="button" class="bg-transparent border-none">All Time</button>
        </mat-menu>
    </mat-card-header>
    <mat-card-content>
        <div class="students-list d-flex flex-wrap">
            <div class="list-item">
                <div class="box border-radius bg-gray d-flex align-items-center">
                    <img src="assets/img/user/user15.jpg" class="rounded-circle" alt="user">
                    <div class="info">
                        <h5 class="fw-semibold">Andrew Burns</h5>
                        <span class="d-block muted-color">programming</span>
                    </div>
                </div>
            </div>
            <div class="list-item">
                <div class="box border-radius bg-gray d-flex align-items-center">
                    <img src="assets/img/user/user16.jpg" class="rounded-circle" alt="user">
                    <div class="info">
                        <h5 class="fw-semibold">Richard Payne</h5>
                        <span class="d-block muted-color">physics</span>
                    </div>
                </div>
            </div>
            <div class="list-item">
                <div class="box border-radius bg-gray d-flex align-items-center">
                    <img src="assets/img/user/user17.jpg" class="rounded-circle" alt="user">
                    <div class="info">
                        <h5 class="fw-semibold">Tammy Sanch</h5>
                        <span class="d-block muted-color">programming</span>
                    </div>
                </div>
            </div>
            <div class="list-item">
                <div class="box border-radius bg-gray d-flex align-items-center">
                    <img src="assets/img/user/user11.jpg" class="rounded-circle" alt="user">
                    <div class="info">
                        <h5 class="fw-semibold">Jennifer Sum</h5>
                        <span class="d-block muted-color">physics</span>
                    </div>
                </div>
            </div>
            <div class="list-item">
                <div class="box border-radius bg-gray d-flex align-items-center">
                    <img src="assets/img/user/user18.jpg" class="rounded-circle" alt="user">
                    <div class="info">
                        <h5 class="fw-semibold">Dana Carey</h5>
                        <span class="d-block muted-color">physics</span>
                    </div>
                </div>
            </div>
            <div class="list-item">
                <div class="box border-radius bg-gray d-flex align-items-center">
                    <img src="assets/img/user/user12.jpg" class="rounded-circle" alt="user">
                    <div class="info">
                        <h5 class="fw-semibold">Jordan Enson</h5>
                        <span class="d-block muted-color">programming</span>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>