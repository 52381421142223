import {Component, OnInit} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../../services/api.service";
import {UtilityService} from "../../../services/utility.service";
import {ErrorService} from "../../common/notifications/errors/error.service";
import {SuccessService} from "../../common/notifications/success/success.service";
import {EndpointTypeEnum} from "../../../enums/endpoint-type.enum";
import {ErrorTypeEnum} from "../../../enums/error-type.enum";
import {DEFAULT_MESSAGES} from "../../../constants/default-messages";

@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrl: './horizontal-menu.component.scss'
})
export class HorizontalMenuComponent implements OnInit {
    backendUrl: string = environment.backendUrl;
    categoriesHome: any;
    form!: FormGroup;
    formWasSubmitted = false;
    categoriesForDropdown: any;
    addedCategories: any[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private apiService: ApiService,
        private formBuilder: FormBuilder,
        private utilityService: UtilityService,
        private errorService: ErrorService,
        private successService: SuccessService,
    ) {
        this.initForm();
        this.getCategoriesForDropdown();
    }

    // инициализация формы
    initForm(): void {
        // инициализируем поля из формы и валидируем
        this.form = this.formBuilder.group({
            added_categories: [''],
        });
    }

    ngOnInit() {
        this.getCategories();
    }

    addCategory() {
        this.addedCategories.push({
            category: '',
            header_ru: '',
            header_ua: '',
            sort: '',
        });
    }

    removeCategory(index: number) {
        this.addedCategories.splice(index, 1);
    }

    onUpdateCategory(index: number, field: string, event: any): void {
        switch (field) {
            case 'category':
                // записываем значение поля
                this.addedCategories[index].category = event.value;
                break;
            case 'header_ru':
                // записываем значение поля
                this.addedCategories[index].header_ru = event.target.value;
                break;
            case 'header_ua':
                // записываем значение поля
                this.addedCategories[index].header_ua = event.target.value;
                break;
            case 'sort':
                // записываем значение поля
                this.addedCategories[index].sort = event.target.value;
                break;
            default:
                break;
        }
    }

    // получение данных для выпадающего списка
    getCategoriesForDropdown(): void {
        this.apiService
            .get(EndpointTypeEnum.DropdownLists, '/categories', true)
            .subscribe({
                next: (response: any) => {
                    this.categoriesForDropdown = response.data;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    // получение данных для формы
    getCategories() {
        this.apiService
            .get(EndpointTypeEnum.Main, '/horizontal-menu', true)
            .subscribe({
                next: (response: any) => {
                    this.categoriesHome = response.data;
                    for (const categoryHome of this.categoriesHome) {
                        this.addedCategories.push({
                            category: categoryHome.category,
                            header_ru: categoryHome.header_ru,
                            header_ua: categoryHome.header_ua,
                            sort: categoryHome.sort,
                        });
                    }
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    // отправка формы
    onFormSubmit(form: FormGroup): void {
        this.formWasSubmitted = true;
        // если есть ошибки валидации в полях формы, не даем отправить форму
        if (form.invalid) {
            this.errorService.addError(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.FORM_ERROR]);
            return;
        }

        // получаем значения из формы
        const formValue = form.value;
        formValue.added_categories = this.addedCategories;

        // отправка формы
        this.apiService
            .put(EndpointTypeEnum.Main, '/horizontal-menu', true, form.value)
            .subscribe({
                next: (response: any) => {
                    this.successService.add(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.SUCCESS]);
                    this.addedCategories = [];
                    // form.controls['opengraph_image'].reset();
                    this.getCategories();
                },
                error: (err: any) => {
                    this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                },
            });
    }
}
