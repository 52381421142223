import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../../../services/api.service";
import {ErrorService} from "../../../common/notifications/errors/error.service";
import {SuccessService} from "../../../common/notifications/success/success.service";
import {ErrorTypeEnum} from "../../../../enums/error-type.enum";
import {EndpointTypeEnum} from "../../../../enums/endpoint-type.enum";
import {DEFAULT_MESSAGES} from "../../../../constants/default-messages";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-payment-method-edit',
  templateUrl: './payment-method-edit.component.html',
  styleUrl: './payment-method-edit.component.scss'
})
export class PaymentMethodEditComponent implements OnInit, OnDestroy {
    backendUrl: string = environment.backendUrl;
    paymentMethod: any;
    paymentMethodId: any = this.route.snapshot.paramMap.get('id');
    form!: FormGroup;
    formWasSubmitted = false;
    image: any;

    constructor(
        private router: Router,
        private apiService: ApiService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private errorService: ErrorService,
        private successService: SuccessService,
    ) {
        this.initForm();
    }

    // инициализация формы
    initForm(): void {
        // инициализируем поля из формы и валидируем
        this.form = this.formBuilder.group({
            name_ua: ['', [Validators.required]],
            name_ru: ['', [Validators.required]],
            is_paid: ['1', Validators.required],
            description_ua: ['', [Validators.required]],
            description_ru: ['', [Validators.required]],
            is_active: ['1', Validators.required],
        });
    }

    ngOnInit() {
        if (this.paymentMethodId !== null) {
            this.getPaymentMethod(this.paymentMethodId);
        }
    }

    ngOnDestroy() {
        this.successService.clear(ErrorTypeEnum.Global);
        this.successService.clear(ErrorTypeEnum.Local);
        this.errorService.clearErrors(ErrorTypeEnum.Global);
        this.errorService.clearErrors(ErrorTypeEnum.Local);
    }

    // получение данных для формы
    getPaymentMethod(paymentMethodId: string) {
        this.apiService
            .get(EndpointTypeEnum.Main, '/payment-methods', true, paymentMethodId)
            .subscribe({
                next: (response: any) => {
                    this.paymentMethod = response.data;
                    // установка значения полей
                    this.image = this.paymentMethod.image;
                    this.form.patchValue({
                        name_ru: this.paymentMethod.name_ru,
                        name_ua: this.paymentMethod.name_ua,
                        is_paid: this.paymentMethod.is_paid ? this.paymentMethod.is_paid = 1 : this.paymentMethod.is_paid = 0,
                        description_ua: this.paymentMethod.description_ua,
                        description_ru: this.paymentMethod.description_ru,
                        is_active: this.paymentMethod.is_active ? this.paymentMethod.is_active = 1 : this.paymentMethod.is_active = 0,
                    });
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    // отправка формы
    onFormSubmit(form: FormGroup): void {
        this.formWasSubmitted = true;

        // если есть ошибки валидации, не даем отправить форму
        if (form.invalid) {
            return;
        }

        // получаем значения из формы
        const formValue = form.value;
        formValue.image = this.image;

        // отправка формы
        this.apiService
            .put(EndpointTypeEnum.Main, '/payment-methods', true, form.value, this.paymentMethodId)
            .subscribe({
                next: (response: any) => {
                    this.successService.add(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.SUCCESS]);
                },
                error: (err: any) => {
                    this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                },
            });
    }

    uploadImage(event: any) {
        if (event.target.files.length !== 0) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('image', file);
            this.apiService
                .postMultipartFormData(EndpointTypeEnum.Requests, '/upload-files', true, formData)
                .subscribe({
                    next: (response: any) => {
                        this.image = response.data;
                        this.errorService.clearErrors(ErrorTypeEnum.Local);
                    },
                    error: (err: any) => {
                        this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                    },
                });
        } else {
            this.image = '';
        }
    }
}
