<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Progress Bar</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">UI Kit</li>
        <li class="breadcrumb-item fw-semibold">Progress Bar</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Progress Bar</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-progress-bar mode="determinate" value="40"></mat-progress-bar>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Indeterminate Progress Bar</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Query Progress Bar</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-progress-bar mode="query"></mat-progress-bar>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Progress Spinner</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-spinner></mat-spinner>
            </mat-card-content>
        </mat-card>
    </div>
</div>