<ng-container [ngSwitch]="stepperOrientation | async">
    <div *ngSwitchCase="'horizontal'">Make your screen smaller to see a vertical stepper</div>
    <div *ngSwitchCase="'vertical'">Make your screen larger to see a horizontal stepper</div>
</ng-container>

<mat-stepper class="example-stepper" [orientation]="(stepperOrientation | async)!">
    <mat-step [stepControl]="firstFormGroup" label="Fill out your name">
        <form [formGroup]="firstFormGroup">
            <mat-form-field appearance="fill">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Last name, First name" formControlName="firstCtrl" required>
            </mat-form-field>
            <div>
                <button mat-flat-button class="tagus" matStepperNext>Next</button>
            </div>
        </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup" label="Fill out your address">
        <form [formGroup]="secondFormGroup">
            <mat-form-field appearance="fill">
                <mat-label>Address</mat-label>
                <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY" required>
            </mat-form-field>
            <div>
                <button mat-flat-button class="tagus me-15" matStepperPrevious>Back</button>
                <button mat-flat-button class="tagus" matStepperNext>Next</button>
            </div>
        </form>
    </mat-step>
    <mat-step [stepControl]="thirdFormGroup" label="Fill out your phone number">
        <form [formGroup]="thirdFormGroup">
            <mat-form-field appearance="fill">
                <mat-label>Phone number</mat-label>
                <input matInput formControlName="thirdCtrl" placeholder="Ex. 12345678" required>
            </mat-form-field>
            <div>
                <button mat-flat-button class="tagus me-15" matStepperPrevious>Back</button>
                <button mat-flat-button class="tagus" matStepperNext>Next</button>
            </div>
        </form>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Done</ng-template>
        <p>You are now done.</p>
        <div>
            <button mat-flat-button class="tagus" matStepperPrevious>Back</button>
        </div>
    </mat-step>
</mat-stepper>