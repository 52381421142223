<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Expansion</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">UI Kit</li>
        <li class="breadcrumb-item fw-semibold">Expansion</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Basic Expansion Panel</h5>
    </mat-card-header>
    <mat-card-content>
        <mat-accordion>
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                <mat-panel-title>
                    This is the expansion title
                </mat-panel-title>
                <mat-panel-description>
                    This is a summary of the content
                </mat-panel-description>
                </mat-expansion-panel-header>
                <p>This is the primary content of the panel.</p>
            </mat-expansion-panel>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                <mat-panel-title>
                    Self aware panel
                </mat-panel-title>
                <mat-panel-description>
                    Currently I am {{panelOpenState ? 'open' : 'closed'}}
                </mat-panel-description>
                </mat-expansion-panel-header>
                <p>I'm visible because I am open</p>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Expansion Panel as Accordion</h5>
    </mat-card-header>
    <mat-card-content>
        <mat-accordion class="example-headers-align">
            <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Personal data
                    </mat-panel-title>
                    <mat-panel-description>
                        Type your name and age
                        <mat-icon>account_circle</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-form-field appearance="fill">
                    <mat-label>First name</mat-label>
                    <input matInput>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Age</mat-label>
                    <input matInput type="number" min="1">
                </mat-form-field>
                <mat-action-row>
                    <button mat-button color="primary" (click)="nextStep()">Next</button>
                </mat-action-row>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Destination
                    </mat-panel-title>
                    <mat-panel-description>
                        Type the country name
                        <mat-icon>map</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-form-field appearance="fill">
                    <mat-label>Country</mat-label>
                    <input matInput>
                </mat-form-field>
                <mat-action-row>
                    <button mat-button color="warn" (click)="prevStep()">Previous</button>
                    <button mat-button color="primary" (click)="nextStep()">Next</button>
                </mat-action-row>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Day of the trip
                    </mat-panel-title>
                    <mat-panel-description>
                        Inform the date you wish to travel
                        <mat-icon>date_range</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-form-field appearance="fill">
                    <mat-label>Date</mat-label>
                    <input matInput [matDatepicker]="picker" (focus)="picker.open()" readonly>
                </mat-form-field>
                <mat-datepicker #picker></mat-datepicker>
                <mat-action-row>
                    <button mat-button color="warn" (click)="prevStep()">Previous</button>
                    <button mat-button color="primary" (click)="nextStep()">End</button>
                </mat-action-row>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Accordion with Expand/Collapse All Toggles</h5>
    </mat-card-header>
    <mat-card-content>
        <app-accordion-toggles></app-accordion-toggles>
    </mat-card-content>
</mat-card>