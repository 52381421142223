import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, catchError, map, Observable } from 'rxjs';
import { ApiService } from "../services/api.service";
import { EndpointTypeEnum } from "../enums/endpoint-type.enum";

@Injectable({
  providedIn: 'root'
})
export class MarketResolver implements Resolve<any> {
  private _market: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private apiService: ApiService,
    private router: Router,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const marketId = route.paramMap.get('market');

    return this.apiService.get(EndpointTypeEnum.Market, '/markets', true, marketId).pipe(
      map((response: any) => {
        this._market.next(response.data);
        return response.data;
      }),
      catchError(error => {
        return this.router.navigate(['/access-denied']);
      })
    );
  }

  get market$() {
    return this._market.asObservable();
  }
}
