import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {ErrorTypeEnum} from "../../../../enums/error-type.enum";

@Injectable({
  providedIn: 'root'
})
export class SuccessService {
  private global: string[] = [];
  private local: string[] = [];
  private globalSubject = new BehaviorSubject<string[]>(this.global);
  private localSubject = new BehaviorSubject<string[]>(this.local);

  add(type: ErrorTypeEnum, message: string[]) {
    if (type === ErrorTypeEnum.Global) {
      // добавляем сообщения в массив
      this.global.push(...message);
      // уведомляем подписчиков об изменении массива сообщений
      this.globalSubject.next([...this.global]);
    } else if (type === ErrorTypeEnum.Local) {
      // локальные сообщения очищаем перед добавлением новых
      this.clear(ErrorTypeEnum.Local);
      // добавляем сообщения в массив
      this.local.push(...message);
      // уведомляем подписчиков об изменении массива ошибок
      this.localSubject.next([...this.local]);
    }
  }

  clear(type: ErrorTypeEnum) {
    if (type === ErrorTypeEnum.Global) {
      this.global = [];
      this.globalSubject.next([...this.global]);
    } else if (type === ErrorTypeEnum.Local) {
      this.local = [];
      this.localSubject.next([...this.local]);
    }
  }

  // Observable, чтобы компоненты могли подписаться на ошибки
  get(type: ErrorTypeEnum) {
    if (type === ErrorTypeEnum.Global) {
      return this.globalSubject.asObservable();
    } else {
      return this.localSubject.asObservable();
    }
  }
}
