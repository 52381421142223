import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { ErrorTypeEnum } from "../../../../enums/error-type.enum";

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private errorsGlobal: string[] = [];
  private errorsLocal: string[] = [];
  private errorsGlobalSubject = new BehaviorSubject<string[]>(this.errorsGlobal);
  private errorsLocalSubject = new BehaviorSubject<string[]>(this.errorsLocal);

  // добавления ошибки
  // addError(error: string) {
  //   // для начала очистим предыдущие ошибки
  //   this.clearErrors();
  //   // закидываем ошибку
  //   this.errors.push(error);
  //   this.errorsSubject.next([...this.errors]);
  // }
  addError(type: ErrorTypeEnum, errors: string[]) {
    // для начала очищаем предыдущие ошибки
    // this.clearErrors();
    if (type === ErrorTypeEnum.Global) {
      // добавляем ошибки в массив
      this.errorsGlobal.push(...errors);
      // уведомляем подписчиков об изменении массива ошибок
      this.errorsGlobalSubject.next([...this.errorsGlobal]);
    } else if (type === ErrorTypeEnum.Local) {
      // локальные ошибки очищаем
      this.clearErrors(ErrorTypeEnum.Local);
      // добавляем ошибки в массив
      this.errorsLocal.push(...errors);
      // уведомляем подписчиков об изменении массива ошибок
      this.errorsLocalSubject.next([...this.errorsLocal]);
    }
  }

  // очистка ошибок
  clearErrors(type: ErrorTypeEnum) {
    if (type === ErrorTypeEnum.Global) {
      this.errorsGlobal = [];
      this.errorsGlobalSubject.next([...this.errorsGlobal]);
    } else if (type === ErrorTypeEnum.Local) {
      this.errorsLocal = [];
      this.errorsLocalSubject.next([...this.errorsLocal]);
    }
  }

  // Observable, чтобы компоненты могли подписаться на ошибки
  getErrors(type: ErrorTypeEnum) {
    if (type === ErrorTypeEnum.Global) {
      return this.errorsGlobalSubject.asObservable();
    } else {
      return this.errorsLocalSubject.asObservable();
    }
  }
}
