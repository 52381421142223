<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Material Symbols</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Icons</li>
        <li class="breadcrumb-item fw-semibold">Material Symbols</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card" [class.component-dark-theme]="themeService.isDark()">
    <mat-card-content>
        <div class="icon-demo">
            <span class="fw-semibold me-10">Use example:</span>
            <code>
                &lt;<span class="red">span</span> <span class="green">class</span>=<span class="yellow">&quot;material-symbols-outlined&quot;</span>&gt;home&lt;/<span class="red">span</span>&gt;
            </code>
        </div>
        <div class="row justify-content-center">
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        search
                    </span>
                    <p class="d-block gray-color">search</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        home
                    </span>
                    <p class="d-block gray-color">home</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        menu
                    </span>
                    <p class="d-block gray-color">menu</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        close
                    </span>
                    <p class="d-block gray-color">close</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        settings
                    </span>
                    <p class="d-block gray-color">settings</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        favorite
                    </span>
                    <p class="d-block gray-color">favorite</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        delete
                    </span>
                    <p class="d-block gray-color">delete</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        star
                    </span>
                    <p class="d-block gray-color">star</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        toggle_on
                    </span>
                    <p class="d-block gray-color">toggle_on</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        radio_button_checked
                    </span>
                    <p class="d-block gray-color">radio_button_checked</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        sync
                    </span>
                    <p class="d-block gray-color">sync</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        fullscreen
                    </span>
                    <p class="d-block gray-color">fullscreen</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        terminal
                    </span>
                    <p class="d-block gray-color">terminal</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        keyboard_voice
                    </span>
                    <p class="d-block gray-color">keyboard_voice</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        indeterminate_check_box
                    </span>
                    <p class="d-block gray-color">indeterminate_check_box</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        token
                    </span>
                    <p class="d-block gray-color">token</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        assistant_navigation
                    </span>
                    <p class="d-block gray-color">assistant_navigation</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        swipe_right
                    </span>
                    <p class="d-block gray-color">swipe_right</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        move_down
                    </span>
                    <p class="d-block gray-color">move_down</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        switch_right
                    </span>
                    <p class="d-block gray-color">switch_right</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        share_reviews
                    </span>
                    <p class="d-block gray-color">share_reviews</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        repartition
                    </span>
                    <p class="d-block gray-color">repartition</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        clock_loader_60
                    </span>
                    <p class="d-block gray-color">clock_loader_60</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        clock_loader_90
                    </span>
                    <p class="d-block gray-color">clock_loader_90</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        group
                    </span>
                    <p class="d-block gray-color">group</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        rocket_launch
                    </span>
                    <p class="d-block gray-color">rocket_launch</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        sentiment_very_satisfied
                    </span>
                    <p class="d-block gray-color">sentiment_very_satisfied</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        recycling
                    </span>
                    <p class="d-block gray-color">recycling</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        coronavirus
                    </span>
                    <p class="d-block gray-color">coronavirus</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        mood_bad
                    </span>
                    <p class="d-block gray-color">mood_bad</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        cloudy_snowing
                    </span>
                    <p class="d-block gray-color">cloudy_snowing</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        clean_hands
                    </span>
                    <p class="d-block gray-color">clean_hands</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        routine
                    </span>
                    <p class="d-block gray-color">routine</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        globe_asia
                    </span>
                    <p class="d-block gray-color">globe_asia</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        genetics
                    </span>
                    <p class="d-block gray-color">genetics</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <span class="material-symbols-outlined">
                        visibility
                    </span>
                    <p class="d-block gray-color">visibility</p>
                </div>
            </div>
        </div>
        <div class="text-center mt-25">
            <a mat-flat-button href="https://fonts.google.com/icons" class="tagus" target="_blank">View All Icons</a>
        </div>
    </mat-card-content>
</mat-card>