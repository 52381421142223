<!-- легаси -->

<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Товари</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Дашборд
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Каталог</li>
        <li class="breadcrumb-item fw-semibold">Google Merchant</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header>
        <h5 class="mb-0">Категории</h5>
        <div class="d-flex align-items-center">
            <a target="_blank" [href]="getFeedURL('ru')" class="margin-right-10px ql-color-blue">
                Просмотреть feed
            </a>
            <a target="_blank" [href]="getFeedURL('ua')" class="margin-right-10px ql-color-blue">
                Переглянути feed
            </a>
            <a
                mat-flat-button class="default-btn tagus small "
                (click)="generate()"
            >
                <span *ngIf="!isLoading">
                    <i class="ri-add-fill"></i> Генерувати
                </span>

                <span *ngIf="isLoading" class="loading-spinner">
                    Is loading ...
                    <!--                &lt;!&ndash; Your loading spinner here &ndash;&gt;-->
                    <!--                <mat-spinner></mat-spinner>-->
                </span>
            </a>
        </div>
    </mat-card-header>
    <app-local-success></app-local-success>
    <app-modal-confirmation *ngIf="showConfirmation" (confirmed)="onConfirmationRemove($event)"></app-modal-confirmation>
    <div class="width-100">
        <mat-label>Показувати у категоріях</mat-label>
        <div style="max-height: 200px;overflow-y: auto">
            <ng-container *ngFor="let category of categoriesForDropdown">
                <mat-checkbox
                    class="example-margin"
                    style="display: block"
                    [value]="category._id"
                    (change)="updateSelectedCategories(category._id)"
                    [checked]="isSelectedCategories(category._id)"
                >
                    {{ category.name_ua }}
                </mat-checkbox>
            </ng-container>
        </div>
    </div>
</mat-card>
