import { Component } from '@angular/core';

@Component({
  selector: 'app-lms-current-courses',
  templateUrl: './lms-current-courses.component.html',
  styleUrls: ['./lms-current-courses.component.scss']
})
export class LmsCurrentCoursesComponent {

}
