import { Component } from '@angular/core';
import { CustomizerSettingsService } from '../../customizer-settings/customizer-settings.service';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../../services/api.service";
import {Router} from "@angular/router";
import {AuthStorageService} from "../../../services/auth-storage.service";
import {EndpointTypeEnum} from "../../../enums/endpoint-type.enum";
import {ErrorTypeEnum} from "../../../enums/error-type.enum";
import {ErrorService} from "../../common/notifications/errors/error.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    hide = true;
    form: FormGroup;
    formWasSubmitted = false;

    constructor(
        private router: Router,
        private apiService: ApiService,
        private authStorageService: AuthStorageService,
        private formBuilder: FormBuilder,
        private errorService: ErrorService,
        public themeService: CustomizerSettingsService
    ) {
        // инициализируем поля из формы и валидируем
        this.form = this.formBuilder.group({
            phone: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
            password: ['', Validators.required ],
        });
    }

    onFormSubmit(form: FormGroup): void {
        this.formWasSubmitted = true;

        // если есть ошибки валидации, не даем отправить форму
        if (form.invalid) {
            return;
        }

        // получаем значения из формы
        const formValue = form.value;
        formValue.phone = "+" + formValue.phone;

        this.apiService
            .post(EndpointTypeEnum.Auth, '/login', false, formValue)
            .subscribe({
                next: (response: any) => {
                    this.authStorageService.setToken(response);

                    // редиректим исходя из роли
                    // this.router.navigate(['/market']);
                    window.location.href = '/market';
                },
                error: err => {
                    this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                    form.controls['password'].reset();
                }
        });
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    toggleCardBorderTheme() {
        this.themeService.toggleCardBorderTheme();
    }

    toggleCardBorderRadiusTheme() {
        this.themeService.toggleCardBorderRadiusTheme();
    }

    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }

}
