import { Component } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { Observable, ReplaySubject } from 'rxjs';

export interface PeriodicElement {
    name: string;
    position: number;
    weight: number;
    symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
    {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
    {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
    {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
    {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
    {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
    {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
    {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
    {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
    {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
    {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
];

@Component({
    selector: 'app-add-remove-observable-based-datasource-table',
    templateUrl: './add-remove-observable-based-datasource-table.component.html',
    styleUrls: ['./add-remove-observable-based-datasource-table.component.scss']
})
export class AddRemoveObservableBasedDatasourceTableComponent {

    displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
    dataToDisplay = [...ELEMENT_DATA];

    dataSource = new ExampleDataSource(this.dataToDisplay);

    addData() {
        const randomElementIndex = Math.floor(Math.random() * ELEMENT_DATA.length);
        this.dataToDisplay = [...this.dataToDisplay, ELEMENT_DATA[randomElementIndex]];
        this.dataSource.setData(this.dataToDisplay);
    }

    removeData() {
        this.dataToDisplay = this.dataToDisplay.slice(0, -1);
        this.dataSource.setData(this.dataToDisplay);
    }

}

class ExampleDataSource extends DataSource<PeriodicElement> {
    private _dataStream = new ReplaySubject<PeriodicElement[]>();

    constructor(initialData: PeriodicElement[]) {
        super();
        this.setData(initialData);
    }

    connect(): Observable<PeriodicElement[]> {
        return this._dataStream;
    }

    disconnect() {}

    setData(data: PeriodicElement[]) {
        this._dataStream.next(data);
    }
}