<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Drag & Drop</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">UI Kit</li>
        <li class="breadcrumb-item fw-semibold">Drag & Drop</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Basic Drag & Drop</h5>
    </mat-card-header>
    <mat-card-content [class.component-dark-theme]="themeService.isDark()">
        <div class="example-box" cdkDrag>
            Drag me around!
        </div>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Reordering Lists Drag & Drop</h5>
    </mat-card-header>
    <mat-card-content [class.component-dark-theme]="themeService.isDark()">
        <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
            <div class="example-list-box" *ngFor="let movie of movies" cdkDrag>{{movie}}</div>
        </div>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Connected Sorting Drag & Drop</h5>
    </mat-card-header>
    <mat-card-content>
        <app-connected-sorting-dd></app-connected-sorting-dd>
    </mat-card-content>
</mat-card>