<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Атрибути</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Дашборд
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Каталог</li>
        <li class="breadcrumb-item gray-color">Атрибути</li>
        <li class="breadcrumb-item fw-semibold">Створення атрибута</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header>
        <h5 class="mb-0">Створення атрибута</h5>
        <div class="d-flex align-items-center">
            <button mat-raised-button class="tagus small margin-right-10px" form="form" type="submit">Зберегти</button>
            <a class="small" mat-stroked-button routerLink="/main/catalog/attributes" color="warn">Вийти</a>
        </div>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="form" (submit)="onFormSubmit(form)" id="form">
            <div class="row">

                <mat-form-field class="example-full-width">
                    <mat-label>Назва (укр)</mat-label>
                    <input formControlName="name_ua" matInput>
                    <mat-error *ngIf="(formWasSubmitted && form.controls['name_ua'].invalid) || (form.controls['name_ua'].invalid && form.controls['name_ua'].touched)">
                        <ng-container *ngIf="form.controls['name_ua'].errors?.['required']">
                            Обов'язкове поле
                        </ng-container>
                        <ng-container *ngIf="form.controls['name_ua'].errors?.['minlength']">
                            Поле може містити мінімум 2 символи
                        </ng-container>
                        <ng-container *ngIf="form.controls['name_ua'].errors?.['maxlength']">
                            Поле може містити максимум 255 символів
                        </ng-container>
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="example-full-width">
                    <mat-label>Назва (рус)</mat-label>
                    <input formControlName="name_ru" matInput>
                    <mat-error *ngIf="(formWasSubmitted && form.controls['name_ru'].invalid) || (form.controls['name_ru'].invalid && form.controls['name_ru'].touched)">
                        <ng-container *ngIf="form.controls['name_ru'].errors?.['required']">
                            Обов'язкове поле
                        </ng-container>
                        <ng-container *ngIf="form.controls['name_ru'].errors?.['minlength']">
                            Поле може містити мінімум 2 символи
                        </ng-container>
                        <ng-container *ngIf="form.controls['name_ru'].errors?.['maxlength']">
                            Поле може містити максимум 255 символів
                        </ng-container>
                    </mat-error>
                </mat-form-field>

            </div>
        </form>
    </mat-card-content>
</mat-card>
