import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrl: './modal-confirmation.component.scss'
})
export class ModalConfirmationComponent {
    @Output() confirmed = new EventEmitter<boolean>();

    confirm() {
        this.confirmed.emit(true);
    }

    cancel() {
        this.confirmed.emit(false);
    }
}
