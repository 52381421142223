<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Users</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Projects</li>
        <li class="breadcrumb-item fw-semibold">Users</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header>
        <h5 class="mb-0">Users List</h5>
        <button mat-flat-button class="default-btn tagus" (click)="openCreateUserDialog('300ms', '100ms')">
            <i class="ri-add-fill"></i> Create New User
        </button>
    </mat-card-header>
    <mat-card-content>
        <div class="teamMembersListTable tagus-mat-table mat-elevation-z8">
            <table mat-table [dataSource]="dataSource">

                <!-- Name Column -->
                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Name</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        <div class="d-flex align-items-center">
                            <mat-checkbox class="pe-10"></mat-checkbox>
                            <div class="d-flex align-items-center user-info">
                                <img [src]="element.user.userImage" class="rounded-circle" width="45" alt="user">
                                <div class="title">
                                    {{element.user.userName}}
                                    <span class="muted-color fw-normal d-block">{{element.user.userDesignation}}</span>
                                </div>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>Email</th>
                    <td mat-cell *matCellDef="let element"><a href="mailto:{{element.email}}">{{element.email}}</a></td>
                </ng-container>

                <!-- Role Column -->
                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef>Role</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.role}}
                    </td>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="badge fw-medium" *ngIf="element.status.active">{{element.status.active}}</span>
                        <span class="badge inactive fw-medium" *ngIf="element.status.inactive">{{element.status.inactive}}</span>
                    </td>
                </ng-container>

                <!-- Projects Column -->
                <ng-container matColumnDef="projects">
                    <th mat-header-cell *matHeaderCellDef>Projects</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.projects}}
                    </td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>Action</th>
                    <td mat-cell *matCellDef="let element">
                        <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                            <i class="{{element.action}}"></i>
                        </button>
                        <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-share-line"></i> Share
                            </button>
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-link"></i> Get Sharable Link
                            </button>
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-edit-line"></i> Rename
                            </button>
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-delete-bin-line"></i> Remove
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator
                [pageSizeOptions]="[10, 15, 20]"
                showFirstLastButtons
            >
            </mat-paginator>
        </div>
    </mat-card-content>
</mat-card>