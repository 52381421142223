<div class="row">
    <div class="col-sm-6 col-xxl-3">
        <mat-card class="single-stats-box tagus-card mb-25 position-relative">
            <div class="d-flex align-items-center">
                <div class="icon">
                    <i class="flaticon-donut-chart"></i>
                </div>
                <div class="title">
                    <span class="d-block fw-semibold gray-color">Due Tasks</span>
                    <h3>41</h3>
                    <p>Completed projects this month</p>
                </div>
            </div>
            <span class="badge fw-semibold">5.80% <i class="flaticon-arrow-up"></i></span>
        </mat-card>
    </div>
    <div class="col-sm-6 col-xxl-3">
        <mat-card class="single-stats-box tagus-card mb-25 position-relative">
            <div class="d-flex align-items-center">
                <div class="icon">
                    <i class="flaticon-goal"></i>
                </div>
                <div class="title">
                    <span class="d-block fw-semibold gray-color">Active Projects</span>
                    <h3>65</h3>
                    <p>Projects this month</p>
                </div>
            </div>
            <span class="badge fw-semibold down">1.04% <i class="flaticon-down-filled-triangular-arrow"></i></span>
        </mat-card>
    </div>
    <div class="col-sm-6 col-xxl-3">
        <mat-card class="single-stats-box tagus-card mb-25 position-relative">
            <div class="d-flex align-items-center">
                <div class="icon">
                    <i class="flaticon-timer"></i>
                </div>
                <div class="title">
                    <span class="d-block fw-semibold gray-color">Total Hours</span>
                    <h3>801:30 Hrs</h3>
                    <p>Projects this month</p>
                </div>
            </div>
            <span class="badge fw-semibold">7.77% <i class="flaticon-arrow-up"></i></span>
        </mat-card>
    </div>
    <div class="col-sm-6 col-xxl-3">
        <mat-card class="single-stats-box tagus-card mb-25 position-relative">
            <div class="d-flex align-items-center">
                <div class="icon">
                    <i class="flaticon-award"></i>
                </div>
                <div class="title">
                    <span class="d-block fw-semibold gray-color">Total Projects</span>
                    <h3>24k</h3>
                    <p>Projects this month</p>
                </div>
            </div>
            <span class="badge fw-semibold">3.33% <i class="flaticon-arrow-up"></i></span>
        </mat-card>
    </div>
</div>