<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Sellers</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Sellers</li>
    </ol>
</div>

<div class="row">
    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
        <mat-card class="mb-25 text-center tagus-card">
            <mat-card-content>
                <div class="icon bg-gray mb-20 rounded-circle d-inline-block position-relative">
                    <img src="assets/img/sellers/seller1.png" class="ms-auto me-auto" alt="seller">
                </div>
                <h5 class="mb-5 fw-semibold">Nesta Technologies</h5>
                <span class="d-block muted-color">Katia Stapleton</span>
                <div class="btn-box">
                    <a mat-flat-button routerLink="/" class="gray">View Details</a>
                </div>
                <ul class="list d-flex align-items-center justify-content-space-between text-center mb-0">
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Item Stock</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">12,0558</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Wallet Balance</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">$48,450.00</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
        <mat-card class="mb-25 text-center tagus-card">
            <mat-card-content>
                <div class="icon bg-gray mb-20 rounded-circle d-inline-block position-relative">
                    <img src="assets/img/sellers/seller2.png" class="ms-auto me-auto" alt="seller">
                </div>
                <h5 class="mb-5 fw-semibold">Force Medicines</h5>
                <span class="d-block muted-color">Wade Dave</span>
                <div class="btn-box">
                    <a mat-flat-button routerLink="/" class="gray">View Details</a>
                </div>
                <ul class="list d-flex align-items-center justify-content-space-between text-center mb-0">
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Item Stock</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">10,500</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Wallet Balance</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">$50,500.00</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
        <mat-card class="mb-25 text-center tagus-card">
            <mat-card-content>
                <div class="icon bg-gray mb-20 rounded-circle d-inline-block position-relative">
                    <img src="assets/img/sellers/seller3.png" class="ms-auto me-auto" alt="seller">
                </div>
                <h5 class="mb-5 fw-semibold">Metelgo Sys</h5>
                <span class="d-block muted-color">Gilbert Dan</span>
                <div class="btn-box">
                    <a mat-flat-button routerLink="/" class="gray">View Details</a>
                </div>
                <ul class="list d-flex align-items-center justify-content-space-between text-center mb-0">
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Item Stock</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">8,450</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Wallet Balance</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">$60,450.00</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
        <mat-card class="mb-25 text-center tagus-card">
            <mat-card-content>
                <div class="icon bg-gray mb-20 rounded-circle d-inline-block position-relative">
                    <img src="assets/img/sellers/seller4.png" class="ms-auto me-auto" alt="seller">
                </div>
                <h5 class="mb-5 fw-semibold">Digito Galatoxy</h5>
                <span class="d-block muted-color">Roberto Ramon</span>
                <div class="btn-box">
                    <a mat-flat-button routerLink="/" class="gray">View Details</a>
                </div>
                <ul class="list d-flex align-items-center justify-content-space-between text-center mb-0">
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Item Stock</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">15,0587</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Wallet Balance</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">$78,450.00</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
        <mat-card class="mb-25 text-center tagus-card">
            <mat-card-content>
                <div class="icon bg-gray mb-20 rounded-circle d-inline-block position-relative">
                    <img src="assets/img/sellers/seller5.png" class="ms-auto me-auto" alt="seller">
                </div>
                <h5 class="mb-5 fw-semibold">Micro Technology</h5>
                <span class="d-block muted-color">Nathaniel Liam</span>
                <div class="btn-box">
                    <a mat-flat-button routerLink="/" class="gray">View Details</a>
                </div>
                <ul class="list d-flex align-items-center justify-content-space-between text-center mb-0">
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Item Stock</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">18,0558</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Wallet Balance</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">$65,450.00</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
        <mat-card class="mb-25 text-center tagus-card">
            <mat-card-content>
                <div class="icon bg-gray mb-20 rounded-circle d-inline-block position-relative">
                    <img src="assets/img/sellers/seller6.png" class="ms-auto me-auto" alt="seller">
                </div>
                <h5 class="mb-5 fw-semibold">iTest Factory</h5>
                <span class="d-block muted-color">Ethan Milton</span>
                <div class="btn-box">
                    <a mat-flat-button routerLink="/" class="gray">View Details</a>
                </div>
                <ul class="list d-flex align-items-center justify-content-space-between text-center mb-0">
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Item Stock</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">19,0558</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Wallet Balance</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">$76,450.00</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
        <mat-card class="mb-25 text-center tagus-card">
            <mat-card-content>
                <div class="icon bg-gray mb-20 rounded-circle d-inline-block position-relative">
                    <img src="assets/img/sellers/seller7.png" class="ms-auto me-auto" alt="seller">
                </div>
                <h5 class="mb-5 fw-semibold">Syntech Service</h5>
                <span class="d-block muted-color">Joshua Harvey</span>
                <div class="btn-box">
                    <a mat-flat-button routerLink="/" class="gray">View Details</a>
                </div>
                <ul class="list d-flex align-items-center justify-content-space-between text-center mb-0">
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Item Stock</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">20,0558</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Wallet Balance</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">$56,450.00</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
        <mat-card class="mb-25 text-center tagus-card">
            <mat-card-content>
                <div class="icon bg-gray mb-20 rounded-circle d-inline-block position-relative">
                    <img src="assets/img/sellers/seller8.png" class="ms-auto me-auto" alt="seller">
                </div>
                <h5 class="mb-5 fw-semibold">Zotic Mentex</h5>
                <span class="d-block muted-color">Antonio Connor</span>
                <div class="btn-box">
                    <a mat-flat-button routerLink="/" class="gray">View Details</a>
                </div>
                <ul class="list d-flex align-items-center justify-content-space-between text-center mb-0">
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Item Stock</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">22,0558</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Wallet Balance</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">$90,450.00</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
</div>