<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">File Uploader</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">File Uploader</li>
    </ol>
</div>

<div class="row" [class.component-dark-theme]="themeService.isDark()">
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header>
                <h5 class="mb-0">Simple File Uploader</h5>
            </mat-card-header>
            <mat-card-content>
                <ngx-dropzone (change)="onSelect($event)">
                    <ngx-dropzone-label class="fw-semibold">Drop files here or click to upload.</ngx-dropzone-label>
                    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                    </ngx-dropzone-preview>
                </ngx-dropzone>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header>
                <h5 class="mb-0">Image Preview File Uploader</h5>
            </mat-card-header>
            <mat-card-content>
                <ngx-dropzone (change)="onSelect($event)">
                    <ngx-dropzone-label class="fw-semibold">Drop files here or click to upload.</ngx-dropzone-label>
                    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                    </ngx-dropzone-image-preview>
                </ngx-dropzone>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header>
                <h5 class="mb-0">Video Preview File Uploader</h5>
            </mat-card-header>
            <mat-card-content>
                <ngx-dropzone (change)="onSelect($event)">
                    <ngx-dropzone-label class="fw-semibold">Drop files here or click to upload.</ngx-dropzone-label>
                    <ngx-dropzone-video-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                    </ngx-dropzone-video-preview>
                </ngx-dropzone>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header>
                <h5 class="mb-0">Disabled File Uploader</h5>
            </mat-card-header>
            <mat-card-content>
                <ngx-dropzone (change)="onSelect($event)" [expandable]="true" [disabled]="true">
                    <ngx-dropzone-label class="fw-semibold">Drop files here or click to upload.</ngx-dropzone-label>
                    <ngx-dropzone-video-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                    </ngx-dropzone-video-preview>
                </ngx-dropzone>
            </mat-card-content>
        </mat-card>
    </div>
</div>