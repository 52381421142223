import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthStorageService } from "../services/auth-storage.service";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MainGuard implements CanActivate {
  constructor(
    private authStorageService: AuthStorageService,
    private router: Router,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.authStorageService.getUserFromToken();

    if (user && user.role === 'main') {
      // если все хорошо, пропускаем
      return true;
    } else if (user && user.role !== 'main') {
      !environment.production && console.log('*guard*. main guard');
      await this.router.navigate(['/access-denied']);
      return false;
    } else {
      // если пользователь не авторизован
      await this.router.navigate(['/authentication/login']);
      return false;
    }
  }
}
