<div class="row">
    <div class="col-xxl-8">

        <app-ecommerce-stats></app-ecommerce-stats>

        <app-audience-overview></app-audience-overview>

        <div class="row">
            <div class="col-lg-8">

                <app-visits-by-day></app-visits-by-day>

            </div>
            <div class="col-lg-4">

                <app-ecommerce-impressions></app-ecommerce-impressions>

                <app-ecommerce-activity-timeline></app-ecommerce-activity-timeline>

            </div>
        </div>

        <app-revenue-status></app-revenue-status>
        
    </div>
    <div class="col-xxl-4">

        <app-ecommerce-ratings></app-ecommerce-ratings>

        <app-live-visits-on-our-site></app-live-visits-on-our-site>

        <app-sales-by-locations></app-sales-by-locations>

        <app-new-customers></app-new-customers>
        
    </div>
</div>

<app-recent-orders></app-recent-orders>

<div class="row">
    <div class="col-xxl-8">
        <app-team-members-list></app-team-members-list>
    </div>
    <div class="col-xxl-4">
        <app-best-selling-products></app-best-selling-products>
    </div>
</div>