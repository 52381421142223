<div class="create-dialog-box scrollable-dialog">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">Create New User</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>
    <form>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Image</label>
                    <input type="file" class="input-control">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Name</label>
                    <input type="text" class="input-control" placeholder="Name">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">User Name</label>
                    <input type="text" class="input-control" placeholder="User name">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Email</label>
                    <input type="email" class="input-control" placeholder="Email">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Tags</label>
                    <input type="text" class="input-control" placeholder="Tags">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Projects</label>
                    <input type="text" class="input-control" placeholder="Projects">
                </div>
            </div>
        </div>
        <div class="text-end">
            <button type="button" class="gray" (click)="close()"><i class="ri-close-fill"></i> Cancel</button>
            <button type="submit"><i class="ri-add-fill"></i> Create New User</button>
        </div>
    </form>
</div>