<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Категорії</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Дашборд
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Каталог</li>
        <li class="breadcrumb-item gray-color">Категорії</li>
        <li class="breadcrumb-item fw-semibold">Створення категорії</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header>
        <h5 class="mb-0">Створення категорії</h5>
        <div class="d-flex align-items-center">
            <button mat-raised-button class="tagus small margin-right-10px" form="form" type="submit">Зберегти</button>
            <a class="small" mat-stroked-button routerLink="/main/catalog/categories" color="warn">Вийти</a>
        </div>
    </mat-card-header>
    <mat-card-content>
        <app-local-error></app-local-error>
        <form [formGroup]="form" (submit)="onFormSubmit(form)" id="form">
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                <!-- Загальне -->
                <mat-tab label="Загальне"><br>
                    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                        <!-- УКР -->
                        <mat-tab label="УКР"><br>

                            <mat-form-field class="width-100">
                                <mat-label>Назва</mat-label>
                                <input formControlName="name_ua" matInput>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['name_ua'].invalid) || (form.controls['name_ua'].invalid && form.controls['name_ua'].touched)">
                                    <ng-container *ngIf="form.controls['name_ua'].errors?.['required']">
                                        Обов'язкове поле
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['name_ua'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['name_ua'].errors?.['maxlength']">
                                        Поле може містити максимум 255 символів
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>

                            <div class="width-100" style="padding-bottom: 20px;">
                                <label class="d-block mb-12 fw-semibold gray-color">Опис</label>
                                <angular-editor formControlName="description_ua" [config]="editorConfig"></angular-editor>
                            </div>

                            <mat-form-field class="width-100">
                                <mat-label>Мета title</mat-label>
                                <input formControlName="meta_title_ua" matInput>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['meta_title_ua'].invalid) || (form.controls['meta_title_ua'].invalid && form.controls['meta_title_ua'].touched)">
                                    <ng-container *ngIf="form.controls['meta_title_ua'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['meta_title_ua'].errors?.['maxlength']">
                                        Поле може містити максимум 255 символів
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="width-100">
                                <mat-label>Мета description</mat-label>
                                <textarea formControlName="meta_description_ua" rows="5" matInput></textarea>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['meta_description_ua'].invalid) || (form.controls['meta_description_ua'].invalid && form.controls['meta_description_ua'].touched)">
                                    <ng-container *ngIf="form.controls['meta_description_ua'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['meta_description_ua'].errors?.['maxlength']">
                                        Поле може містити максимум 255 символів
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>

                        </mat-tab>
                        <mat-tab label="РУС"><br>

                            <mat-form-field class="width-100">
                                <mat-label>Назва</mat-label>
                                <input formControlName="name_ru" matInput>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['name_ru'].invalid) || (form.controls['name_ru'].invalid && form.controls['name_ru'].touched)">
                                    <ng-container *ngIf="form.controls['name_ru'].errors?.['required']">
                                        Обов'язкове поле
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['name_ru'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['name_ru'].errors?.['maxlength']">
                                        Поле може містити максимум 255 символів
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>

                            <div class="width-100" style="padding-bottom: 20px;">
                                <label class="d-block mb-12 fw-semibold gray-color">Опис</label>
                                <angular-editor formControlName="description_ru" [config]="editorConfig"></angular-editor>
                            </div>

                            <mat-form-field class="width-100">
                                <mat-label>Мета title</mat-label>
                                <input formControlName="meta_title_ru" matInput>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['meta_title_ru'].invalid) || (form.controls['meta_title_ru'].invalid && form.controls['meta_title_ru'].touched)">
                                    <ng-container *ngIf="form.controls['meta_title_ru'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['meta_title_ru'].errors?.['maxlength']">
                                        Поле може містити максимум 255 символів
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="width-100">
                                <mat-label>Мета description</mat-label>
                                <textarea formControlName="meta_description_ru" rows="5" matInput></textarea>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['meta_description_ru'].invalid) || (form.controls['meta_description_ru'].invalid && form.controls['meta_description_ru'].touched)">
                                    <ng-container *ngIf="form.controls['meta_description_ru'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['meta_description_ru'].errors?.['maxlength']">
                                        Поле може містити максимум 255 символів
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>

                        </mat-tab>
                    </mat-tab-group>
                </mat-tab>
                <!-- Зв'язки -->
                <mat-tab label="Зв'язки"><br>

                    <mat-form-field appearance="fill" class="width-100">
                        <mat-label>Головна категорія</mat-label>
                        <mat-select formControlName="parent_category">
                            <mat-option *ngFor="let categoryForDropdown of categoriesForDropdown" [value]="categoryForDropdown._id">
                                {{categoryForDropdown.name_ua}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <hr/>
                    <mat-form-field class="width-100">
                        <mat-label>З цими товарами дивляться</mat-label>
                        <input #input
                               type="text"
                               placeholder=""
                               matInput
                               [formControl]="categoriesControlForViewedWith"
                               [matAutocomplete]="auto"
                               (input)="filterCategoriesViewedWith()"
                               (focus)="filterCategoriesViewedWith()">
                        <mat-autocomplete requireSelection #auto="matAutocomplete" (optionSelected)="onUpdateCategoriesViewedWith($event)">
                            <mat-option *ngFor="let filteredCategory of filteredCategoriesViewedWith" [value]="filteredCategory">
                                {{ filteredCategory.name_ua }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <ng-container *ngFor="let selectedCategory of selectedCategoriesViewedWith; let i = index">
                        <span style="cursor: pointer" (click)="removeCategoryViewedWith(i)">
                            ❌ {{ selectedCategory.name_ua }}
                        </span>
                        <br/>
                    </ng-container>

                </mat-tab>
                <!-- Дизайн -->
                <mat-tab label="Дизайн"><br>
                    <div class="row" style="margin: 0">

                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="d-block mb-12 fw-semibold gray-color">Зображення</label>
                            <input type="file" (change)="uploadImage($event, 'imageCover')" accept="image/*" formControlName="image">
                            <br/>
                            <img *ngIf="imageCover" [src]="backendUrl + '/uploads/' + imageCover" class="border-radius mt-15" width="200">
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="width-100" style="padding: 20px 0">
                                <label class="d-block mb-12 fw-semibold gray-color">Банер в меню (укр)</label>
                                <input type="file" (change)="uploadImage($event, 'bannerMenuUa')" accept="image/*">
                                <br/>
                                <img *ngIf="bannerMenuUa" [src]="backendUrl + '/uploads/' + bannerMenuUa" class="border-radius mt-15" width="200">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="width-100" style="padding: 20px 0">
                                <label class="d-block mb-12 fw-semibold gray-color">Банер в меню (рус)</label>
                                <input type="file" (change)="uploadImage($event, 'bannerMenuRu')" accept="image/*">
                                <br/>
                                <img *ngIf="bannerMenuRu" [src]="backendUrl + '/uploads/' + bannerMenuRu" class="border-radius mt-15" width="200">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field class="width-100">
                                <mat-label>Банер URL (для укр) - починаючи з http/https</mat-label>
                                <input formControlName="banner_url_ua" matInput>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field class="width-100">
                                <mat-label>Банер URL (для рус) - починаючи з http/https</mat-label>
                                <input formControlName="banner_url_ru" matInput>
                            </mat-form-field>
                        </div>
                    </div>

                </mat-tab>
                <!-- SEO -->
                <mat-tab label="SEO"><br>

                    <mat-form-field class="width-100">
                        <mat-label>URL</mat-label>
                        <input formControlName="url" matInput>
                        <mat-error *ngIf="(formWasSubmitted && form.controls['url'].invalid) || (form.controls['url'].invalid && form.controls['url'].touched)">
                            <ng-container *ngIf="form.controls['url'].errors?.['required']">
                                Обов'язкове поле
                            </ng-container>
                            <ng-container *ngIf="form.controls['url'].errors?.['pattern']">
                                Поле повинно містити тільки латинські літери, цифри та прочерки
                            </ng-container>
                            <ng-container *ngIf="form.controls['url'].errors?.['minlength']">
                                Поле може містити мінімум 2 символи
                            </ng-container>
                            <ng-container *ngIf="form.controls['url'].errors?.['maxlength']">
                                Поле може містити максимум 255 символів
                            </ng-container>
                        </mat-error>
                    </mat-form-field>

                </mat-tab>
                <!-- OpenGraph -->
                <mat-tab label="OpenGraph"><br>
                    <div class="row" style="margin: 0">

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field class="width-100">
                                <mat-label>Заголовок Facebook, Twitter (укр)</mat-label>
                                <input formControlName="opengraph_header_ua" matInput>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field class="width-100">
                                <mat-label>Заголовок Facebook, Twitter (рус)</mat-label>
                                <input formControlName="opengraph_header_ru" matInput>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field class="width-100">
                                <mat-label>Опис Facebook, Twitter (укр)</mat-label>
                                <textarea formControlName="opengraph_description_ua" rows="5" matInput></textarea>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field class="width-100">
                                <mat-label>Опис Facebook, Twitter (рус)</mat-label>
                                <textarea formControlName="opengraph_description_ru" rows="5" matInput></textarea>
                            </mat-form-field>
                        </div>

                        <div class="width-100">
                            <label class="d-block mb-12 fw-semibold gray-color">Зображення Facebook, Twitter</label>
                            <input type="file" (change)="uploadImage($event, 'imageOpenGraph')" accept="image/*" formControlName="opengraph_image">
                            <br/>
                            <img *ngIf="imageOpenGraph" [src]="backendUrl + '/uploads/' + imageOpenGraph" class="border-radius mt-15" width="200">
                        </div>

                    </div>
                </mat-tab>
            </mat-tab-group>
        </form>

    </mat-card-content>
</mat-card>
