<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Invoice</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Invoice</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header>
        <h5 class="mb-0">Invoice Lists</h5>
    </mat-card-header>
    <mat-card-content>
        <div class="invoiceListsTable tagus-mat-table mat-elevation-z8">
            <table mat-table [dataSource]="dataSource">

                <!-- ID Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>#</th>
                    <td mat-cell *matCellDef="let element" class="fw-semibold main-color">{{element.id}}</td>
                </ng-container>

                <!-- Invoice Name Column -->
                <ng-container matColumnDef="invoiceName">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Name</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        <a routerLink="/invoice-details" class="fw-semibold">
                            {{element.invoiceName}}
                        </a>
                    </td>
                </ng-container>

                <!-- Client Name Column -->
                <ng-container matColumnDef="clientName">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Client Name</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        <div class="user-info d-flex align-items-center">
                            <img [src]="element.clientName.img" class="rounded-circle" width="45" alt="user">
                            <div class="title">
                                <h5 class="mb-0 fw-semibold">{{element.clientName.name}}</h5>
                                <span class="muted-color d-block fw-normal">{{element.clientName.username}}</span>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <!-- Issued Date Column -->
                <ng-container matColumnDef="issuedDate">
                    <th mat-header-cell *matHeaderCellDef>Issued Date</th>
                    <td mat-cell *matCellDef="let element">{{element.issuedDate}}</td>
                </ng-container>

                <!-- Total Column -->
                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef>Total</th>
                    <td mat-cell *matCellDef="let element">{{element.total}}</td>
                </ng-container>

                <!-- Balance Column -->
                <ng-container matColumnDef="balance">
                    <th mat-header-cell *matHeaderCellDef>Balance</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="badge fw-medium" *ngIf="element.balance.paid">{{element.balance.paid}}</span>
                        <span class="badge pending fw-medium" *ngIf="element.balance.pending">{{element.balance.pending}}</span>
                    </td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>Action</th>
                    <td mat-cell *matCellDef="let element">
                        <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                            <i class="{{element.action}}"></i>
                        </button>
                        <mat-menu #actionMenu="matMenu" class="dotMenu monthMenu" xPosition="before">
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-share-line"></i> Share
                            </button>
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-edit-line"></i> Rename
                            </button>
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-delete-bin-line"></i> Remove
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
            <mat-paginator
                [pageSizeOptions]="[10, 20, 30, 40]"
                showFirstLastButtons
            >
            </mat-paginator>
        </div>
    </mat-card-content>
</mat-card>