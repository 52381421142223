<app-lms-stats></app-lms-stats>

<div class="row">
    <div class="col-xxl-8">

        <app-lms-progress></app-lms-progress>

        <div class="row">
            <div class="col-xxl-8">

                <app-lms-hours-spent></app-lms-hours-spent>

                <app-lms-instructors></app-lms-instructors>

            </div>
            <div class="col-xxl-4">

                <app-lms-planning></app-lms-planning>

                <app-lms-status></app-lms-status>

            </div>
        </div>
        
        <app-lms-current-courses></app-lms-current-courses>

        <app-lms-enrolled-created></app-lms-enrolled-created>
        

    </div>
    <div class="col-xxl-4">

        <app-lms-experience></app-lms-experience>

        <app-lms-active-course></app-lms-active-course>

        <app-lms-course-completion></app-lms-course-completion>

        <app-lms-messages></app-lms-messages>

        <app-lms-students></app-lms-students>

    </div>
</div>