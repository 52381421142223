<div class="create-dialog-box scrollable-dialog">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">Add New Task</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>
    <form>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label class="d-block fw-semibold">Task</label>
                    <input type="text" class="input-control" placeholder="Task *">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Member</label>
                    <input type="file" class="input-control">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Start Date</label>
                    <input type="date" class="input-control">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">End Date</label>
                    <input type="date" class="input-control">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Status</label>
                    <input type="text" class="input-control" placeholder="Status *">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Completion</label>
                    <input type="text" class="input-control" placeholder="0/10 *">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Priority</label>
                    <input type="text" class="input-control" placeholder="High/Medium/Low *">
                </div>
            </div>
        </div>
        <div class="text-end">
            <button type="button" class="gray" (click)="close()"><i class="ri-close-fill"></i> Cancel</button>
            <button type="submit"><i class="ri-add-fill"></i> Add New Task</button>
        </div>
    </form>
</div>