<div class="mat-elevation-8">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">
    
        <!-- Position Column -->
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
                No.
            </th>
            <td mat-cell *matCellDef="let element">{{element.position}}</td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
                Name
            </th>
            <td mat-cell *matCellDef="let element">{{element.name}}</td>
        </ng-container>
    
        <!-- Weight Column -->
        <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by weight">
                Weight
            </th>
            <td mat-cell *matCellDef="let element">{{element.weight}}</td>
        </ng-container>
    
        <!-- Symbol Column -->
        <ng-container matColumnDef="symbol">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
                Symbol
            </th>
            <td mat-cell *matCellDef="let element">{{element.symbol}}</td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>