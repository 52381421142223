<div class="chart">
    <apx-chart
        [series]="series!"
        [chart]="chart!"
        [dataLabels]="dataLabels!"
        [markers]="markers!"
        [title]="title!"
        [fill]="fill!"
        [yaxis]="yaxis!"
        [xaxis]="xaxis!"
        [grid]="grid!"
        [tooltip]="tooltip!"
        [autoUpdateSeries]="true!"
    ></apx-chart>
</div>