import { Component } from '@angular/core';

@Component({
  selector: 'app-disabled-datepicker',
  templateUrl: './disabled-datepicker.component.html',
  styleUrls: ['./disabled-datepicker.component.scss']
})
export class DisabledDatepickerComponent {

}
