<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Select</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">UI Kit</li>
        <li class="breadcrumb-item fw-semibold">Select</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-8 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Select</h5>
            </mat-card-header>
            <mat-card-content>
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <mat-form-field appearance="fill" class="example-full-width">
                            <mat-label>Favorite food</mat-label>
                            <mat-select>
                                <mat-option *ngFor="let food of foods" [value]="food.value">
                                {{food.viewValue}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <mat-form-field appearance="fill" class="example-full-width">
                            <mat-label>Cars</mat-label>
                            <select matNativeControl required>
                                <option value="volvo">Volvo</option>
                                <option value="saab">Saab</option>
                                <option value="mercedes">Mercedes</option>
                                <option value="audi">Audi</option>
                            </select>
                        </mat-form-field>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Select with Multiple Selection</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>Toppings</mat-label>
                    <mat-select [formControl]="toppings" multiple>
                        <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Select with Custom Trigger Text</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>Toppings</mat-label>
                    <mat-select [formControl]="toppings" multiple>
                        <mat-select-trigger>
                        {{toppings.value?.[0] || ''}}
                        <span *ngIf="(toppings.value?.length || 0) > 1" class="example-additional-selection">
                            (+{{(toppings.value?.length || 0) - 1}} {{toppings.value?.length === 2 ? 'other' : 'others'}})
                        </span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Auto Width Select</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field appearance="fill">
                    <mat-label>Favorite food</mat-label>
                    <mat-select>
                        <mat-option *ngFor="let food of foods" [value]="food.value">
                            {{food.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Disabled Select</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>Choose an option</mat-label>
                    <mat-select disabled="disableSelect">
                        <mat-option value="option1">Option 1</mat-option>
                        <mat-option value="option2" disabled>Option 2 (disabled)</mat-option>
                        <mat-option value="option3">Option 3</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </div>
</div>