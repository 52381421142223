<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Dialog</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">UI Kit</li>
        <li class="breadcrumb-item fw-semibold">Dialog</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Dialog</h5>
            </mat-card-header>
            <mat-card-content>
                <button mat-flat-button class="gray fw-semibold" (click)="openDialog()">Launch Dialog</button>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Menu Dialog</h5>
            </mat-card-header>
            <mat-card-content>
                <app-menu-dialog></app-menu-dialog>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Form Dialog</h5>
            </mat-card-header>
            <mat-card-content>
                <app-form-dialog></app-form-dialog>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Big Form Dialog</h5>
            </mat-card-header>
            <mat-card-content>
                <app-big-form-dialog></app-big-form-dialog>
            </mat-card-content>
        </mat-card>
    </div>
</div>