<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Timeline</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Timeline</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Timeline</h5>
    </mat-card-header>
    <mat-card-content>
        <ul class="ps-0 mb-0 mt-0 list-unstyled tagus-timeline">
            <li class="position-relative">
                <img src="assets/img/icon/right-arrow.png" alt="right-arrow" class="icon">
                <h5 class="mb-12">Eat</h5>
                <span class="d-block muted-color mb-12 fs-14">11:47 PM Wednesday</span>
                <p>Invoices have been paid to the company.</p>
            </li>
            <li class="position-relative">
                <img src="assets/img/icon/right-arrow.png" alt="right-arrow" class="icon">
                <h5 class="mb-12">Code</h5>
                <span class="d-block muted-color mb-12 fs-14">11:47 PM Wednesday</span>
                <p>Invoices have been paid to the company.</p>
            </li>
            <li class="position-relative">
                <img src="assets/img/icon/right-arrow.png" alt="right-arrow" class="icon">
                <h5 class="mb-12">Sleep</h5>
                <span class="d-block muted-color mb-12 fs-14">11:47 PM Wednesday</span>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </li>
            <li class="position-relative">
                <img src="assets/img/icon/right-arrow.png" alt="right-arrow" class="icon">
                <h5 class="mb-12">Repeat</h5>
                <span class="d-block muted-color mb-12 fs-14">11:47 PM Wednesday</span>
                <p>Invoices have been paid to the company.</p>
                <div class="d-flex mt-20 align-items-center">
                    <img src="assets/img/user/user8.jpg" class="border-radius me-10" width="50" alt="user">
                    <img src="assets/img/user/user9.jpg" class="border-radius me-10" width="50" alt="user">
                    <img src="assets/img/user/user10.jpg" class="border-radius me-10" width="50" alt="user">
                    <img src="assets/img/user/user11.jpg" class="border-radius me-10" width="50" alt="user">
                    <img src="assets/img/user/user12.jpg" class="border-radius me-10" width="50" alt="user">
                </div>
            </li>
        </ul>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Timeline</h5>
    </mat-card-header>
    <mat-card-content>
        <div class="timeline">
            <div class="event">
                <div class="inner">
                    <span class="date fw-semibold">
                        Jan
                    </span>
                    <h5 class="mb-12">First Event</h5>
                    <span class="d-block muted-color mb-12 fs-14">11:47 PM Wednesday</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis rutrum nunc, eget dictum massa. Nam faucibus felis nec augue adipiscing, eget commodo libero mattis.</p>
                </div>
            </div>
            <div class="event">
                <div class="inner">
                    <span class="date fw-semibold">
                        Feb
                    </span>
                    <h5 class="mb-12">First Event</h5>
                    <span class="d-block muted-color mb-12 fs-14">11:47 PM Wednesday</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis rutrum nunc, eget dictum massa. Nam faucibus felis nec augue adipiscing, eget commodo libero mattis.</p>
                </div>
            </div>
            <div class="event">
                <div class="inner">
                    <span class="date fw-semibold">
                        Mar
                    </span>
                    <h5 class="mb-12">First Event</h5>
                    <span class="d-block muted-color mb-12 fs-14">11:47 PM Wednesday</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis rutrum nunc, eget dictum massa. Nam faucibus felis nec augue adipiscing, eget commodo libero mattis.</p>
                    <div class="d-flex mt-20 align-items-center">
                        <img src="assets/img/user/user8.jpg" class="border-radius me-10" width="50" alt="user">
                        <img src="assets/img/user/user9.jpg" class="border-radius me-10" width="50" alt="user">
                        <img src="assets/img/user/user10.jpg" class="border-radius me-10" width="50" alt="user">
                        <img src="assets/img/user/user11.jpg" class="border-radius me-10" width="50" alt="user">
                        <img src="assets/img/user/user12.jpg" class="border-radius me-10" width="50" alt="user">
                    </div>
                </div>
            </div>
            <div class="event">
                <div class="inner">
                    <span class="date fw-semibold">
                        Apr
                    </span>
                    <h5 class="mb-12">First Event</h5>
                    <span class="d-block muted-color mb-12 fs-14">11:47 PM Wednesday</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis rutrum nunc, eget dictum massa. Nam faucibus felis nec augue adipiscing, eget commodo libero mattis.</p>
                    <div class="d-flex mt-20 align-items-center">
                        <img src="assets/img/user/user8.jpg" class="border-radius me-10" width="50" alt="user">
                        <img src="assets/img/user/user9.jpg" class="border-radius me-10" width="50" alt="user">
                        <img src="assets/img/user/user10.jpg" class="border-radius me-10" width="50" alt="user">
                        <img src="assets/img/user/user11.jpg" class="border-radius me-10" width="50" alt="user">
                        <img src="assets/img/user/user12.jpg" class="border-radius me-10" width="50" alt="user">
                    </div>
                </div>
            </div>
            <div class="event">
                <div class="inner">
                    <span class="date fw-semibold">
                        Jun
                    </span>
                    <h5 class="mb-12">First Event</h5>
                    <span class="d-block muted-color mb-12 fs-14">11:47 PM Wednesday</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis rutrum nunc, eget dictum massa. Nam faucibus felis nec augue adipiscing, eget commodo libero mattis.</p>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>