<div class="chart">
    <apx-chart
        [series]="chartOptions.series!"
        [chart]="chartOptions.chart!"
        [labels]="chartOptions.labels!"
        [title]="chartOptions.title!"
        [tooltip]="chartOptions.tooltip!"
        [legend]="chartOptions.legend!"
        [dataLabels]="chartOptions.dataLabels!"
        [stroke]="chartOptions.stroke!"
        [colors]="chartOptions.colors!"
        [theme]="chartOptions.theme!"
        [responsive]="chartOptions.responsive!"
    ></apx-chart>
</div>