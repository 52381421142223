<div class="confirmation-modal">
    <div class="confirmation-modal-container">
        <p>Підтверджуєте дію?</p>
        <button mat-raised-button class="tagus" style="width: 100%" (click)="confirm()">Так</button>
        <hr/>
        <button mat-stroked-button style="width: 100%" (click)="cancel()">Ні</button>
    </div>
</div>

<div class="confirmation-modal-background" (click)="cancel()"></div>
