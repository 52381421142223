import { Component } from '@angular/core';

@Component({
  selector: 'app-dp-action-button',
  templateUrl: './dp-action-button.component.html',
  styleUrls: ['./dp-action-button.component.scss']
})
export class DpActionButtonComponent {

}
