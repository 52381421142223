<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Users</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Projects</li>
        <li class="breadcrumb-item fw-semibold">Users</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-4 col-md-6 col-sm-6 col-xl-4 col-xxl-4">
        <app-pt-overview></app-pt-overview>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-6 col-xl-4 col-xxl-4">
        <app-pt-completed></app-pt-completed>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-6 col-xl-4 col-xxl-4">
        <app-pt-performance></app-pt-performance>
    </div>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header>
        <h5 class="mb-0">My Tasks</h5>
        <button type="button" mat-button class="position-relative btn bg-transparent border-none" [matMenuTriggerFor]="monthMenu">
            This Week
        </button>
        <mat-menu #monthMenu="matMenu" class="monthMenu" xPosition="before">
            <button mat-menu-item type="button" class="bg-transparent border-none">This Month</button>
            <button mat-menu-item type="button" class="bg-transparent border-none">This Year</button>
            <button mat-menu-item type="button" class="bg-transparent border-none">All Time</button>
        </mat-menu>
    </mat-card-header>
    <mat-card-content>
        <div class="myTasksTable tagus-mat-table mat-elevation-z8">
            <table mat-table [dataSource]="dataSource">

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Name</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        <div class="d-flex align-items-center name-info">
                            <mat-checkbox></mat-checkbox>
                            {{element.name}}
                        </div>
                    </td>
                </ng-container>

                <!-- Deadline Column -->
                <ng-container matColumnDef="deadline">
                    <th mat-header-cell *matHeaderCellDef>Deadline</th>
                    <td mat-cell *matCellDef="let element">{{element.deadline}}</td>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="badge fw-medium" *ngIf="element.status.active">{{element.status.active}}</span>
                        <span class="badge pending fw-medium" *ngIf="element.status.pending">{{element.status.pending}}</span>
                        <span class="badge completed fw-medium" *ngIf="element.status.completed">{{element.status.completed}}</span>
                    </td>
                </ng-container>

                <!-- Assignee Column -->
                <ng-container matColumnDef="assignee">
                    <th mat-header-cell *matHeaderCellDef>Assignee</th>
                    <td mat-cell *matCellDef="let element">
                        <img [src]="element.assignee.img" matTooltip="{{element.assignee.name}}" class="rounded-circle" alt="icon">
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator
                [pageSizeOptions]="[7, 9, 11, 14]"
                showFirstLastButtons
            >
            </mat-paginator>
        </div>
    </mat-card-content>
</mat-card>