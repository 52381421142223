import { FormControl } from '@angular/forms';

export function priceValidator(control: FormControl): { [key: string]: any } | null {
  // регулярное выражение для проверки цены до десятых
  const pricePattern = /^[0-9]+(\.[0-9]{1,2})?$/;

  if (control.value && !pricePattern.test(control.value)) {
    // возвращаем ошибку, если значение не соответствует шаблону
    return { 'invalidPrice': true };
  }

  // валидация успешна
  return null;
}
