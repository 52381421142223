<div class="row">
    <div class="col-md-4">
        <mat-card class="single-stats-box mb-25 tagus-card">
            <div class="d-flex align-items-center">
                <div class="icon">
                    <i class="flaticon-donut-chart"></i>
                </div>
                <div class="title">
                    <span class="d-block gray-color">Total Projects</span>
                    <h3>24K</h3>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="col-md-4">
        <mat-card class="single-stats-box mb-25 tagus-card">
            <div class="d-flex align-items-center">
                <div class="icon">
                    <i class="flaticon-goal"></i>
                </div>
                <div class="title">
                    <span class="d-block gray-color">Pending Projects</span>
                    <h3>155K</h3>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="col-md-4">
        <mat-card class="single-stats-box mb-25 tagus-card">
            <div class="d-flex align-items-center">
                <div class="icon">
                    <i class="flaticon-award"></i>
                </div>
                <div class="title">
                    <span class="d-block gray-color">Total Revenue</span>
                    <h3>$50.5M</h3>
                </div>
            </div>
        </mat-card>
    </div>
</div>