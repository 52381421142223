import {Component} from '@angular/core';
import { ToggleService } from '../header/toggle.service';
import { CustomizerSettingsService } from '../../customizer-settings/customizer-settings.service';
import {PageService} from "../../../services/page.service";
import {MarketResolver} from "../../../resolvers/market.resolver";

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
    currentPage: string = '';
    market: any;
    panelOpenState = false;
    isToggled = false;

    constructor(
        public pageService: PageService,
        private toggleService: ToggleService,
        private marketResolver: MarketResolver,
        public themeService: CustomizerSettingsService,
    ) {
        this.toggleService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
        this.pageService.currentPage$.subscribe(currentPage => {
            this.currentPage = currentPage;
        });
        this.marketResolver.market$.subscribe(market => {
            this.market = market;
        });
    }

    isURLActive(expectedURL: string) {
        return this.currentPage && this.currentPage.startsWith(expectedURL);
    }

    toggle() {
        this.toggleService.toggle();
    }

    toggleSidebarTheme() {
        this.themeService.toggleSidebarTheme();
    }

    toggleHideSidebarTheme() {
        this.themeService.toggleHideSidebarTheme();
    }

}
