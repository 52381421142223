<mat-card
    class="mb-25 tagus-card"
    [class.component-dark-theme]="themeService.isDark()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Settings</h5>
    </mat-card-header>
    <mat-card-content>
        <nav>
            <ul class="ps-0 mt-0">
                <li>
                    <a routerLink="/account" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Account</a>
                </li>
                <li>
                    <a routerLink="/security" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Security</a>
                </li>
                <li>
                    <a routerLink="/connections" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Connections</a>
                </li>
                <li>
                    <a routerLink="/privacy-policy" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Privacy Policy</a>
                </li>
                <li>
                    <a routerLink="/terms-conditions" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a>
                </li>
            </ul>
        </nav>
        <form>
            <h5 class="fw-semibold mb-8">Security</h5>
            <p>Update your password from here.</p>
            <div class="row mt-20">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Old Password</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-lock-line"></i>
                            <mat-label>Type password</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'">
                            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">New Password</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-lock-line"></i>
                            <mat-label>Type password</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'">
                            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">New Password</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-lock-line"></i>
                            <mat-label>Type password</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'">
                            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-space-between">
                <button mat-raised-button class="tagus fw-semibold">Change Password</button>
                <a routerLink="/forgot-password" class="d-inline-block main-color fw-semibold">Forgot your password?</a>
            </div>
        </form>
    </mat-card-content>
</mat-card>

<div class="row" [class.component-rtl-enabled]="themeService.isRTLEnabled()">
    <div class="col-lg-6 col-md-12">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Login History</h5>
                <a routerLink="." class="gray-color fw-semibold main-hover-color">All Logout</a>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex mb-20 align-items-center justify-content-space-between">
                    <div class="d-flex align-items-center">
                        <mat-checkbox></mat-checkbox>
                        <div class="ms-5">
                            <span class="d-block mb-5 fw-semibold">iPhone 12 Pro</span>
                            <span class="d-block gray-color">Los Angeles, United States - March 16 at 2:47PM</span>
                        </div>
                    </div>
                    <a routerLink="." class="gray-color fw-semibold main-hover-color">Logout</a>
                </div>
                <div class="d-flex align-items-center justify-content-space-between">
                    <div class="d-flex align-items-center">
                        <mat-checkbox></mat-checkbox>
                        <div class="ms-5">
                            <span class="d-block mb-5 fw-semibold">HP Windows 10</span>
                            <span class="d-block gray-color">Boston Angeles, United States - March 16 at 5:30AM</span>
                        </div>
                    </div>
                    <a routerLink="." class="gray-color fw-semibold main-hover-color">Logout</a>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>