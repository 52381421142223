<div
    class="h-100vh pt-50 pb-50"
    [class.component-dark-theme]="themeService.isDark()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.card-border-radius]="themeService.isCardBorderRadius()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="tagus-form ms-auto me-auto">
                <form [formGroup]="form" (submit)="onFormSubmit(form)">
                    <div class="bg-white border-radius pt-20 ps-20 pe-20">
                        <app-local-error></app-local-error>
                        <div class="tagus-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold gray-color">Название <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <input formControlName="name" matInput>
                            </mat-form-field>
                            <!-- валидация -->
                            <ng-container *ngIf="(formWasSubmitted && form.controls['name'].invalid) || (form.controls['name'].invalid && form.controls['name'].touched)">
                                <div class="alert alert-danger" role="alert">
                                    <ng-container *ngIf="form.controls['name'].errors?.['required']">
                                        Обязательное поле
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['name'].errors?.['minlength']">
                                        Поле может содержать минимум 2 символа
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['name'].errors?.['maxlength']">
                                        Поле может содержать максимум 40 символов
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <button mat-flat-button class="tagus d-block fw-semibold">Сохранить</button>
                </form>
            </div>
        </div>
    </div>
</div>
