import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {Router} from "@angular/router";
import {ApiService} from "../../../../services/api.service";
import {MarketResolver} from "../../../../resolvers/market.resolver";
import {ErrorService} from "../../../common/notifications/errors/error.service";
import {SuccessService} from "../../../common/notifications/success/success.service";
import {ErrorTypeEnum} from "../../../../enums/error-type.enum";
import {EndpointTypeEnum} from "../../../../enums/endpoint-type.enum";
import {DEFAULT_MESSAGES} from "../../../../constants/default-messages";
import {environment} from "../../../../../environments/environment";

@Component({
    selector: 'app-market-xml',
    templateUrl: './market-xml.component.html',
    styleUrl: './market-xml.component.scss'
})
// легаси
export class MarketXmlComponent implements OnInit, OnDestroy, AfterViewInit {
    selectedCategories: string[] = [];
    categoriesForDropdown: any;
    market: any;
    displayedColumns: string[] = ['name', 'category', 'price', 'stock', 'action'];
    ELEMENT_DATA: PeriodicElement[] = [];
    dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
    products: any[] = [];
    showConfirmation = false;
    selectedCategoryId: any;
    isLoading = false
    googleMerchant: any
    selectedLanguage: string = 'ua'

    @ViewChild(MatPaginator) paginator: MatPaginator;
    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    constructor(
        private router: Router,
        private apiService: ApiService,
        private marketResolver: MarketResolver,
        private errorService: ErrorService,
        private successService: SuccessService,
    ) {
        this.marketResolver.market$.subscribe(market => {
            this.market = market;
        });

        this.getCategoriesForDropdown();
        this.getGoogleMerchant()
    }

    ngOnInit() {
        this.load();
    }

    ngOnDestroy() {
        this.successService.clear(ErrorTypeEnum.Global);
        this.successService.clear(ErrorTypeEnum.Local);
        this.errorService.clearErrors(ErrorTypeEnum.Global);
        this.errorService.clearErrors(ErrorTypeEnum.Local);
    }

    load() {
        this.apiService
            .get(EndpointTypeEnum.Market, '/products', true, null, { 'market_id': this.market._id })
            .subscribe({
                next: (response: any) => {
                    // присваиваем данные переменной ELEMENT_DATA
                    // this.ELEMENT_DATA = response.data;
                    // обновляем данные в MatTableDataSource
                    // this.dataSource.data = this.ELEMENT_DATA;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    remove(productId: string) {
        this.showConfirmation = true;
        this.selectedCategoryId = productId;
    }

    onConfirmationRemove(confirmed: boolean) {
        if (confirmed) {
            this.apiService
                .delete(EndpointTypeEnum.Market, '/products', true, this.selectedCategoryId)
                .subscribe({
                    next: (response: any) => {
                        this.load();
                        this.successService.add(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.SUCCESS]);
                    },
                    error: (err) => {
                        this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                    },
                });
            this.showConfirmation = false;
        } else {
            this.showConfirmation = false;
            this.selectedCategoryId = null;
        }
    }

    updateSelectedCategories(categoryId: string): void {
        if (this.isSelectedCategories(categoryId)) {
            this.selectedCategories = this.selectedCategories.filter(id => id !== categoryId);
        } else {
            this.selectedCategories.push(categoryId);
        }
    }

    // -- логика для: categories --
    // чекбокс для отображения выбранных категорий
    isSelectedCategories(categoryId: string): boolean {
        return this.selectedCategories.includes(categoryId);
    }

    getCategoriesForDropdown(): void {
        this.apiService
            .get(EndpointTypeEnum.DropdownLists, '/categories', true)
            .subscribe({
                next: (response: any) => {
                    this.categoriesForDropdown = response.data;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    getGoogleMerchant(): void {
        this.apiService
            .get(EndpointTypeEnum.Market, `/google-merchant`, true, this.market._id)
            .subscribe({
                next: (response: any) => {
                    this.googleMerchant = response.data;
                    this.selectedCategories = response.data.categories
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    generate(){
        this.isLoading = true;

        this.apiService
            .get(EndpointTypeEnum.Market, '/products/feed', true, null, { 'market_id': this.market._id, 'categories': this.selectedCategories})
            .subscribe({
                next: (response: any) => {
                    this.isLoading = false
                    // присваиваем данные переменной ELEMENT_DATA
                    // this.ELEMENT_DATA = response.data;
                    // обновляем данные в MatTableDataSource
                    // this.dataSource.data = this.ELEMENT_DATA;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });

        if(this.googleMerchant){
            this.apiService
                .put(EndpointTypeEnum.Market, '/google-merchant', true, { 'market': this.market._id, 'name': 'Google', 'categories': this.selectedCategories}, this.googleMerchant._id)
                .subscribe({
                    next: (response: any) => {
                        this.successService.add(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.SUCCESS]);
                    },
                    error: (err: any) => {
                        this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                    },
                });
        } else {
            this.apiService
                .post(EndpointTypeEnum.Market, '/google-merchant', true,  { 'market': this.market._id, 'name': 'Google', 'categories': this.selectedCategories})
                .subscribe({
                    next: (response: any) => {
                        // this.isLoading = false
                        // присваиваем данные переменной ELEMENT_DATA
                        // this.ELEMENT_DATA = response.data;
                        // обновляем данные в MatTableDataSource
                        // this.dataSource.data = this.ELEMENT_DATA;
                    },
                    error: (err) => {
                        this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                    },
                });
        }
    }

    getFeedURL(lng: string) {
        const baseUrl = environment.backendUrl;
        if(lng === 'ua'){
            return `${baseUrl}/feed/google_feed_ua.xml`;
        } else {
            return `${baseUrl}/feed/google_feed_ru.xml`;
        }
    }
}

export interface PeriodicElement {
    name_ua: any;
    main_category: any;
    amount: any;
}
