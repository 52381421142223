<div class="row">
    <div class="col-lg-6 col-md-12">
        <mat-form-field color="accent" appearance="fill" class="example-full-width">
            <mat-label>Inherited calendar color</mat-label>
            <input matInput [matDatepicker]="picker1">
            <mat-hint class="mt-5">MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-lg-6 col-md-12">
        <mat-form-field color="accent" appearance="fill" class="example-full-width">
            <mat-label>Custom calendar color</mat-label>
            <input matInput [matDatepicker]="picker2">
            <mat-hint class="mt-5">MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 color="primary"></mat-datepicker>
        </mat-form-field>
    </div>
</div>