<mat-card class="mb-25 tagus-card sales-by-locations">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Sales by Locations</h5>
        <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="monthMenu">
            <i class="ri-more-fill"></i>
        </button>
        <mat-menu #monthMenu="matMenu" class="monthMenu" xPosition="before">
            <button mat-menu-item type="button" class="bg-transparent border-none">Today</button>
            <button mat-menu-item type="button" class="bg-transparent border-none">Last Month</button>
            <button mat-menu-item type="button" class="bg-transparent border-none">Last Year</button>
            <button mat-menu-item type="button" class="bg-transparent border-none">All Time</button>
        </mat-menu>
    </mat-card-header>
    <mat-card-content>
        <div class="text-center">
            <img src="assets/img/hex-map.png" alt="hex-map">
        </div>
        <ul class="m-0 p-0">
            <li>
                <div class="d-flex align-items-center justify-content-space-between">
                    <span class="gray-color fw-semibold">Canada</span>
                    <span class="gray-color fw-semibold">75%</span>
                </div>
                <mat-progress-bar mode="determinate" value="75"></mat-progress-bar>
            </li>
            <li>
                <div class="d-flex align-items-center justify-content-space-between">
                    <span class="gray-color fw-semibold">Russia</span>
                    <span class="gray-color fw-semibold">65%</span>
                </div>
                <mat-progress-bar mode="determinate" value="65"></mat-progress-bar>
            </li>
            <li>
                <div class="d-flex align-items-center justify-content-space-between">
                    <span class="gray-color fw-semibold">Greenland</span>
                    <span class="gray-color fw-semibold">50%</span>
                </div>
                <mat-progress-bar mode="determinate" value="50"></mat-progress-bar>
            </li>
            <li>
                <div class="d-flex align-items-center justify-content-space-between">
                    <span class="gray-color fw-semibold">USA</span>
                    <span class="gray-color fw-semibold">40%</span>
                </div>
                <mat-progress-bar mode="determinate" value="40"></mat-progress-bar>
            </li>
            <li>
                <div class="d-flex align-items-center justify-content-space-between">
                    <span class="gray-color fw-semibold">Japan</span>
                    <span class="gray-color fw-semibold">30%</span>
                </div>
                <mat-progress-bar mode="determinate" value="40"></mat-progress-bar>
            </li>
        </ul>
    </mat-card-content>
</mat-card>