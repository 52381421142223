<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Customers</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Products</li>
        <li class="breadcrumb-item fw-semibold">Customers</li>
    </ol>
</div>

<mat-card
    class="mb-25 tagus-card"
    [class.component-dark-theme]="themeService.isDark()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <h5 class="mb-0">Customers List</h5>
        <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="monthMenu">
            <i class="ri-more-fill"></i>
        </button>
        <mat-menu #monthMenu="matMenu" class="monthMenu" xPosition="before">
            <button mat-menu-item type="button" class="bg-transparent border-none">Today</button>
            <button mat-menu-item type="button" class="bg-transparent border-none">Last Month</button>
            <button mat-menu-item type="button" class="bg-transparent border-none">Last Year</button>
            <button mat-menu-item type="button" class="bg-transparent border-none">All Time</button>
        </mat-menu>
    </mat-card-header>
    <mat-card-content>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th class="fw-semibold main-color">User Name</th>
                        <th class="fw-semibold gray-color">Email</th>
                        <th class="fw-semibold gray-color">Phone</th>
                        <th class="fw-semibold gray-color">Balance</th>
                        <th class="fw-semibold gray-color">Orders</th>
                        <th class="fw-semibold gray-color">Status</th>
                        <th class="fw-semibold gray-color">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div class="user-info d-flex align-items-center">
                                <mat-checkbox class="fm-checkbox"></mat-checkbox>
                                <img src="assets/img/user/user12.jpg" class="rounded-circle" alt="user">
                                <div class="title">
                                    <h5 class="fw-semibold mb-5">Evangelina Mcclain</h5>
                                    <span class="d-block muted-color">&#64;evangelinamcclain</span>
                                </div>
                            </div>
                        </td>
                        <td class="gray-color">
                            <a href="mailto:evangelinamcclain&#64;tagus.com" class="gray-color">
                                evangelinamcclain&#64;tagus.com
                            </a>
                        </td>
                        <td class="gray-color">
                            <a href="tel:001833546822" class="gray-color">
                                0018 3354 6822
                            </a>
                        </td>
                        <td class="gray-color">$3522.12</td>
                        <td class="gray-color">165</td>
                        <td class="gray-color">
                            <span class="badge fw-medium" *ngIf="active">Active</span>
                        </td>
                        <td>
                            <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                                <i class="ri-more-fill"></i>
                            </button>
                            <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-share-line"></i> Share
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-link"></i> Get Sharable Link
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-edit-line"></i> Rename
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-delete-bin-line"></i> Remove
                                </button>
                            </mat-menu>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="user-info d-flex align-items-center">
                                <mat-checkbox class="fm-checkbox"></mat-checkbox>
                                <img src="assets/img/user/user13.jpg" class="rounded-circle" alt="user">
                                <div class="title">
                                    <h5 class="fw-semibold mb-5">Candice Munoz</h5>
                                    <span class="d-block muted-color">&#64;candicemunoz</span>
                                </div>
                            </div>
                        </td>
                        <td class="gray-color">
                            <a href="mailto:candicemunoz&#64;tagus.com" class="gray-color">
                                candicemunoz&#64;tagus.com
                            </a>
                        </td>
                        <td class="gray-color">
                            <a href="tel:001833546822" class="gray-color">
                                0018 3354 6822
                            </a>
                        </td>
                        <td class="gray-color">$3522.12</td>
                        <td class="gray-color">165</td>
                        <td class="gray-color">
                            <span class="badge fw-medium blocked" *ngIf="blocked">Blocked</span>
                        </td>
                        <td>
                            <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                                <i class="ri-more-fill"></i>
                            </button>
                            <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-share-line"></i> Share
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-link"></i> Get Sharable Link
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-edit-line"></i> Rename
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-delete-bin-line"></i> Remove
                                </button>
                            </mat-menu>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="user-info d-flex align-items-center">
                                <mat-checkbox class="fm-checkbox"></mat-checkbox>
                                <img src="assets/img/user/user11.jpg" class="rounded-circle" alt="user">
                                <div class="title">
                                    <h5 class="fw-semibold mb-5">Bernard Langley</h5>
                                    <span class="d-block muted-color">&#64;bernardlangley</span>
                                </div>
                            </div>
                        </td>
                        <td class="gray-color">
                            <a href="mailto:bernardlangley&#64;tagus.com" class="gray-color">
                                bernardlangley&#64;tagus.com
                            </a>
                        </td>
                        <td class="gray-color">
                            <a href="tel:001833546822" class="gray-color">
                                0018 3354 6822
                            </a>
                        </td>
                        <td class="gray-color">$3522.12</td>
                        <td class="gray-color">165</td>
                        <td class="gray-color">
                            <span class="badge fw-medium" *ngIf="active">Active</span>
                        </td>
                        <td>
                            <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                                <i class="ri-more-fill"></i>
                            </button>
                            <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-share-line"></i> Share
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-link"></i> Get Sharable Link
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-edit-line"></i> Rename
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-delete-bin-line"></i> Remove
                                </button>
                            </mat-menu>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="user-info d-flex align-items-center">
                                <mat-checkbox class="fm-checkbox"></mat-checkbox>
                                <img src="assets/img/user/user14.jpg" class="rounded-circle" alt="user">
                                <div class="title">
                                    <h5 class="fw-semibold mb-5">Kristie Hall</h5>
                                    <span class="d-block muted-color">&#64;kristiehall</span>
                                </div>
                            </div>
                        </td>
                        <td class="gray-color">
                            <a href="mailto:kristiehall&#64;tagus.com" class="gray-color">
                                kristiehall&#64;tagus.com
                            </a>
                        </td>
                        <td class="gray-color">
                            <a href="tel:001833546822" class="gray-color">
                                0018 3354 6822
                            </a>
                        </td>
                        <td class="gray-color">$3522.12</td>
                        <td class="gray-color">165</td>
                        <td class="gray-color">
                            <span class="badge fw-medium blocked" *ngIf="blocked">Blocked</span>
                        </td>
                        <td>
                            <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                                <i class="ri-more-fill"></i>
                            </button>
                            <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-share-line"></i> Share
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-link"></i> Get Sharable Link
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-edit-line"></i> Rename
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-delete-bin-line"></i> Remove
                                </button>
                            </mat-menu>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="user-info d-flex align-items-center">
                                <mat-checkbox class="fm-checkbox"></mat-checkbox>
                                <img src="assets/img/user/user8.jpg" class="rounded-circle" alt="user">
                                <div class="title">
                                    <h5 class="fw-semibold mb-5">Bolton Obrien</h5>
                                    <span class="d-block muted-color">&#64;boltonobrien</span>
                                </div>
                            </div>
                        </td>
                        <td class="gray-color">
                            <a href="mailto:boltonobrien&#64;tagus.com" class="gray-color">
                                boltonobrien&#64;tagus.com
                            </a>
                        </td>
                        <td class="gray-color">
                            <a href="tel:001833546822" class="gray-color">
                                0018 3354 6822
                            </a>
                        </td>
                        <td class="gray-color">$3522.12</td>
                        <td class="gray-color">165</td>
                        <td class="gray-color">
                            <span class="badge fw-medium" *ngIf="active">Active</span>
                        </td>
                        <td>
                            <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                                <i class="ri-more-fill"></i>
                            </button>
                            <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-share-line"></i> Share
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-link"></i> Get Sharable Link
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-edit-line"></i> Rename
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-delete-bin-line"></i> Remove
                                </button>
                            </mat-menu>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="user-info d-flex align-items-center">
                                <mat-checkbox class="fm-checkbox"></mat-checkbox>
                                <img src="assets/img/user/user18.jpg" class="rounded-circle" alt="user">
                                <div class="title">
                                    <h5 class="fw-semibold mb-5">Dee Alvarado</h5>
                                    <span class="d-block muted-color">&#64;deealvarado</span>
                                </div>
                            </div>
                        </td>
                        <td class="gray-color">
                            <a href="mailto:deealvarado&#64;tagus.com" class="gray-color">
                                deealvarado&#64;tagus.com
                            </a>
                        </td>
                        <td class="gray-color">
                            <a href="tel:001833546822" class="gray-color">
                                0018 3354 6822
                            </a>
                        </td>
                        <td class="gray-color">$3522.12</td>
                        <td class="gray-color">165</td>
                        <td class="gray-color">
                            <span class="badge fw-medium" *ngIf="active">Active</span>
                        </td>
                        <td>
                            <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                                <i class="ri-more-fill"></i>
                            </button>
                            <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-share-line"></i> Share
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-link"></i> Get Sharable Link
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-edit-line"></i> Rename
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-delete-bin-line"></i> Remove
                                </button>
                            </mat-menu>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="user-info d-flex align-items-center">
                                <mat-checkbox class="fm-checkbox"></mat-checkbox>
                                <img src="assets/img/user/user22.jpg" class="rounded-circle" alt="user">
                                <div class="title">
                                    <h5 class="fw-semibold mb-5">Cervantes Kramer</h5>
                                    <span class="d-block muted-color">&#64;cervanteskramer</span>
                                </div>
                            </div>
                        </td>
                        <td class="gray-color">
                            <a href="mailto:cervanteskramer&#64;tagus.com" class="gray-color">
                                cervanteskramer&#64;tagus.com
                            </a>
                        </td>
                        <td class="gray-color">
                            <a href="tel:001833546822" class="gray-color">
                                0018 3354 6822
                            </a>
                        </td>
                        <td class="gray-color">$3522.12</td>
                        <td class="gray-color">165</td>
                        <td class="gray-color">
                            <span class="badge fw-medium blocked" *ngIf="blocked">Blocked</span>
                        </td>
                        <td>
                            <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                                <i class="ri-more-fill"></i>
                            </button>
                            <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-share-line"></i> Share
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-link"></i> Get Sharable Link
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-edit-line"></i> Rename
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-delete-bin-line"></i> Remove
                                </button>
                            </mat-menu>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="user-info d-flex align-items-center">
                                <mat-checkbox class="fm-checkbox"></mat-checkbox>
                                <img src="assets/img/user/user16.jpg" class="rounded-circle" alt="user">
                                <div class="title">
                                    <h5 class="fw-semibold mb-5">Dejesus Michael</h5>
                                    <span class="d-block muted-color">&#64;dejesusmichael</span>
                                </div>
                            </div>
                        </td>
                        <td class="gray-color">
                            <a href="mailto:dejesusmichael&#64;tagus.com" class="gray-color">
                                dejesusmichael&#64;tagus.com
                            </a>
                        </td>
                        <td class="gray-color">
                            <a href="tel:001833546822" class="gray-color">
                                0018 3354 6822
                            </a>
                        </td>
                        <td class="gray-color">$3522.12</td>
                        <td class="gray-color">165</td>
                        <td class="gray-color">
                            <span class="badge fw-medium" *ngIf="active">Active</span>
                        </td>
                        <td>
                            <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                                <i class="ri-more-fill"></i>
                            </button>
                            <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-share-line"></i> Share
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-link"></i> Get Sharable Link
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-edit-line"></i> Rename
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-delete-bin-line"></i> Remove
                                </button>
                            </mat-menu>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="user-info d-flex align-items-center">
                                <mat-checkbox class="fm-checkbox"></mat-checkbox>
                                <img src="assets/img/user/user15.jpg" class="rounded-circle" alt="user">
                                <div class="title">
                                    <h5 class="fw-semibold mb-5">Alissa Nelson</h5>
                                    <span class="d-block muted-color">&#64;alissanelson</span>
                                </div>
                            </div>
                        </td>
                        <td class="gray-color">
                            <a href="mailto:alissanelson&#64;tagus.com" class="gray-color">
                                alissanelson&#64;tagus.com
                            </a>
                        </td>
                        <td class="gray-color">
                            <a href="tel:001833546822" class="gray-color">
                                0018 3354 6822
                            </a>
                        </td>
                        <td class="gray-color">$3522.12</td>
                        <td class="gray-color">165</td>
                        <td class="gray-color">
                            <span class="badge fw-medium" *ngIf="active">Active</span>
                        </td>
                        <td>
                            <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                                <i class="ri-more-fill"></i>
                            </button>
                            <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-share-line"></i> Share
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-link"></i> Get Sharable Link
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-edit-line"></i> Rename
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-delete-bin-line"></i> Remove
                                </button>
                            </mat-menu>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </mat-card-content>
</mat-card>