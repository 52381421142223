import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ErrorService} from "../../../common/notifications/errors/error.service";
import {Router} from "@angular/router";
import {ErrorTypeEnum} from "../../../../enums/error-type.enum";
import {SuccessService} from "../../../common/notifications/success/success.service";
import {DEFAULT_MESSAGES} from "../../../../constants/default-messages";
import {ApiService} from "../../../../services/api.service";
import {EndpointTypeEnum} from "../../../../enums/endpoint-type.enum";

@Component({
  selector: 'app-main-attribute-create',
  templateUrl: './main-attribute-create.component.html',
  styleUrl: './main-attribute-create.component.scss'
})
export class MainAttributeCreateComponent {
    form!: FormGroup;
    formWasSubmitted = false;

    constructor(
        private router: Router,
        private apiService: ApiService,
        private formBuilder: FormBuilder,
        private errorService: ErrorService,
        private successService: SuccessService,
    ) {
        this.initForm();
    }

    // инициализация формы
    initForm(): void {
        // инициализируем поля из формы и валидируем
        this.form = this.formBuilder.group({
            name_ru: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
            name_ua: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
        });
    }

    // отправка формы
    onFormSubmit(form: FormGroup): void {
        this.formWasSubmitted = true;

        // если есть ошибки валидации, не даем отправить форму
        if (form.invalid) {
            return;
        }

        // отправка формы
        this.apiService
            .post(EndpointTypeEnum.Main, '/attributes', true, form.value)
            .subscribe({
                next: (response: any) => {
                    this.router.navigate(['/main/catalog/attributes']);
                    this.successService.add(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.SUCCESS]);
                },
                error: (err: any) => {
                    this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                },
            });
    }
}
