<div class="row">
    <div class="col-lg-4 col-md-6">
        <mat-form-field appearance="fill" class="example-full-width">
            <mat-label>Angular forms</mat-label>
            <input matInput [matDatepicker]="picker1" [formControl]="date">
            <mat-hint class="mt-5">MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-form-field appearance="fill" class="example-full-width">
            <mat-label>Angular forms (w/ deserialization)</mat-label>
            <input matInput [matDatepicker]="picker2" [formControl]="serializedDate">
            <mat-hint class="mt-5">MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-form-field appearance="fill" class="example-full-width">
            <mat-label>Value binding</mat-label>
            <input matInput [matDatepicker]="picker3" [value]="date.value">
            <mat-hint class="mt-5">MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
        </mat-form-field>
    </div>
</div>