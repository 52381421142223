<mat-card class="mb-25 terminals-box tagus-card" [class.component-dark-theme]="themeService.isDark()">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Terminals</h5>
        <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="monthMenu">
            <i class="ri-more-fill"></i>
        </button>
        <mat-menu #monthMenu="matMenu" class="dotMenu monthMenu" xPosition="before">
            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                <i class="ri-add-circle-line"></i> Add
            </button>
            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                <i class="ri-delete-bin-6-line"></i> Delete
            </button>
            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                <i class="ri-restart-line"></i> Refresh
            </button>
        </mat-menu>
    </mat-card-header>
    <mat-card-content>
        <div class="terminals-list position-relative">
            <div class="terminal-box position-relative rounded-circle text-center">
                <div class="text">
                    <h4>$27632</h4>
                    <span>Monthly Earning</span>
                </div>
            </div>
            <div class="terminal-box position-relative rounded-circle text-center">
                <div class="text">
                    <h4>82.9k</h4>
                    <span>Users</span>
                </div>
            </div>
            <div class="terminal-box position-relative rounded-circle text-center">
                <div class="text">
                    <h4>0.9k</h4>
                    <span>Inactive</span>
                </div>
            </div>
        </div>
        <ul class="list text-center ps-0 mb-0">
            <li class="position-relative gray-color">Monthly Earnings</li>
            <li class="position-relative gray-color">Users</li>
            <li class="position-relative gray-color">Inactive</li>
        </ul>
    </mat-card-content>
</mat-card>