<div class="row">
    <div class="col-xxl-4">

        <app-welcome-dashboard></app-welcome-dashboard>

        <app-analytics-status></app-analytics-status>

    </div>
    <div class="col-xxl-8">

        <app-analytics-stats></app-analytics-stats>

        <app-analytics-audience-overview></app-analytics-audience-overview>
        
    </div>
</div>

<div class="row">
    <div class="col-xxl-8">
        <div class="row">
            <div class="col-lg-8">

                <app-sales-analytics></app-sales-analytics>

                <app-revenue-report></app-revenue-report>

            </div>
            <div class="col-lg-4">

                <app-analytics-total-revenue></app-analytics-total-revenue>

                <app-analytics-activity></app-analytics-activity>

            </div>
        </div>

        <app-browser-used-traffic-reports></app-browser-used-traffic-reports>
        
    </div>
    <div class="col-xxl-4">

        <app-sessions-by-countries></app-sessions-by-countries>

        <app-total-transactions></app-total-transactions>

        <app-terminals></app-terminals>

    </div>
</div>

<div class="row justify-content-center">
    <div class="col-md-6 col-lg-4 col-xxl-2/5">
        <app-sessions-device></app-sessions-device>
    </div>
    <div class="col-md-6 col-lg-4 col-xxl-2/5">
        <app-new-vs-returing></app-new-vs-returing>
    </div>
    <div class="col-md-6 col-lg-4 col-xxl-2/5">
        <app-analytics-language></app-analytics-language>
    </div>
    <div class="col-md-6 col-lg-4 col-xxl-2/5">
        <app-analytics-gender></app-analytics-gender>
    </div>
    <div class="col-md-6 col-lg-4 col-xxl-2/5">
        <app-visitors-age></app-visitors-age>
    </div>
</div>