<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Order Details</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Products</li>
        <li class="breadcrumb-item fw-semibold">Order Details</li>
    </ol>
</div>

<div
    class="row justify-content-center"
    [class.component-dark-theme]="themeService.isDark()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="col-lg-5 col-xl-4 col-md-12">
        <mat-card class="mb-25 tacking-box tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Track Order</h5>
            </mat-card-header>
            <mat-card-content>
                <div class="top d-flex align-items-center">
                    <div>
                        <span class="fw-semibold d-block mb-8">Order ID:</span>
                        <span class="d-block gray-color fw-semibold">#VL2667</span>
                    </div>
                    <div>
                        <span class="fw-semibold d-block mb-8">Tracking ID:</span>
                        <span class="d-block gray-color fw-semibold">1004152012012</span>
                    </div>
                </div>
                <ul class="track-list ps-0 mb-0">
                    <li class="position-relative">
                        <h5 class="mb-10 fw-semibold">Order Placed</h5>
                        <span class="d-block gray-color mb-10">21 March 2023 07:22 AM</span>
                        <p>There are many variations of passages of Lorem Ipsum available the majority.</p>
                    </li>
                    <li class="position-relative">
                        <h5 class="mb-10 fw-semibold">Packed</h5>
                        <span class="d-block gray-color mb-10">22 March 2023 05:16 PM</span>
                        <p>There are many variations of passages of Lorem Ipsum available the majority.</p>
                    </li>
                    <li class="position-relative">
                        <h5 class="mb-10 fw-semibold">Shipped</h5>
                        <span class="d-block gray-color mb-10">24 March 2023 12:16 AM</span>
                        <p>There are many variations of passages of Lorem Ipsum available the majority.</p>
                    </li>
                    <li class="position-relative">
                        <h5 class="mb-10 fw-semibold">Delivered</h5>
                        <span class="d-block gray-color mb-10">Estimated Delivery Within 3 Days</span>
                        <p>There are many variations of passages of Lorem Ipsum available the majority.</p>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-7 col-xl-8 col-md-12">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header>
                <h5 class="mb-0">Order #VL2667</h5>
            </mat-card-header>
            <mat-card-content>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="fw-semibold gray-color text-start">Product</th>
                                <th class="fw-semibold gray-color">Price</th>
                                <th class="fw-semibold gray-color">Size</th>
                                <th class="fw-semibold gray-color">Quantity</th>
                                <th class="fw-semibold gray-color">Rating</th>
                                <th class="fw-semibold gray-color text-end">Total Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-start">
                                    <a routerLink="/" class="d-flex align-items-center product-info">
                                        <img src="assets/img/recent-orders/product1.jpg" class="border-radius me-12" width="50" alt="product">
                                        <div class="title">
                                            <h5 class="fw-semibold mb-5">Laptop Macos Pro</h5>
                                            <span class="d-block muted-color fw-medium">
                                                Color: <span class="gray-color">Pink</span>
                                            </span>
                                        </div>
                                    </a>
                                </td>
                                <td class="gray-color">$100.00</td>
                                <td class="gray-color">M</td>
                                <td class="gray-color">02</td>
                                <td>
                                    <div class="rating">
                                        <i class="flaticon-star-1"></i>
                                        <i class="flaticon-star-1"></i>
                                        <i class="flaticon-star-1"></i>
                                        <i class="flaticon-star-1"></i>
                                        <i class="flaticon-star-1"></i>
                                    </div>
                                </td>
                                <td class="gray-color text-end">$200.00</td>
                            </tr>
                            <tr>
                                <td class="text-start">
                                    <a routerLink="/" class="d-flex align-items-center product-info">
                                        <img src="assets/img/recent-orders/product2.jpg" class="border-radius me-12" width="50" alt="product">
                                        <div class="title">
                                            <h5 class="fw-semibold mb-5">Smart Camera XD6</h5>
                                            <span class="d-block muted-color fw-medium">
                                                Color: <span class="gray-color">Red</span>
                                            </span>
                                        </div>
                                    </a>
                                </td>
                                <td class="gray-color">$50.00</td>
                                <td class="gray-color">XL</td>
                                <td class="gray-color">03</td>
                                <td>
                                    <div class="rating">
                                        <i class="flaticon-star-1"></i>
                                        <i class="flaticon-star-1"></i>
                                        <i class="flaticon-star-1"></i>
                                        <i class="flaticon-star-1"></i>
                                        <i class="flaticon-star-2"></i>
                                    </div>
                                </td>
                                <td class="gray-color text-end">$150.00</td>
                            </tr>
                            <tr>
                                <td class="text-start">
                                    <a routerLink="/" class="d-flex align-items-center product-info">
                                        <img src="assets/img/recent-orders/product3.jpg" class="border-radius me-12" width="50" alt="product">
                                        <div class="title">
                                            <h5 class="fw-semibold mb-5">Pixi 8 Wireless Airphone</h5>
                                            <span class="d-block muted-color fw-medium">
                                                Color: <span class="gray-color">White</span>
                                            </span>
                                        </div>
                                    </a>
                                </td>
                                <td class="gray-color">$100.00</td>
                                <td class="gray-color">L</td>
                                <td class="gray-color">01</td>
                                <td>
                                    <div class="rating">
                                        <i class="flaticon-star-1"></i>
                                        <i class="flaticon-star-1"></i>
                                        <i class="flaticon-star-1"></i>
                                        <i class="flaticon-star-1"></i>
                                        <i class="flaticon-star"></i>
                                    </div>
                                </td>
                                <td class="gray-color text-end">$100.00</td>
                            </tr>
                            <tr>
                                <td class="text-start">
                                    <a routerLink="/" class="d-flex align-items-center product-info">
                                        <img src="assets/img/recent-orders/product4.jpg" class="border-radius me-12" width="50" alt="product">
                                        <div class="title">
                                            <h5 class="fw-semibold mb-5">Jebble Smart Watch</h5>
                                            <span class="d-block muted-color fw-medium">
                                                Color: <span class="gray-color">Black</span>
                                            </span>
                                        </div>
                                    </a>
                                </td>
                                <td class="gray-color">$100.00</td>
                                <td class="gray-color">M</td>
                                <td class="gray-color">02</td>
                                <td>
                                    <div class="rating">
                                        <i class="flaticon-star-1"></i>
                                        <i class="flaticon-star-1"></i>
                                        <i class="flaticon-star-1"></i>
                                        <i class="flaticon-star-2"></i>
                                        <i class="flaticon-star"></i>
                                    </div>
                                </td>
                                <td class="gray-color text-end">$200.00</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="fw-semibold gray-color">Sub Total:</td>
                                <td class="fw-semibold text-end">$650.00</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="fw-semibold gray-color">Discount:</td>
                                <td class="fw-semibold text-end">$50.00</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="fw-semibold gray-color">Shipping Charge:</td>
                                <td class="fw-semibold text-end">$50.00</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="fw-semibold gray-color">Estimated Tax:</td>
                                <td class="fw-semibold text-end">$00.00</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="fw-semibold">Total (USD):</td>
                                <td class="fw-semibold text-end">$750.00</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Shipping Information</h5>
            </mat-card-header>
            <mat-card-content class="box">
                <h5 class="fw-semibold mb-15">Thomas Adison</h5>
                <ul class="list ps-0 mb-0 mt-0">
                    <li class="gray-color position-relative">
                        <span class="fw-medium d-inline-block">Address :</span>
                        3559 Roosevelt Wilson Lane San Bernardino, CA 92405
                    </li>
                    <li class="gray-color position-relative">
                        <span class="fw-medium d-inline-block">Phone :</span>
                        (123) 456-7890
                    </li>
                    <li class="gray-color position-relative">
                        <span class="fw-medium d-inline-block">Email :</span>
                        contact&#64;gmail.com
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Billing Information</h5>
            </mat-card-header>
            <mat-card-content class="box">
                <h5 class="fw-semibold mb-15">Thomas Adison</h5>
                <ul class="list ps-0 mb-0 mt-0">
                    <li class="gray-color position-relative">
                        <span class="fw-medium d-inline-block">Payment Type :</span>
                        Credit Card
                    </li>
                    <li class="gray-color position-relative">
                        <span class="fw-medium d-inline-block">Provider :</span>
                        Visa ending in 2851
                    </li>
                    <li class="gray-color position-relative">
                        <span class="fw-medium d-inline-block">CVV :</span>
                        xxx
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Delivery Info</h5>
            </mat-card-header>
            <mat-card-content class="box">
                <h5 class="fw-semibold mb-15">UPS Delivery</h5>
                <ul class="list ps-0 mb-0 mt-0">
                    <li class="gray-color position-relative">
                        <span class="fw-medium d-inline-block">Order ID :</span>
                        xxxx235
                    </li>
                    <li class="gray-color position-relative">
                        <span class="fw-medium d-inline-block">Payment Mode :</span>
                        COD
                    </li>
                    <li class="gray-color position-relative">
                        <span class="fw-medium d-inline-block">Email :</span>
                        info&#64;contact.com
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
</div>