<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Alerts</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">UI Kit</li>
        <li class="breadcrumb-item fw-semibold">Alerts</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Alerts</h5>
            </mat-card-header>
            <mat-card-content class="example-alerts">
                <div class="alert alert-tagus" role="alert">
                    A simple tagus alert—check it out!
                </div>
                <div class="alert alert-primary" role="alert">
                    A simple primary alert—check it out!
                </div>
                <div class="alert alert-secondary" role="alert">
                    A simple secondary alert—check it out!
                </div>
                <div class="alert alert-success" role="alert">
                    A simple success alert—check it out!
                </div>
                <div class="alert alert-danger" role="alert">
                    A simple danger alert—check it out!
                </div>
                <div class="alert alert-warning" role="alert">
                    A simple warning alert—check it out!
                </div>
                <div class="alert alert-info" role="alert">
                    A simple info alert—check it out!
                </div>
                <div class="alert alert-light" role="alert">
                    A simple light alert—check it out!
                </div>
                <div class="alert alert-dark" role="alert">
                    A simple dark alert—check it out!
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">BG Color Alerts</h5>
            </mat-card-header>
            <mat-card-content class="example-alerts">
                <div class="alert alert-bg-tagus" role="alert">
                    A simple tagus alert—check it out!
                </div>
                <div class="alert alert-bg-primary" role="alert">
                    A simple primary alert—check it out!
                </div>
                <div class="alert alert-bg-secondary" role="alert">
                    A simple secondary alert—check it out!
                </div>
                <div class="alert alert-bg-success" role="alert">
                    A simple success alert—check it out!
                </div>
                <div class="alert alert-bg-danger" role="alert">
                    A simple danger alert—check it out!
                </div>
                <div class="alert alert-bg-warning" role="alert">
                    A simple warning alert—check it out!
                </div>
                <div class="alert alert-bg-info" role="alert">
                    A simple info alert—check it out!
                </div>
                <div class="alert alert-bg-light" role="alert">
                    A simple light alert—check it out!
                </div>
                <div class="alert alert-bg-dark" role="alert">
                    A simple dark alert—check it out!
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Alerts with Icon</h5>
            </mat-card-header>
            <mat-card-content class="example-alerts">
                <div class="alert alert-tagus d-flex align-items-center" role="alert">
                    <i class="ri-home-line"></i>
                    A simple tagus alert—check it out!
                </div>
                <div class="alert alert-primary d-flex align-items-center" role="alert">
                    <i class="ri-home-2-line"></i>
                    A simple primary alert—check it out!
                </div>
                <div class="alert alert-secondary d-flex align-items-center" role="alert">
                    <i class="ri-home-3-line"></i>
                    A simple secondary alert—check it out!
                </div>
                <div class="alert alert-success d-flex align-items-center" role="alert">
                    <i class="ri-home-4-line"></i>
                    A simple success alert—check it out!
                </div>
                <div class="alert alert-danger d-flex align-items-center" role="alert">
                    <i class="ri-home-5-line"></i>
                    A simple danger alert—check it out!
                </div>
                <div class="alert alert-warning d-flex align-items-center" role="alert">
                    <i class="ri-home-6-line"></i>
                    A simple warning alert—check it out!
                </div>
                <div class="alert alert-info d-flex align-items-center" role="alert">
                    <i class="ri-home-7-line"></i>
                    A simple info alert—check it out!
                </div>
                <div class="alert alert-light d-flex align-items-center" role="alert">
                    <i class="ri-home-8-line"></i>
                    A simple light alert—check it out!
                </div>
                <div class="alert alert-dark d-flex align-items-center" role="alert">
                    <i class="ri-home-smile-line"></i>
                    A simple dark alert—check it out!
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">BG Color Alerts with Icon</h5>
            </mat-card-header>
            <mat-card-content class="example-alerts">
                <div class="alert alert-bg-tagus d-flex align-items-center" role="alert">
                    <i class="ri-home-line"></i>
                    A simple tagus alert—check it out!
                </div>
                <div class="alert alert-bg-primary d-flex align-items-center" role="alert">
                    <i class="ri-home-2-line"></i>
                    A simple primary alert—check it out!
                </div>
                <div class="alert alert-bg-secondary d-flex align-items-center" role="alert">
                    <i class="ri-home-3-line"></i>
                    A simple secondary alert—check it out!
                </div>
                <div class="alert alert-bg-success d-flex align-items-center" role="alert">
                    <i class="ri-home-4-line"></i>
                    A simple success alert—check it out!
                </div>
                <div class="alert alert-bg-danger d-flex align-items-center" role="alert">
                    <i class="ri-home-5-line"></i>
                    A simple danger alert—check it out!
                </div>
                <div class="alert alert-bg-warning d-flex align-items-center" role="alert">
                    <i class="ri-home-6-line"></i>
                    A simple warning alert—check it out!
                </div>
                <div class="alert alert-bg-info d-flex align-items-center" role="alert">
                    <i class="ri-home-7-line"></i>
                    A simple info alert—check it out!
                </div>
                <div class="alert alert-bg-light d-flex align-items-center" role="alert">
                    <i class="ri-home-8-line"></i>
                    A simple light alert—check it out!
                </div>
                <div class="alert alert-bg-dark d-flex align-items-center" role="alert">
                    <i class="ri-home-smile-line"></i>
                    A simple dark alert—check it out!
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Outline Alerts</h5>
            </mat-card-header>
            <mat-card-content class="example-alerts">
                <div class="alert alert-outline-tagus" role="alert">
                    A simple tagus alert—check it out!
                </div>
                <div class="alert alert-outline-primary" role="alert">
                    A simple primary alert—check it out!
                </div>
                <div class="alert alert-outline-secondary" role="alert">
                    A simple secondary alert—check it out!
                </div>
                <div class="alert alert-outline-success" role="alert">
                    A simple success alert—check it out!
                </div>
                <div class="alert alert-outline-danger" role="alert">
                    A simple danger alert—check it out!
                </div>
                <div class="alert alert-outline-warning" role="alert">
                    A simple warning alert—check it out!
                </div>
                <div class="alert alert-outline-info" role="alert">
                    A simple info alert—check it out!
                </div>
                <div class="alert alert-outline-light" role="alert">
                    A simple light alert—check it out!
                </div>
                <div class="alert alert-outline-dark" role="alert">
                    A simple dark alert—check it out!
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Link Color Alerts</h5>
            </mat-card-header>
            <mat-card-content class="example-alerts">
                <div class="alert alert-tagus" role="alert">
                    A simple tagus alert with <a routerLink="." class="alert-link">an example link</a>. Give it a click if you like.
                </div>
                <div class="alert alert-primary" role="alert">
                    A simple primary alert with <a routerLink="." class="alert-link">an example link</a>. Give it a click if you like.
                </div>
                <div class="alert alert-secondary" role="alert">
                    A simple secondary alert with <a routerLink="." class="alert-link">an example link</a>. Give it a click if you like.
                </div>
                <div class="alert alert-success" role="alert">
                    A simple success alert with <a routerLink="." class="alert-link">an example link</a>. Give it a click if you like.
                </div>
                <div class="alert alert-danger" role="alert">
                    A simple danger alert with <a routerLink="." class="alert-link">an example link</a>. Give it a click if you like.
                </div>
                <div class="alert alert-warning" role="alert">
                    A simple warning alert with <a routerLink="." class="alert-link">an example link</a>. Give it a click if you like.
                </div>
                <div class="alert alert-info" role="alert">
                    A simple info alert with <a routerLink="." class="alert-link">an example link</a>. Give it a click if you like.
                </div>
                <div class="alert alert-light" role="alert">
                    A simple light alert with <a routerLink="." class="alert-link">an example link</a>. Give it a click if you like.
                </div>
                <div class="alert alert-dark" role="alert">
                    A simple dark alert with <a routerLink="." class="alert-link">an example link</a>. Give it a click if you like.
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Dismissing Alerts</h5>
            </mat-card-header>
            <mat-card-content class="example-alerts">
                <div class="alert alert-tagus d-flex justify-content-space-between align-items-center" role="alert" *ngIf="showAlert">
                    A simple tagus alert—check it out!
                    <button type="button" class="close bg-transparent p-0 border-none" data-dismiss="alert" aria-label="Close" (click)="dismissAlert()">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
                <div class="alert alert-primary d-flex justify-content-space-between align-items-center" role="alert" *ngIf="showAlert2">
                    A simple primary alert—check it out!
                    <button type="button" class="close bg-transparent p-0 border-none" data-dismiss="alert" aria-label="Close" (click)="dismissAlert2()">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
                <div class="alert alert-secondary d-flex justify-content-space-between align-items-center" role="alert" *ngIf="showAlert3">
                    A simple secondary alert—check it out!
                    <button type="button" class="close bg-transparent p-0 border-none" data-dismiss="alert" aria-label="Close" (click)="dismissAlert3()">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
                <div class="alert alert-success d-flex justify-content-space-between align-items-center" role="alert" *ngIf="showAlert4">
                    A simple success alert—check it out!
                    <button type="button" class="close bg-transparent p-0 border-none" data-dismiss="alert" aria-label="Close" (click)="dismissAlert4()">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
                <div class="alert alert-danger d-flex justify-content-space-between align-items-center" role="alert" *ngIf="showAlert5">
                    A simple danger alert—check it out!
                    <button type="button" class="close bg-transparent p-0 border-none" data-dismiss="alert" aria-label="Close" (click)="dismissAlert5()">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
                <div class="alert alert-warning d-flex justify-content-space-between align-items-center" role="alert" *ngIf="showAlert6">
                    A simple warning alert—check it out!
                    <button type="button" class="close bg-transparent p-0 border-none" data-dismiss="alert" aria-label="Close" (click)="dismissAlert6()">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
                <div class="alert alert-info d-flex justify-content-space-between align-items-center" role="alert" *ngIf="showAlert7">
                    A simple info alert—check it out!
                    <button type="button" class="close bg-transparent p-0 border-none" data-dismiss="alert" aria-label="Close" (click)="dismissAlert7()">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
                <div class="alert alert-light d-flex justify-content-space-between align-items-center" role="alert" *ngIf="showAlert8">
                    A simple light alert—check it out!
                    <button type="button" class="close bg-transparent p-0 border-none" data-dismiss="alert" aria-label="Close" (click)="dismissAlert8()">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
                <div class="alert alert-dark d-flex justify-content-space-between align-items-center" role="alert" *ngIf="showAlert9">
                    A simple dark alert—check it out!
                    <button type="button" class="close bg-transparent p-0 border-none" data-dismiss="alert" aria-label="Close" (click)="dismissAlert9()">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Dismissing BG Alerts</h5>
            </mat-card-header>
            <mat-card-content class="example-alerts">
                <div class="alert alert-bg-tagus d-flex justify-content-space-between align-items-center" role="alert" *ngIf="showAlert10">
                    A simple tagus alert—check it out!
                    <button type="button" class="close bg-transparent p-0 border-none" data-dismiss="alert" aria-label="Close" (click)="dismissAlert10()">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
                <div class="alert alert-bg-primary d-flex justify-content-space-between align-items-center" role="alert" *ngIf="showAlert11">
                    A simple primary alert—check it out!
                    <button type="button" class="close bg-transparent p-0 border-none" data-dismiss="alert" aria-label="Close" (click)="dismissAlert11()">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
                <div class="alert alert-bg-secondary d-flex justify-content-space-between align-items-center" role="alert" *ngIf="showAlert12">
                    A simple secondary alert—check it out!
                    <button type="button" class="close bg-transparent p-0 border-none" data-dismiss="alert" aria-label="Close" (click)="dismissAlert12()">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
                <div class="alert alert-bg-success d-flex justify-content-space-between align-items-center" role="alert" *ngIf="showAlert13">
                    A simple success alert—check it out!
                    <button type="button" class="close bg-transparent p-0 border-none" data-dismiss="alert" aria-label="Close" (click)="dismissAlert13()">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
                <div class="alert alert-bg-danger d-flex justify-content-space-between align-items-center" role="alert" *ngIf="showAlert14">
                    A simple danger alert—check it out!
                    <button type="button" class="close bg-transparent p-0 border-none" data-dismiss="alert" aria-label="Close" (click)="dismissAlert14()">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
                <div class="alert alert-bg-warning d-flex justify-content-space-between align-items-center" role="alert" *ngIf="showAlert15">
                    A simple warning alert—check it out!
                    <button type="button" class="close bg-transparent p-0 border-none" data-dismiss="alert" aria-label="Close" (click)="dismissAlert15()">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
                <div class="alert alert-bg-info d-flex justify-content-space-between align-items-center" role="alert" *ngIf="showAlert16">
                    A simple info alert—check it out!
                    <button type="button" class="close bg-transparent p-0 border-none" data-dismiss="alert" aria-label="Close" (click)="dismissAlert16()">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
                <div class="alert alert-bg-light d-flex justify-content-space-between align-items-center" role="alert" *ngIf="showAlert17">
                    A simple light alert—check it out!
                    <button type="button" class="close bg-transparent p-0 border-none" data-dismiss="alert" aria-label="Close" (click)="dismissAlert17()">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
                <div class="alert alert-bg-dark d-flex justify-content-space-between align-items-center" role="alert" *ngIf="showAlert18">
                    A simple dark alert—check it out!
                    <button type="button" class="close bg-transparent p-0 border-none" data-dismiss="alert" aria-label="Close" (click)="dismissAlert18()">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>