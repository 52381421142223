import {Component, OnDestroy, OnInit} from '@angular/core';
import {CustomizerSettingsService} from "../../../customizer-settings/customizer-settings.service";
import {ErrorService} from "../../../common/notifications/errors/error.service";
import {Router} from "@angular/router";
import {SuccessService} from "../../../common/notifications/success/success.service";
import {ErrorTypeEnum} from "../../../../enums/error-type.enum";
import {ApiService} from "../../../../services/api.service";
import {EndpointTypeEnum} from "../../../../enums/endpoint-type.enum";

@Component({
  selector: 'app-market-list',
  templateUrl: './market-list.component.html',
  styleUrl: './market-list.component.scss'
})
export class MarketListComponent implements OnInit, OnDestroy {
    markets: any[] = [];

    constructor(
        private router: Router,
        private apiService: ApiService,
        private errorService: ErrorService,
        private successService: SuccessService,
        public themeService: CustomizerSettingsService,
    ) {}

    ngOnInit() {
        this.load();
    }

    ngOnDestroy() {
        this.successService.clear(ErrorTypeEnum.Global);
        this.successService.clear(ErrorTypeEnum.Local);
        this.errorService.clearErrors(ErrorTypeEnum.Global);
        this.errorService.clearErrors(ErrorTypeEnum.Local);
    }

    load() {
        this.apiService
            .get(EndpointTypeEnum.Market, '/markets', true)
            .subscribe({
                next: (response: any) => {
                    this.markets = response.data;
                    // this.isLoading = false;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                    // this.isLoading = false;
                },
            });
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    toggleCardBorderTheme() {
        this.themeService.toggleCardBorderTheme();
    }

    toggleCardBorderRadiusTheme() {
        this.themeService.toggleCardBorderRadiusTheme();
    }

    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }
}
