<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Material Icons</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Icons</li>
        <li class="breadcrumb-item fw-semibold">Material Icons</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card" [class.component-dark-theme]="themeService.isDark()">
    <mat-card-content>
        <div class="icon-demo">
            <span class="fw-semibold me-10">Use example:</span>
            <code>
                &lt;<span class="red">mat-icon</span> <span class="green">aria-hidden</span>=<span class="yellow">&quot;false&quot;</span> <span class="green">class</span>=<span class="yellow">&quot;material-symbols-outlined&quot;</span>&gt;home&lt;/<span class="red">mat-icon</span>&gt;
            </code>
        </div>
        <div class="row justify-content-center">
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="home"></mat-icon>
                    <p class="d-block gray-color">home</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="3d_rotation"></mat-icon>
                    <p class="d-block gray-color">3d_rotation</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="accessibility"></mat-icon>
                    <p class="d-block gray-color">accessibility</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="accessibility_new"></mat-icon>
                    <p class="d-block gray-color">accessibility_new</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="accessible"></mat-icon>
                    <p class="d-block gray-color">accessible</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="accessible_forward"></mat-icon>
                    <p class="d-block gray-color">accessible_forward</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="account_balance"></mat-icon>
                    <p class="d-block gray-color">account_balance</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="account_balance_wallet"></mat-icon>
                    <p class="d-block gray-color">account_balance_wallet</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="account_box"></mat-icon>
                    <p class="d-block gray-color">account_box</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="account_circle"></mat-icon>
                    <p class="d-block gray-color">account_circle</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="add_shopping_cart"></mat-icon>
                    <p class="d-block gray-color">add_shopping_cart</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="alarm"></mat-icon>
                    <p class="d-block gray-color">alarm</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="alarm_add"></mat-icon>
                    <p class="d-block gray-color">alarm_add</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="alarm_off"></mat-icon>
                    <p class="d-block gray-color">alarm_off</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="alarm_on"></mat-icon>
                    <p class="d-block gray-color">alarm_on</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="all_out"></mat-icon>
                    <p class="d-block gray-color">all_out</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="android"></mat-icon>
                    <p class="d-block gray-color">android</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="announcement"></mat-icon>
                    <p class="d-block gray-color">announcement</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="arrow_right_alt"></mat-icon>
                    <p class="d-block gray-color">arrow_right_alt</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="aspect_ratio"></mat-icon>
                    <p class="d-block gray-color">aspect_ratio</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="assessment"></mat-icon>
                    <p class="d-block gray-color">assessment</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="assignment"></mat-icon>
                    <p class="d-block gray-color">assignment</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="assignment_ind"></mat-icon>
                    <p class="d-block gray-color">assignment_ind</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="assignment_late"></mat-icon>
                    <p class="d-block gray-color">assignment_late</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="assignment_return"></mat-icon>
                    <p class="d-block gray-color">assignment_return</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="assignment_returned"></mat-icon>
                    <p class="d-block gray-color">assignment_returned</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="assignment_turned_in"></mat-icon>
                    <p class="d-block gray-color">assignment_turned_in</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="autorenew"></mat-icon>
                    <p class="d-block gray-color">autorenew</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="backup"></mat-icon>
                    <p class="d-block gray-color">backup</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="book"></mat-icon>
                    <p class="d-block gray-color">book</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="bookmark"></mat-icon>
                    <p class="d-block gray-color">bookmark</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="bookmark_border"></mat-icon>
                    <p class="d-block gray-color">bookmark_border</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="bug_report"></mat-icon>
                    <p class="d-block gray-color">bug_report</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="build"></mat-icon>
                    <p class="d-block gray-color">build</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="cached"></mat-icon>
                    <p class="d-block gray-color">cached</p>
                </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="box text-center border-radius p-25">
                    <mat-icon aria-hidden="false" fontIcon="calendar_today"></mat-icon>
                    <p class="d-block gray-color">calendar_today</p>
                </div>
            </div>
        </div>
        <div class="text-center mt-25">
            <a mat-flat-button href="https://www.angularjswiki.com/angular/angular-material-icons-list-mat-icon-list/" class="tagus" target="_blank">View All Icons</a>
        </div>
    </mat-card-content>
</mat-card>