<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Slider</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">UI Kit</li>
        <li class="breadcrumb-item fw-semibold">Slider</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Basic Slider</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-slider>
                    <input matSliderThumb>
                </mat-slider>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Range Slider</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-slider min="200" max="500">
                    <input value="300" matSliderStartThumb>
                    <input value="400" matSliderEndThumb>
                </mat-slider>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Range Slider</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-slider min="0" max="100000" step="1000" showTickMarks discrete [displayWith]="formatLabel">
                    <input matSliderThumb>
                </mat-slider>
            </mat-card-content>
        </mat-card>
    </div>
</div>