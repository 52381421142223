<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Stepper</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">UI Kit</li>
        <li class="breadcrumb-item fw-semibold">Stepper</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Basic Stepper</h5>
    </mat-card-header>
    <mat-card-content>
        <mat-stepper #stepper>
            <mat-step [stepControl]="firstFormGroup" errorMessage="Name is required.">
                <form [formGroup]="firstFormGroup">
                    <ng-template matStepLabel>Fill out your name</ng-template>
                    <mat-form-field appearance="fill">
                        <mat-label>Name</mat-label>
                        <input matInput placeholder="Last name, First name" formControlName="firstCtrl" required>
                    </mat-form-field>
                    <div>
                        <p>Go to a different step to see the error state</p>
                        <button mat-flat-button matStepperNext class="gray">Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="secondFormGroup" errorMessage="Address is required.">
                <form [formGroup]="secondFormGroup">
                    <ng-template matStepLabel>Fill out your address</ng-template>
                    <mat-form-field appearance="fill">
                        <mat-label>Address</mat-label>
                        <input matInput placeholder="Ex. 1 Main St, New York, NY" formControlName="secondCtrl"
                            required>
                    </mat-form-field>
                    <div>
                        <p>Go to a different step to see the error state</p>
                        <button mat-flat-button matStepperPrevious class="gray me-15">Back</button>
                        <button mat-flat-button matStepperNext class="gray">Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Done</ng-template>
                <p>You are now done.</p>
                <div>
                    <button mat-flat-button matStepperPrevious class="gray me-15">Back</button>
                    <button mat-flat-button (click)="stepper.reset()" class="gray">Reset</button>
                </div>
            </mat-step>
        </mat-stepper>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Stepper Header Position</h5>
    </mat-card-header>
    <mat-card-content>
        <mat-stepper headerPosition="bottom" #stepper>
            <mat-step [stepControl]="firstFormGroup">
                <form [formGroup]="firstFormGroup">
                    <ng-template matStepLabel>Fill out your name</ng-template>
                    <mat-form-field>
                        <input matInput placeholder="Last name, First name" formControlName="firstCtrl" required>
                    </mat-form-field>
                    <div>
                        <button mat-flat-button class="gray" matStepperNext>Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="secondFormGroup" optional>
                <form [formGroup]="secondFormGroup">
                    <ng-template matStepLabel>Fill out your address</ng-template>
                    <mat-form-field>
                        <input matInput placeholder="Address" formControlName="secondCtrl" required>
                    </mat-form-field>
                    <div>
                        <button mat-flat-button class="gray me-15" matStepperPrevious>Back</button>
                        <button mat-flat-button class="gray" matStepperNext>Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Done</ng-template>
                <p>You are now done.</p>
                <div>
                    <button mat-flat-button class="gray me-15" matStepperPrevious>Back</button>
                    <button mat-flat-button class="gray" (click)="stepper.reset()">Reset</button>
                </div>
            </mat-step>
        </mat-stepper>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Stepper Label Bottom Position</h5>
    </mat-card-header>
    <mat-card-content>
        <mat-stepper labelPosition="bottom" #stepper>
            <mat-step [stepControl]="firstFormGroup">
                <form [formGroup]="firstFormGroup">
                    <ng-template matStepLabel>Fill out your name</ng-template>
                    <mat-form-field appearance="fill">
                        <mat-label>Name</mat-label>
                        <input matInput placeholder="Last name, First name" formControlName="firstCtrl" required>
                    </mat-form-field>
                    <div>
                        <button mat-flat-button color="primary" matStepperNext>Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="secondFormGroup" optional>
                <form [formGroup]="secondFormGroup">
                    <ng-template matStepLabel>Fill out your address</ng-template>
                    <mat-form-field appearance="fill">
                        <mat-label>Address</mat-label>
                        <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY"
                        required>
                    </mat-form-field>
                    <div>
                        <button mat-flat-button color="primary" class="me-15" matStepperPrevious>Back</button>
                        <button mat-flat-button color="primary" matStepperNext>Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Done</ng-template>
                <p>You are now done.</p>
                <div>
                    <button mat-flat-button color="primary" class="me-15" matStepperPrevious>Back</button>
                    <button mat-flat-button color="primary" (click)="stepper.reset()">Reset</button>
                </div>
            </mat-step>
        </mat-stepper>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Stepper Lazy Content Rendering</h5>
    </mat-card-header>
    <mat-card-content>
        <mat-stepper orientation="vertical">
            <mat-step>
                <ng-template matStepLabel>Step 1</ng-template>
                <ng-template matStepContent>
                    <p>This content was rendered lazily</p>
                    <button mat-flat-button color="primary" matStepperNext>Next</button>
                </ng-template>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Step 2</ng-template>
                <ng-template matStepContent>
                    <p>This content was also rendered lazily</p>
                    <button mat-flat-button color="primary" class="me-15" matStepperPrevious>Back</button>
                    <button mat-flat-button color="primary" matStepperNext>Next</button>
                </ng-template>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Step 3</ng-template>
                <p>This content was rendered eagerly</p>
                <button mat-flat-button color="primary" matStepperPrevious>Back</button>
            </mat-step>
        </mat-stepper>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Stepper Responsive</h5>
    </mat-card-header>
    <mat-card-content>
        <app-stepper-responsive></app-stepper-responsive>
    </mat-card-content>
</mat-card>