<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Echarts</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Apexcharts</li>
        <li class="breadcrumb-item fw-semibold">Echarts</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Simple Chart</h5>
    </mat-card-header>
    <mat-card-content>
        <app-simple-echart></app-simple-echart>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Events Chart</h5>
    </mat-card-header>
    <mat-card-content>
        <app-events-echart></app-events-echart>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Theme Chart</h5>
    </mat-card-header>
    <mat-card-content>
        <app-theme-echart></app-theme-echart>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Loading Chart</h5>
    </mat-card-header>
    <mat-card-content>
        <app-loading-echart></app-loading-echart>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Merge Chart</h5>
    </mat-card-header>
    <mat-card-content>
        <app-merge-echart></app-merge-echart>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">InitOpts Chart</h5>
    </mat-card-header>
    <mat-card-content>
        <app-init-opts-echart></app-init-opts-echart>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Instance Chart</h5>
    </mat-card-header>
    <mat-card-content>
        <app-instance-echart></app-instance-echart>
    </mat-card-content>
</mat-card>