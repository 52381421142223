<form class="example-form">
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Assignee</mat-label>
        <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option.name}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>