<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Flaticon</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Icons</li>
        <li class="breadcrumb-item fw-semibold">Flaticon</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card" [class.component-dark-theme]="themeService.isDark()">
    <mat-card-content>
        <div class="icon-demo mb-25">
            <span class="fw-semibold me-10">Use example:</span>
            <code>
                &lt;<span class="red">i</span> <span class="green">class</span>=<span class="yellow">&quot;flaticon-chevron&quot;</span>&gt;&lt;/<span
                class="red">i</span>&gt;
            </code>
            <span class="ms-10 me-10 gray-color">or</span>
            <code>
                &lt;<span class="red">span</span> <span class="green">class</span>=<span class="yellow">&quot;flaticon-chevron&quot;</span>&gt;&lt;/<span class="red">span</span>&gt;
            </code>
        </div>
        <div class="text-center">
            <div class="glyph">
                <i class="flaticon flaticon-chevron"></i>
                <div class="class-name">.flaticon-chevron</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-chevron-1"></i>
                <div class="class-name">.flaticon-chevron-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-right-arrow"></i>
                <div class="class-name">.flaticon-right-arrow</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-left-arrow"></i>
                <div class="class-name">.flaticon-left-arrow</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-chevron-2"></i>
                <div class="class-name">.flaticon-chevron-2</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-chevron-3"></i>
                <div class="class-name">.flaticon-chevron-3</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-right-arrow-1"></i>
                <div class="class-name">.flaticon-right-arrow-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-left-arrow-1"></i>
                <div class="class-name">.flaticon-left-arrow-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-telegram"></i>
                <div class="class-name">.flaticon-telegram</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-like"></i>
                <div class="class-name">.flaticon-like</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-like-1"></i>
                <div class="class-name">.flaticon-like-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-dislike"></i>
                <div class="class-name">.flaticon-dislike</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-dislike-1"></i>
                <div class="class-name">.flaticon-dislike-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-eye"></i>
                <div class="class-name">.flaticon-eye</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-view"></i>
                <div class="class-name">.flaticon-view</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-eye-1"></i>
                <div class="class-name">.flaticon-eye-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-visibility-off"></i>
                <div class="class-name">.flaticon-visibility-off</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-price-tag"></i>
                <div class="class-name">.flaticon-price-tag</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-price-tag-1"></i>
                <div class="class-name">.flaticon-price-tag-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-heart"></i>
                <div class="class-name">.flaticon-heart</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-heart-1"></i>
                <div class="class-name">.flaticon-heart-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-star"></i>
                <div class="class-name">.flaticon-star</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-star-1"></i>
                <div class="class-name">.flaticon-star-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-star-2"></i>
                <div class="class-name">.flaticon-star-2</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-shopping-cart"></i>
                <div class="class-name">.flaticon-shopping-cart</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-shopping-cart-1"></i>
                <div class="class-name">.flaticon-shopping-cart-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-bag"></i>
                <div class="class-name">.flaticon-bag</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-shopping-cart-2"></i>
                <div class="class-name">.flaticon-shopping-cart-2</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-user"></i>
                <div class="class-name">.flaticon-user</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-user-1"></i>
                <div class="class-name">.flaticon-user-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-lock"></i>
                <div class="class-name">.flaticon-lock</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-unlock"></i>
                <div class="class-name">.flaticon-unlock</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-play-button-arrowhead"></i>
                <div class="class-name">.flaticon-play-button-arrowhead</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-play"></i>
                <div class="class-name">.flaticon-play</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-search-interface-symbol"></i>
                <div class="class-name">.flaticon-search-interface-symbol</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-search"></i>
                <div class="class-name">.flaticon-search</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-plus"></i>
                <div class="class-name">.flaticon-plus</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-minus"></i>
                <div class="class-name">.flaticon-minus</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-close"></i>
                <div class="class-name">.flaticon-close</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-menu"></i>
                <div class="class-name">.flaticon-menu</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-menu-1"></i>
                <div class="class-name">.flaticon-menu-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-menu-2"></i>
                <div class="class-name">.flaticon-menu-2</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-bookmark"></i>
                <div class="class-name">.flaticon-bookmark</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-bookmark-1"></i>
                <div class="class-name">.flaticon-bookmark-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-folder"></i>
                <div class="class-name">.flaticon-folder</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-folder-1"></i>
                <div class="class-name">.flaticon-folder-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-diamond"></i>
                <div class="class-name">.flaticon-diamond</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-diamond-1"></i>
                <div class="class-name">.flaticon-diamond-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-check"></i>
                <div class="class-name">.flaticon-check</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-check-mark"></i>
                <div class="class-name">.flaticon-check-mark</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-draw-check-mark"></i>
                <div class="class-name">.flaticon-draw-check-mark</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-check-mark-1"></i>
                <div class="class-name">.flaticon-check-mark-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-customer-service"></i>
                <div class="class-name">.flaticon-customer-service</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-technical-support"></i>
                <div class="class-name">.flaticon-technical-support</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-support"></i>
                <div class="class-name">.flaticon-support</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-people"></i>
                <div class="class-name">.flaticon-people</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-users-group"></i>
                <div class="class-name">.flaticon-users-group</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-black-circle"></i>
                <div class="class-name">.flaticon-black-circle</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-3d-cube"></i>
                <div class="class-name">.flaticon-3d-cube</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-cube-3d"></i>
                <div class="class-name">.flaticon-cube-3d</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-straight-quotes"></i>
                <div class="class-name">.flaticon-straight-quotes</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-right-quote"></i>
                <div class="class-name">.flaticon-right-quote</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-quotes"></i>
                <div class="class-name">.flaticon-quotes</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-quote"></i>
                <div class="class-name">.flaticon-quote</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-bell"></i>
                <div class="class-name">.flaticon-bell</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-bell-1"></i>
                <div class="class-name">.flaticon-bell-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-secure-shield"></i>
                <div class="class-name">.flaticon-secure-shield</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-shield"></i>
                <div class="class-name">.flaticon-shield</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-tap"></i>
                <div class="class-name">.flaticon-tap</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-tap-1"></i>
                <div class="class-name">.flaticon-tap-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-fingerprint-scan"></i>
                <div class="class-name">.flaticon-fingerprint-scan</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-fingerprint"></i>
                <div class="class-name">.flaticon-fingerprint</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-brain"></i>
                <div class="class-name">.flaticon-brain</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-brainstorm"></i>
                <div class="class-name">.flaticon-brainstorm</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-credit-card"></i>
                <div class="class-name">.flaticon-credit-card</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-credit-cards"></i>
                <div class="class-name">.flaticon-credit-cards</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-award"></i>
                <div class="class-name">.flaticon-award</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-trophy"></i>
                <div class="class-name">.flaticon-trophy</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-award-symbol"></i>
                <div class="class-name">.flaticon-award-symbol</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-award-1"></i>
                <div class="class-name">.flaticon-award-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-download"></i>
                <div class="class-name">.flaticon-download</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-file"></i>
                <div class="class-name">.flaticon-file</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-download-circular-button"></i>
                <div class="class-name">.flaticon-download-circular-button</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-fast-delivery"></i>
                <div class="class-name">.flaticon-fast-delivery</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-gift-box-with-a-bow"></i>
                <div class="class-name">.flaticon-gift-box-with-a-bow</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-giftbox"></i>
                <div class="class-name">.flaticon-giftbox</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-layer"></i>
                <div class="class-name">.flaticon-layer</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-layer-1"></i>
                <div class="class-name">.flaticon-layer-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-information"></i>
                <div class="class-name">.flaticon-information</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-info"></i>
                <div class="class-name">.flaticon-info</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-worldwide"></i>
                <div class="class-name">.flaticon-worldwide</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-world"></i>
                <div class="class-name">.flaticon-world</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-envelope"></i>
                <div class="class-name">.flaticon-envelope</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-email"></i>
                <div class="class-name">.flaticon-email</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-email-1"></i>
                <div class="class-name">.flaticon-email-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-phone-call"></i>
                <div class="class-name">.flaticon-phone-call</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-telephone"></i>
                <div class="class-name">.flaticon-telephone</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-maps-and-flags"></i>
                <div class="class-name">.flaticon-maps-and-flags</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-maps-and-flags-1"></i>
                <div class="class-name">.flaticon-maps-and-flags-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-pin"></i>
                <div class="class-name">.flaticon-pin</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-marker"></i>
                <div class="class-name">.flaticon-marker</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-thumbtacks"></i>
                <div class="class-name">.flaticon-thumbtacks</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-thumbtacks-1"></i>
                <div class="class-name">.flaticon-thumbtacks-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-placeholder"></i>
                <div class="class-name">.flaticon-placeholder</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-home"></i>
                <div class="class-name">.flaticon-home</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-home-1"></i>
                <div class="class-name">.flaticon-home-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-video-camera"></i>
                <div class="class-name">.flaticon-video-camera</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-video-camera-1"></i>
                <div class="class-name">.flaticon-video-camera-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-camera"></i>
                <div class="class-name">.flaticon-camera</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-photo-camera"></i>
                <div class="class-name">.flaticon-photo-camera</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-refresh"></i>
                <div class="class-name">.flaticon-refresh</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-edit"></i>
                <div class="class-name">.flaticon-edit</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-pen"></i>
                <div class="class-name">.flaticon-pen</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-share"></i>
                <div class="class-name">.flaticon-share</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-share-1"></i>
                <div class="class-name">.flaticon-share-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-printer"></i>
                <div class="class-name">.flaticon-printer</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-calendar"></i>
                <div class="class-name">.flaticon-calendar</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-calendar-1"></i>
                <div class="class-name">.flaticon-calendar-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-diskette"></i>
                <div class="class-name">.flaticon-diskette</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-gallery"></i>
                <div class="class-name">.flaticon-gallery</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-musical-note"></i>
                <div class="class-name">.flaticon-musical-note</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-taget"></i>
                <div class="class-name">.flaticon-taget</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-plane"></i>
                <div class="class-name">.flaticon-plane</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-mail"></i>
                <div class="class-name">.flaticon-mail</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-airplane"></i>
                <div class="class-name">.flaticon-airplane</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-comment"></i>
                <div class="class-name">.flaticon-comment</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-chat"></i>
                <div class="class-name">.flaticon-chat</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-up-right-arrow"></i>
                <div class="class-name">.flaticon-up-right-arrow</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-upper-left-arrow"></i>
                <div class="class-name">.flaticon-upper-left-arrow</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-flash"></i>
                <div class="class-name">.flaticon-flash</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-flash-1"></i>
                <div class="class-name">.flaticon-flash-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-facebook-app-symbol"></i>
                <div class="class-name">.flaticon-facebook-app-symbol</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-youtube"></i>
                <div class="class-name">.flaticon-youtube</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-whatsapp"></i>
                <div class="class-name">.flaticon-whatsapp</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-instagram"></i>
                <div class="class-name">.flaticon-instagram</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-tik-tok"></i>
                <div class="class-name">.flaticon-tik-tok</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-wechat"></i>
                <div class="class-name">.flaticon-wechat</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-messenger"></i>
                <div class="class-name">.flaticon-messenger</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-qq-penguin-shape"></i>
                <div class="class-name">.flaticon-qq-penguin-shape</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-pinterest"></i>
                <div class="class-name">.flaticon-pinterest</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-twitter"></i>
                <div class="class-name">.flaticon-twitter</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-reddit-logo"></i>
                <div class="class-name">.flaticon-reddit-logo</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-linkedin"></i>
                <div class="class-name">.flaticon-linkedin</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-quora"></i>
                <div class="class-name">.flaticon-quora</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-discord"></i>
                <div class="class-name">.flaticon-discord</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-twitch"></i>
                <div class="class-name">.flaticon-twitch</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-trend"></i>
                <div class="class-name">.flaticon-trend</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-trend-1"></i>
                <div class="class-name">.flaticon-trend-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-bounce"></i>
                <div class="class-name">.flaticon-bounce</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-bounce-1"></i>
                <div class="class-name">.flaticon-bounce-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-turn-up"></i>
                <div class="class-name">.flaticon-turn-up</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-turn-down"></i>
                <div class="class-name">.flaticon-turn-down</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-loop"></i>
                <div class="class-name">.flaticon-loop</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-bar-chart"></i>
                <div class="class-name">.flaticon-bar-chart</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-pie-chart"></i>
                <div class="class-name">.flaticon-pie-chart</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-bar-chart-1"></i>
                <div class="class-name">.flaticon-bar-chart-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-bar-chart-2"></i>
                <div class="class-name">.flaticon-bar-chart-2</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-chart"></i>
                <div class="class-name">.flaticon-chart</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-bar-chart-3"></i>
                <div class="class-name">.flaticon-bar-chart-3</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-pie-chart-1"></i>
                <div class="class-name">.flaticon-pie-chart-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-analytics"></i>
                <div class="class-name">.flaticon-analytics</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-pie-chart-2"></i>
                <div class="class-name">.flaticon-pie-chart-2</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-infographic"></i>
                <div class="class-name">.flaticon-infographic</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-pie-chart-3"></i>
                <div class="class-name">.flaticon-pie-chart-3</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-pie-chart-4"></i>
                <div class="class-name">.flaticon-pie-chart-4</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-graph"></i>
                <div class="class-name">.flaticon-graph</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-evolution"></i>
                <div class="class-name">.flaticon-evolution</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-growth"></i>
                <div class="class-name">.flaticon-growth</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-bar-graph"></i>
                <div class="class-name">.flaticon-bar-graph</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-chart-1"></i>
                <div class="class-name">.flaticon-chart-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-pie-chart-5"></i>
                <div class="class-name">.flaticon-pie-chart-5</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-bar-chart-4"></i>
                <div class="class-name">.flaticon-bar-chart-4</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-bars"></i>
                <div class="class-name">.flaticon-bars</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-donut-chart"></i>
                <div class="class-name">.flaticon-donut-chart</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-growth-1"></i>
                <div class="class-name">.flaticon-growth-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-pie-chart-6"></i>
                <div class="class-name">.flaticon-pie-chart-6</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-pie-chart-7"></i>
                <div class="class-name">.flaticon-pie-chart-7</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-pdf-file"></i>
                <div class="class-name">.flaticon-pdf-file</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-pdf"></i>
                <div class="class-name">.flaticon-pdf</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-setting"></i>
                <div class="class-name">.flaticon-setting</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-settings"></i>
                <div class="class-name">.flaticon-settings</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-arrow-up"></i>
                <div class="class-name">.flaticon-arrow-up</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-down-filled-triangular-arrow"></i>
                <div class="class-name">.flaticon-down-filled-triangular-arrow</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-notification"></i>
                <div class="class-name">.flaticon-notification</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-bell-2"></i>
                <div class="class-name">.flaticon-bell-2</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-activity"></i>
                <div class="class-name">.flaticon-activity</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-puzzle"></i>
                <div class="class-name">.flaticon-puzzle</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-pulse"></i>
                <div class="class-name">.flaticon-pulse</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-menu-3"></i>
                <div class="class-name">.flaticon-menu-3</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-learning"></i>
                <div class="class-name">.flaticon-learning</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-struggle"></i>
                <div class="class-name">.flaticon-struggle</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-goal"></i>
                <div class="class-name">.flaticon-goal</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-purpose"></i>
                <div class="class-name">.flaticon-purpose</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-idea"></i>
                <div class="class-name">.flaticon-idea</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-online-shop"></i>
                <div class="class-name">.flaticon-online-shop</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-monitor"></i>
                <div class="class-name">.flaticon-monitor</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-project-management"></i>
                <div class="class-name">.flaticon-project-management</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-online-learning"></i>
                <div class="class-name">.flaticon-online-learning</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-to-do-list"></i>
                <div class="class-name">.flaticon-to-do-list</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-visualization"></i>
                <div class="class-name">.flaticon-visualization</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-email-2"></i>
                <div class="class-name">.flaticon-email-2</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-add"></i>
                <div class="class-name">.flaticon-add</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-copy"></i>
                <div class="class-name">.flaticon-copy</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-files-copy-interface-symbol"></i>
                <div class="class-name">.flaticon-files-copy-interface-symbol</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-chart-2"></i>
                <div class="class-name">.flaticon-chart-2</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-add-1"></i>
                <div class="class-name">.flaticon-add-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-file-1"></i>
                <div class="class-name">.flaticon-file-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-file-2"></i>
                <div class="class-name">.flaticon-file-2</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-contact-form"></i>
                <div class="class-name">.flaticon-contact-form</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-list"></i>
                <div class="class-name">.flaticon-list</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-authentication"></i>
                <div class="class-name">.flaticon-authentication</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-insurance"></i>
                <div class="class-name">.flaticon-insurance</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-timers"></i>
                <div class="class-name">.flaticon-timers</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-credit-card-1"></i>
                <div class="class-name">.flaticon-credit-card-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-verify"></i>
                <div class="class-name">.flaticon-verify</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-timer"></i>
                <div class="class-name">.flaticon-timer</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-more"></i>
                <div class="class-name">.flaticon-more</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-videocall"></i>
                <div class="class-name">.flaticon-videocall</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-star-3"></i>
                <div class="class-name">.flaticon-star-3</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-download-1"></i>
                <div class="class-name">.flaticon-download-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-padlock"></i>
                <div class="class-name">.flaticon-padlock</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-emoji"></i>
                <div class="class-name">.flaticon-emoji</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-abstract"></i>
                <div class="class-name">.flaticon-abstract</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-abstract-shape"></i>
                <div class="class-name">.flaticon-abstract-shape</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-coffee-cup"></i>
                <div class="class-name">.flaticon-coffee-cup</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-tools"></i>
                <div class="class-name">.flaticon-tools</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-box"></i>
                <div class="class-name">.flaticon-box</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-world-wide-web"></i>
                <div class="class-name">.flaticon-world-wide-web</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-fullscreen"></i>
                <div class="class-name">.flaticon-fullscreen</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-fullscreen-1"></i>
                <div class="class-name">.flaticon-fullscreen-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-fullscreen-2"></i>
                <div class="class-name">.flaticon-fullscreen-2</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-fullscreen-3"></i>
                <div class="class-name">.flaticon-fullscreen-3</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-fullscreen-4"></i>
                <div class="class-name">.flaticon-fullscreen-4</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-resume"></i>
                <div class="class-name">.flaticon-resume</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-house"></i>
                <div class="class-name">.flaticon-house</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-truck"></i>
                <div class="class-name">.flaticon-truck</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-contract"></i>
                <div class="class-name">.flaticon-contract</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-iteration"></i>
                <div class="class-name">.flaticon-iteration</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-wifi"></i>
                <div class="class-name">.flaticon-wifi</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-snowflake"></i>
                <div class="class-name">.flaticon-snowflake</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-email-3"></i>
                <div class="class-name">.flaticon-email-3</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-alert"></i>
                <div class="class-name">.flaticon-alert</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-filter"></i>
                <div class="class-name">.flaticon-filter</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-like-2"></i>
                <div class="class-name">.flaticon-like-2</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-coding"></i>
                <div class="class-name">.flaticon-coding</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-sun"></i>
                <div class="class-name">.flaticon-sun</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-coding-1"></i>
                <div class="class-name">.flaticon-coding-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-world-1"></i>
                <div class="class-name">.flaticon-world-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-sustainable"></i>
                <div class="class-name">.flaticon-sustainable</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-shuttle"></i>
                <div class="class-name">.flaticon-shuttle</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-wind"></i>
                <div class="class-name">.flaticon-wind</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-list-1"></i>
                <div class="class-name">.flaticon-list-1</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-cloud-computing"></i>
                <div class="class-name">.flaticon-cloud-computing</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-swipe"></i>
                <div class="class-name">.flaticon-swipe</div>
            </div>
            <div class="glyph">
                <i class="flaticon flaticon-night-mode"></i>
                <div class="class-name">.flaticon-night-mode</div>
            </div>
        </div>
    </mat-card-content>
</mat-card>