<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Sidenav</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">UI Kit</li>
        <li class="breadcrumb-item fw-semibold">Sidenav</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Basic Sidenav</h5>
    </mat-card-header>
    <mat-card-content [class.component-dark-theme]="themeService.isDark()">
        <mat-drawer-container class="example-container" autosize>
            <mat-drawer #drawer class="example-sidenav" mode="side">
                <p>Auto-resizing sidenav</p>
                <p *ngIf="showFiller">Lorem, ipsum dolor sit amet consectetur.</p>
                <button (click)="showFiller = !showFiller" mat-raised-button>
                Toggle extra text
                </button>
            </mat-drawer>
            <div class="example-sidenav-content">
                <button type="button" mat-button (click)="drawer.toggle()">
                Toggle sidenav
                </button>
            </div>
        </mat-drawer-container>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Drawer with Explicit Backdrop Setting</h5>
    </mat-card-header>
    <mat-card-content>
        <app-drawer-ebs></app-drawer-ebs>
    </mat-card-content>
</mat-card>