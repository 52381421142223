<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Search</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Search</li>
    </ol>
</div>

<mat-card class="mb-25 pricing-box tagus-card" [class.component-dark-theme]="themeService.isDark()">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">120 <span class="gray-color fw-medium">results found for</span> “Ipsum”</h5>
    </mat-card-header>
    <mat-card-content>
        <ul class="ps-0 search-list mb-0 mt-0 list-unstyled">
            <li class="border-radius">
                <span class="fw-semibold d-block fs-16 mb-8">What is Lorem Ipsum?</span>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </li>
            <li class="border-radius">
                <span class="fw-semibold d-block fs-16 mb-8">What is Lorem Ipsum?</span>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </li>
            <li class="border-radius">
                <span class="fw-semibold d-block fs-16 mb-8">What is Lorem Ipsum?</span>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </li>
            <li class="border-radius">
                <span class="fw-semibold d-block fs-16 mb-8">What is Lorem Ipsum?</span>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </li>
            <li class="border-radius">
                <span class="fw-semibold d-block fs-16 mb-8">What is Lorem Ipsum?</span>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </li>
            <li class="border-radius">
                <span class="fw-semibold d-block fs-16 mb-8">What is Lorem Ipsum?</span>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </li>
            <li class="border-radius">
                <span class="fw-semibold d-block fs-16 mb-8">What is Lorem Ipsum?</span>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </li>
            <li class="border-radius">
                <span class="fw-semibold d-block fs-16 mb-8">What is Lorem Ipsum?</span>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </li>
            <li class="border-radius">
                <span class="fw-semibold d-block fs-16 mb-8">What is Lorem Ipsum?</span>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </li>
            <li class="border-radius">
                <span class="fw-semibold d-block fs-16 mb-8">What is Lorem Ipsum?</span>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </li>
            <li class="border-radius">
                <span class="fw-semibold d-block fs-16 mb-8">What is Lorem Ipsum?</span>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </li>
        </ul>
    </mat-card-content>
</mat-card>