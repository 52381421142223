import { Component } from '@angular/core';

@Component({
  selector: 'app-basic-date-range',
  templateUrl: './basic-date-range.component.html',
  styleUrls: ['./basic-date-range.component.scss']
})
export class BasicDateRangeComponent {

}
