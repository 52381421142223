<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">File Manager</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">File Manager</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-4 col-xxl-3">
        <app-fm-sidebar></app-fm-sidebar>
    </div>
    <div class="col-lg-8 col-xxl-9">
        <div class="row">
            <div class="col-sm-6 col-xxl-3">
                <mat-card class="mb-25 tagus-card card-box">
                    <mat-card-header class="mb-0">
                        <mat-checkbox class="fm-checkbox"></mat-checkbox>
                        <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="monthMenu">
                            <i class="ri-more-fill"></i>
                        </button>
                        <mat-menu #monthMenu="matMenu" class="dotMenu monthMenu" xPosition="before">
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-edit-line"></i> Rename
                            </button>
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-download-cloud-2-line"></i> Download
                            </button>
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-delete-bin-line"></i> Remove
                            </button>
                        </mat-menu>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="pt-50 pb-50 text-center">
                            <img src="assets/img/icon/folder.png" alt="folder">
                            <span class="d-block fw-semibold mt-10"><a routerLink="/file-manager/projects">Projects</a></span>
                        </div>
                        <div class="d-flex justify-content-space-between align-items-center">
                            <span class="d-block gray-color">387 Files</span>
                            <span class="d-block gray-color">4.5 GB</span>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-sm-6 col-xxl-3">
                <mat-card class="mb-25 tagus-card card-box">
                    <mat-card-header class="mb-0">
                        <mat-checkbox class="fm-checkbox"></mat-checkbox>
                        <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="monthMenu">
                            <i class="ri-more-fill"></i>
                        </button>
                        <mat-menu #monthMenu="matMenu" class="dotMenu monthMenu" xPosition="before">
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-edit-line"></i> Rename
                            </button>
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-download-cloud-2-line"></i> Download
                            </button>
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-delete-bin-line"></i> Remove
                            </button>
                        </mat-menu>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="pt-50 pb-50 text-center">
                            <img src="assets/img/icon/folder.png" alt="folder">
                            <span class="d-block fw-semibold mt-10"><a routerLink="/file-manager/documents">Documents</a></span>
                        </div>
                        <div class="d-flex justify-content-space-between align-items-center">
                            <span class="d-block gray-color">1572 Files</span>
                            <span class="d-block gray-color">7.5 GB</span>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-sm-6 col-xxl-3">
                <mat-card class="mb-25 tagus-card card-box">
                    <mat-card-header class="mb-0">
                        <mat-checkbox class="fm-checkbox"></mat-checkbox>
                        <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="monthMenu">
                            <i class="ri-more-fill"></i>
                        </button>
                        <mat-menu #monthMenu="matMenu" class="dotMenu monthMenu" xPosition="before">
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-edit-line"></i> Rename
                            </button>
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-download-cloud-2-line"></i> Download
                            </button>
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-delete-bin-line"></i> Remove
                            </button>
                        </mat-menu>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="pt-50 pb-50 text-center">
                            <img src="assets/img/icon/folder.png" alt="folder">
                            <span class="d-block fw-semibold mt-10"><a routerLink="/file-manager/media">Media</a></span>
                        </div>
                        <div class="d-flex justify-content-space-between align-items-center">
                            <span class="d-block gray-color">12341 Files</span>
                            <span class="d-block gray-color">2.8 GB</span>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-sm-6 col-xxl-3">
                <mat-card class="mb-25 tagus-card card-box">
                    <mat-card-header class="mb-0">
                        <mat-checkbox class="fm-checkbox"></mat-checkbox>
                        <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="monthMenu">
                            <i class="ri-more-fill"></i>
                        </button>
                        <mat-menu #monthMenu="matMenu" class="dotMenu monthMenu" xPosition="before">
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-edit-line"></i> Rename
                            </button>
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-download-cloud-2-line"></i> Download
                            </button>
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-delete-bin-line"></i> Remove
                            </button>
                        </mat-menu>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="pt-50 pb-50 text-center">
                            <img src="assets/img/icon/folder.png" alt="folder">
                            <span class="d-block fw-semibold mt-10"><a routerLink="/file-manager/applications">Applications</a></span>
                        </div>
                        <div class="d-flex justify-content-space-between align-items-center">
                            <span class="d-block gray-color">2487 Files</span>
                            <span class="d-block gray-color">5.0 GB</span>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <mat-card
            class="mb-25 tagus-card"
            [class.component-rtl-enabled]="themeService.isRTLEnabled()"
            [class.component-dark-theme]="themeService.isDark()"
        >
            <mat-card-header>
                <h5 class="mb-0">Recent Files</h5>
                <button mat-flat-button class="default-btn tagus" (click)="openCreateFolderDialog('300ms', '100ms')">
                    <i class="ri-add-fill"></i> Create Folder
                </button>
            </mat-card-header>
            <mat-card-content>
                <div class="recent-files-table table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="fw-semibold main-color">Name</th>
                                <th class="fw-semibold gray-color">Owner</th>
                                <th class="fw-semibold gray-color">File Size</th>
                                <th class="fw-semibold gray-color">Listed Date</th>
                                <th class="fw-semibold gray-color">File Item</th>
                                <th class="fw-semibold gray-color">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div class="name-info d-flex align-items-center">
                                        <img src="assets/img/icon/folder.png" alt="folder">
                                        <h5 class="fw-semibold mb-0">Product UI/UX Design</h5>
                                    </div>
                                </td>
                                <td class="gray-color">Danielle Thompson</td>
                                <td class="gray-color">0.7 GB</td>
                                <td class="gray-color">Mar 08, 2023</td>
                                <td class="gray-color">02</td>
                                <td>
                                    <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                                        <i class="ri-more-fill"></i>
                                    </button>
                                    <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-share-line"></i> Share
                                        </button>
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-link"></i> Get Sharable Link
                                        </button>
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-edit-line"></i> Rename
                                        </button>
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-download-cloud-line"></i> Download
                                        </button>
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-delete-bin-line"></i> Remove
                                        </button>
                                    </mat-menu>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="name-info d-flex align-items-center">
                                        <img src="assets/img/icon/folder.png" alt="folder">
                                        <h5 class="fw-semibold mb-0">App Design & Development</h5>
                                    </div>
                                </td>
                                <td class="gray-color">Coder Themes</td>
                                <td class="gray-color">521 MB</td>
                                <td class="gray-color">Feb 13, 2023</td>
                                <td class="gray-color">06</td>
                                <td>
                                    <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                                        <i class="ri-more-fill"></i>
                                    </button>
                                    <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-share-line"></i> Share
                                        </button>
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-link"></i> Get Sharable Link
                                        </button>
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-edit-line"></i> Rename
                                        </button>
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-download-cloud-line"></i> Download
                                        </button>
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-delete-bin-line"></i> Remove
                                        </button>
                                    </mat-menu>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="name-info d-flex align-items-center">
                                        <img src="assets/img/icon/folder.png" alt="folder">
                                        <h5 class="fw-semibold mb-0">Ubold-sketch-design.zip</h5>
                                    </div>
                                </td>
                                <td class="gray-color">Gary Coley</td>
                                <td class="gray-color">64.2 MB</td>
                                <td class="gray-color">Dec 18, 2022</td>
                                <td class="gray-color">03</td>
                                <td>
                                    <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                                        <i class="ri-more-fill"></i>
                                    </button>
                                    <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-share-line"></i> Share
                                        </button>
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-link"></i> Get Sharable Link
                                        </button>
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-edit-line"></i> Rename
                                        </button>
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-download-cloud-line"></i> Download
                                        </button>
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-delete-bin-line"></i> Remove
                                        </button>
                                    </mat-menu>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="name-info d-flex align-items-center">
                                        <img src="assets/img/icon/folder.png" alt="folder">
                                        <h5 class="fw-semibold mb-0">Annualreport.pdf</h5>
                                    </div>
                                </td>
                                <td class="gray-color">Cooper Sharwood</td>
                                <td class="gray-color">12.5 GB</td>
                                <td class="gray-color">Nov 29, 2022</td>
                                <td class="gray-color">05</td>
                                <td>
                                    <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                                        <i class="ri-more-fill"></i>
                                    </button>
                                    <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-share-line"></i> Share
                                        </button>
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-link"></i> Get Sharable Link
                                        </button>
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-edit-line"></i> Rename
                                        </button>
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-download-cloud-line"></i> Download
                                        </button>
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-delete-bin-line"></i> Remove
                                        </button>
                                    </mat-menu>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="name-info d-flex align-items-center">
                                        <img src="assets/img/icon/folder.png" alt="folder">
                                        <h5 class="fw-semibold mb-0">Wireframes</h5>
                                    </div>
                                </td>
                                <td class="gray-color">Jasper Rigg</td>
                                <td class="gray-color">8.3 MB</td>
                                <td class="gray-color">Nov 25, 2022</td>
                                <td class="gray-color">01</td>
                                <td>
                                    <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                                        <i class="ri-more-fill"></i>
                                    </button>
                                    <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-share-line"></i> Share
                                        </button>
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-link"></i> Get Sharable Link
                                        </button>
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-edit-line"></i> Rename
                                        </button>
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-download-cloud-line"></i> Download
                                        </button>
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-delete-bin-line"></i> Remove
                                        </button>
                                    </mat-menu>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card class="mb-25 tagus-card">
            <mat-card-header>
                <h5 class="mb-0">Documents</h5>
                <button mat-flat-button class="default-btn tagus" (click)="openCreateDocumentDialog('300ms', '100ms')">
                    <i class="ri-add-fill"></i> Create Document
                </button>
            </mat-card-header>
            <mat-card-content>
                <div class="files-list d-flex flex-wrap">
                    <div class="file-item">
                        <div class="bg-gray text-center mt-15 ps-20 pe-20 pt-50 pb-50 border-radius">
                            <img src="assets/img/icon/jpg.png" alt="jpg">
                            <span class="d-block fw-semibold mt-15 gray-color"><a routerLink="/file-manager/projects" class="gray-color">sketch-design.zip</a></span>
                        </div>
                    </div>
                    <div class="file-item">
                        <div class="bg-gray text-center mt-15 ps-20 pe-20 pt-50 pb-50 border-radius">
                            <img src="assets/img/icon/jpg.png" alt="jpg">
                            <span class="d-block fw-semibold mt-15 gray-color"><a routerLink="/file-manager/projects" class="gray-color">Compile.png</a></span>
                        </div>
                    </div>
                    <div class="file-item">
                        <div class="bg-gray text-center mt-15 ps-20 pe-20 pt-50 pb-50 border-radius">
                            <img src="assets/img/icon/pdf2.png" alt="jpg">
                            <span class="d-block fw-semibold mt-15 gray-color"><a routerLink="/file-manager/projects" class="gray-color">Integrations.pdf</a></span>
                        </div>
                    </div>
                    <div class="file-item">
                        <div class="bg-gray text-center mt-15 ps-20 pe-20 pt-50 pb-50 border-radius">
                            <img src="assets/img/icon/txt.png" alt="jpg">
                            <span class="d-block fw-semibold mt-15 gray-color"><a routerLink="/file-manager/projects" class="gray-color">contact&#64;32.txt</a></span>
                        </div>
                    </div>
                    <div class="file-item">
                        <div class="bg-gray text-center mt-15 ps-20 pe-20 pt-50 pb-50 border-radius">
                            <img src="assets/img/icon/doc.png" alt="jpg">
                            <span class="d-block fw-semibold mt-15 gray-color"><a routerLink="/file-manager/projects" class="gray-color">app-Design.doc</a></span>
                        </div>
                    </div>
                    <div class="file-item">
                        <div class="bg-gray text-center mt-15 ps-20 pe-20 pt-50 pb-50 border-radius">
                            <img src="assets/img/icon/png.png" alt="jpg">
                            <span class="d-block fw-semibold mt-15 gray-color"><a routerLink="/file-manager/projects" class="gray-color">image02.png</a></span>
                        </div>
                    </div>
                    <div class="file-item">
                        <div class="bg-gray text-center mt-15 ps-20 pe-20 pt-50 pb-50 border-radius">
                            <img src="assets/img/icon/doc.png" alt="jpg">
                            <span class="d-block fw-semibold mt-15 gray-color"><a routerLink="/file-manager/projects" class="gray-color">Ubold-sketch.doc</a></span>
                        </div>
                    </div>
                    <div class="file-item">
                        <div class="bg-gray text-center mt-15 ps-20 pe-20 pt-50 pb-50 border-radius">
                            <img src="assets/img/icon/txt.png" alt="jpg">
                            <span class="d-block fw-semibold mt-15 gray-color"><a routerLink="/file-manager/projects" class="gray-color">Annualreport.txt</a></span>
                        </div>
                    </div>
                    <div class="file-item">
                        <div class="bg-gray text-center mt-15 ps-20 pe-20 pt-50 pb-50 border-radius">
                            <img src="assets/img/icon/xl4.png" alt="jpg">
                            <span class="d-block fw-semibold mt-15 gray-color"><a routerLink="/file-manager/projects" class="gray-color">Wireframes.xl4</a></span>
                        </div>
                    </div>
                    <div class="file-item">
                        <div class="bg-gray text-center mt-15 ps-20 pe-20 pt-50 pb-50 border-radius">
                            <img src="assets/img/icon/jpg.png" alt="jpg">
                            <span class="d-block fw-semibold mt-15 gray-color"><a routerLink="/file-manager/projects" class="gray-color">contact&#64;32.jpg</a></span>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>