<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Accordion</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">UI Kit</li>
        <li class="breadcrumb-item fw-semibold">Accordion</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Accordion</h5>
    </mat-card-header>
    <mat-card-content [class.component-dark-theme]="themeService.isDark()">
        <cdk-accordion class="example-accordion">
            <cdk-accordion-item
                *ngFor="let item of items; let index = index;"
                #accordionItem="cdkAccordionItem"
                class="example-accordion-item"
                role="button"
                tabindex="0"
                [attr.id]="'accordion-header-' + index"
                [attr.aria-expanded]="accordionItem.expanded"
                [attr.aria-controls]="'accordion-body-' + index"
            >
                <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
                    {{ item }}
                    <span class="example-accordion-item-description">
                        Click to {{ accordionItem.expanded ? 'close' : 'open' }}
                    </span>
                </div>
                <div
                    class="example-accordion-item-body"
                    role="region"
                    [style.display]="accordionItem.expanded ? '' : 'none'"
                    [attr.id]="'accordion-body-' + index"
                    [attr.aria-labelledby]="'accordion-header-' + index"
                >
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Perferendis excepturi incidunt ipsum deleniti labore, tempore non nam doloribus blanditiis veritatis illo autem iure aliquid ullam rem tenetur deserunt velit culpa? Lorem ipsum dolor, sit amet consectetur adipisicing elit. Perferendis excepturi incidunt ipsum deleniti labore, tempore non nam doloribus blanditiis veritatis illo autem iure aliquid ullam rem tenetur.</p>
                </div>
            </cdk-accordion-item>
        </cdk-accordion>
    </mat-card-content>
</mat-card>