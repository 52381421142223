<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Cards</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">UI Kit</li>
        <li class="breadcrumb-item fw-semibold">Cards</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-content>
                <span class="badge text-outline-tagus me-15">Tagus</span>
                <span class="badge text-outline-primary">Primary</span>
                <h5 class="mb-12 mt-20">Nesta Technologies</h5>
                <p>When you enter into any new area of science, you almost always any new area of science find.</p>
                <button mat-stroked-button class="tagus">Tagus</button>
            </mat-card-content>
        </mat-card>
        <mat-card
            class="mb-25 tagus-card"
            style="background-image: url(assets/img/cards/card2.jpg); background-position: center center; background-repeat: no-repeat; background-size: cover;"
        >
            <mat-card-content>
                <span class="badge text-bg-tagus me-15">Tagus</span>
                <span class="badge text-bg-primary">Primary</span>
                <div style="height: 250px;"></div>
                <h5 class="mb-12 mt-20 text-white">Nesta Technologies</h5>
                <p class="text-white">When you enter into any new area of science, you almost always any new area of science find.</p>
            </mat-card-content>
        </mat-card>
        <mat-card class="mb-25 tagus-card">
            <mat-card-content>
                <span class="badge text-bg-danger me-15">Danger</span>
                <span class="badge text-bg-warning">Warning</span>
                <h5 class="mb-12 mt-20">Nesta Technologies</h5>
                <p>When you enter into any new area of science, you almost always any new area of science find.</p>
                <button mat-flat-button class="gray">Gray</button>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-content>
                <span class="badge text-bg-tagus me-15">Tagus</span>
                <span class="badge text-bg-primary">Primary</span>
                <img src="assets/img/cards/card1.jpg" class="border-radius mt-15" alt="card">
                <h5 class="mb-12 mt-15">Nesta Technologies</h5>
                <p>When you enter into any new area of science, you almost always any new area of science find.</p>
                <button mat-flat-button class="tagus">Tagus</button>
            </mat-card-content>
        </mat-card>
        <mat-card
            class="mb-25 tagus-card"
            style="background-image: url(assets/img/cards/card4.jpg); background-position: center center; background-repeat: no-repeat; background-size: cover;"
        >
            <mat-card-content>
                <span class="badge text-bg-danger me-15">Danger</span>
                <span class="badge text-bg-warning">Warning</span>
                <div style="height: 200px;"></div>
                <h5 class="mb-12 mt-20 text-white">Nesta Technologies</h5>
                <p class="text-white">When you enter into any new area of science, you almost always any new area of science find.</p>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-content>
                <span class="badge text-soft-danger me-15">Danger</span>
                <span class="badge text-soft-warning">Warning</span>
                <h5 class="mb-12 mt-20">Nesta Technologies</h5>
                <p>When you enter into any new area of science, you almost always any new area of science find.</p>
                <button mat-raised-button color="accent">Accent</button>
            </mat-card-content>
        </mat-card>
        <mat-card class="mb-25 tagus-card">
            <mat-card-content>
                <span class="badge text-soft-tagus me-15">Tagus</span>
                <span class="badge text-soft-primary">Primary</span>
                <img src="assets/img/cards/card3.jpg" class="border-radius mt-15" alt="card">
                <h5 class="mb-12 mt-15">Nesta Technologies</h5>
                <p>When you enter into any new area of science, you almost always any new area of science find.</p>
                <button mat-flat-button color="warn">Warn</button>
            </mat-card-content>
        </mat-card>
        <mat-card class="mb-25 tagus-card">
            <mat-card-content>
                <span class="badge text-bg-danger me-15">Danger</span>
                <span class="badge text-bg-warning">Warning</span>
                <h5 class="mb-12 mt-15">Nesta Technologies</h5>
                <p>When you enter into any new area of science, you almost always any new area of science find.</p>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Material Cards</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">UI Kit</li>
        <li class="breadcrumb-item fw-semibold">Material Cards</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25">
            <mat-card-content class="fw-semibold">Simple card</mat-card-content>
        </mat-card>
        <mat-card class="mb-25">
            <mat-card-header>
                <mat-card-subtitle>Card with footer</mat-card-subtitle>
                <mat-card-title class="fw-semibold mb-10 mt-10">Shiba Inu</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p>This card has divider and indeterminate progress as footer</p>
                <p>{{ longText }}</p>
                <mat-divider></mat-divider>
            </mat-card-content>
            <mat-card-actions class="ps-15 mt-10 mb-10">
                <button mat-flat-button class="gray me-15">LIKE</button>
                <button mat-flat-button class="gray">SHARE</button>
            </mat-card-actions>
            <mat-card-footer>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </mat-card-footer>
        </mat-card>
        <mat-card class="mb-25">
            <mat-card-header class="mb-15">
                <mat-card-title-group>
                    <mat-card-title class="fw-semibold mb-10">Card with media size</mat-card-title>
                    <mat-card-subtitle>Small</mat-card-subtitle>
                    <img mat-card-sm-image src="assets/img/recent-orders/product-details1.jpg" >
                </mat-card-title-group>
            </mat-card-header>
            <mat-card-content>
                <p>{{longText}}</p>
            </mat-card-content>
        </mat-card>
        <mat-card class="mb-25">
            <mat-card-header class="mb-15">
                <mat-card-title-group>
                    <mat-card-title class="fw-semibold mb-10">Card with media size</mat-card-title>
                    <mat-card-subtitle>Medium</mat-card-subtitle>
                    <img mat-card-md-image src="assets/img/recent-orders/product-details1.jpg"  alt="Image of a Shiba Inu">
                </mat-card-title-group>
            </mat-card-header>
            <mat-card-content>
                <p>{{longText}}</p>
            </mat-card-content>
        </mat-card>
        <mat-card class="mb-25">
            <mat-card-header class="mb-15">
                <mat-card-title-group>
                    <mat-card-title class="fw-semibold mb-10">Card with media size</mat-card-title>
                    <mat-card-subtitle>Large</mat-card-subtitle>
                    <img mat-card-lg-image src="assets/img/recent-orders/product-details1.jpg" >
                </mat-card-title-group>
            </mat-card-header>
            <mat-card-content>
                <p>{{longText}}</p>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25">
            <mat-card-header>
                <mat-card-title class="fw-semibold mb-10">Card with actions alignment option</mat-card-title>
                <mat-card-subtitle>Start</mat-card-subtitle>
            </mat-card-header>
            <mat-card-actions class="ps-15 mt-10 mb-10">
                <button mat-flat-button class="gray me-15">LIKE</button>
                <button mat-flat-button class="gray">SHARE</button>
            </mat-card-actions>
        </mat-card>
        <mat-card class="mb-25">
            <mat-card-header>
                <mat-card-title class="fw-semibold mb-10">Card with actions alignment option</mat-card-title>
                <mat-card-subtitle>End</mat-card-subtitle>
            </mat-card-header>
            <mat-card-actions align="end" class="pe-15 mb-10">
                <button mat-flat-button class="gray me-15">LIKE</button>
                <button mat-flat-button class="gray">SHARE</button>
            </mat-card-actions>
        </mat-card>
        <mat-card class="mb-25">
            <mat-card-header>
                <mat-card-title class="fw-semibold mb-10">Card with sub-title</mat-card-title>
                <mat-card-subtitle class="mb-10">Dog Breed</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <p>This card indeterminates progress bar.</p>
                <p>{{longText}}</p>
            </mat-card-content>
            <mat-card-actions class="ps-15 mt-10 mb-10">
                <button mat-flat-button class="gray me-15">LIKE</button>
                <button mat-flat-button class="gray">SHARE</button>
            </mat-card-actions>
        </mat-card>
        <mat-card class="mb-25">
            <mat-card-header class="mb-15">
                <mat-card-title-group>
                    <mat-card-title class="fw-semibold mb-10">Card with media size</mat-card-title>
                    <mat-card-subtitle>Extra large</mat-card-subtitle>
                    <img mat-card-xl-image src="assets/img/recent-orders/product-details1.jpg" >
                </mat-card-title-group>
            </mat-card-header>
            <mat-card-content>
                <p>{{longText}}</p>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25">
            <mat-card-header>
                <div mat-card-avatar style="background-image: url(assets/img/user/user1.jpg); background-size: cover;"></div>
                <mat-card-title class="fw-semibold mb-5">Card with multiple sections</mat-card-title>
                <mat-card-subtitle>Dog Breed</mat-card-subtitle>
            </mat-card-header>
            <img mat-card-image src="assets/img/recent-orders/product-details1.jpg" alt="Photo of a Shiba Inu">
            <mat-card-content>
                <div class="pt-15"></div>
                <p>The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan. A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally bred for.</p>
            </mat-card-content>
            <mat-card-actions class="ps-15 mt-10 mb-10">
                <button mat-flat-button class="gray me-15">LIKE</button>
                <button mat-flat-button class="gray">SHARE</button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>