<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Carousels</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">UI Kit</li>
        <li class="breadcrumb-item fw-semibold">Carousels</li>
    </ol>
</div>

<div class="row" [class.component-rtl-enabled]="themeService.isRTLEnabled()">
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Slides Only</h5>
            </mat-card-header>
            <mat-card-content>
                <owl-carousel-o [options]="imageSlides">
                    <ng-template carouselSlide>
                        <img src="assets/img/cards/card5.jpg" alt="card" class="border-radius">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="assets/img/cards/card6.jpg" alt="card" class="border-radius">
                    </ng-template>
                </owl-carousel-o>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">With Dots Controls</h5>
            </mat-card-header>
            <mat-card-content>
                <owl-carousel-o [options]="imageSlides2">
                    <ng-template carouselSlide>
                        <img src="assets/img/cards/card6.jpg" alt="card" class="border-radius">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="assets/img/cards/card5.jpg" alt="card" class="border-radius">
                    </ng-template>
                </owl-carousel-o>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">With Navs Controls</h5>
            </mat-card-header>
            <mat-card-content>
                <owl-carousel-o [options]="imageSlides3">
                    <ng-template carouselSlide>
                        <img src="assets/img/cards/card6.jpg" alt="card" class="border-radius">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="assets/img/cards/card5.jpg" alt="card" class="border-radius">
                    </ng-template>
                </owl-carousel-o>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-12 col-md-12">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Responsive Breakpoints</h5>
            </mat-card-header>
            <mat-card-content>
                <owl-carousel-o [options]="imageSlides4">
                    <ng-template carouselSlide>
                        <img src="assets/img/cards/card6.jpg" alt="card" class="border-radius">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="assets/img/cards/card5.jpg" alt="card" class="border-radius">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="assets/img/cards/card6.jpg" alt="card" class="border-radius">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="assets/img/cards/card5.jpg" alt="card" class="border-radius">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="assets/img/cards/card6.jpg" alt="card" class="border-radius">
                    </ng-template>
                </owl-carousel-o>
            </mat-card-content>
        </mat-card>
    </div>
</div>