<div class="row justify-content-center">
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-content>
                <div class="d-flex align-items-center">
                    <div class="icon me-12 text-center rounded-circle">
                        <i class="ri-money-dollar-circle-line"></i>
                    </div>
                    <h5 class="mb-0"><span>1.3%</span> Up from past week</h5>
                </div>
                <ul class="list ps-0 d-flex align-items-center justify-content-space-between mb-0">
                    <li class="muted-color fw-medium">
                        <span class="d-block">All Orders</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">18k</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Pending Orders</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">5k</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Complited Orders</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">13k</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-content>
                <div class="d-flex align-items-center">
                    <div class="icon me-12 text-center rounded-circle">
                        <i class="ri-bar-chart-fill"></i>
                    </div>
                    <h5 class="mb-0"><span>12.3%</span> Up from past week</h5>
                </div>
                <ul class="list ps-0 d-flex align-items-center justify-content-space-between mb-0">
                    <li class="muted-color fw-medium">
                        <span class="d-block">Canceled</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">2k</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Orders Returned</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">3k</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Damaged</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">1k</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-content>
                <div class="d-flex align-items-center">
                    <div class="icon me-12 text-center rounded-circle">
                        <i class="ri-star-line"></i>
                    </div>
                    <h5 class="mb-0"><span class="down">0.2%</span> Down from past week</h5>
                </div>
                <ul class="list ps-0 d-flex align-items-center justify-content-space-between mb-0">
                    <li class="muted-color fw-medium">
                        <span class="d-block">Abandoned Cart</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">5k</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Customers</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">10k</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">In Progress</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">16k</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
</div>