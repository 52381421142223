<div
    class="h-100vh pt-50 pb-50"
    [class.component-dark-theme]="themeService.isDark()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.card-border-radius]="themeService.isCardBorderRadius()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="tagus-form ms-auto me-auto">
                <div class="title">
                    <div class="d-flex mb-10 align-items-center">
                        <img src="assets/img/albatros/logo-dark.svg">
                    </div>
                </div>
                <form [formGroup]="form" (submit)="onFormSubmit(form)">
                    <div class="bg-white border-radius pt-20 ps-20 pe-20">
                        <app-local-error></app-local-error>
                        <div class="tagus-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold gray-color">Телефон <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <input formControlName="phone" matInput>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['phone'].invalid) || (form.controls['phone'].invalid && form.controls['phone'].touched)">
                                    <ng-container *ngIf="form.controls['phone'].errors?.['required']">
                                        Обов'язкове поле
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['phone'].errors?.['pattern']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="tagus-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold gray-color">Пароль <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <input formControlName="password" matInput [type]="hide ? 'password' : 'text'">
                                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['password'].invalid) || (form.controls['password'].invalid && form.controls['password'].touched)">
                                    <ng-container *ngIf="form.controls['password'].errors?.['required']">
                                        Обов'язкове поле
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <button mat-flat-button class="tagus d-block fw-semibold">Увійти</button>
                </form>
            </div>
        </div>
    </div>
</div>
