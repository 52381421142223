import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ApiService} from "../../../services/api.service";
import {UtilityService} from "../../../services/utility.service";
import {ErrorService} from "../../common/notifications/errors/error.service";
import {SuccessService} from "../../common/notifications/success/success.service";
import {ErrorTypeEnum} from "../../../enums/error-type.enum";
import {DEFAULT_MESSAGES} from "../../../constants/default-messages";
import {EndpointTypeEnum} from "../../../enums/endpoint-type.enum";

@Component({
  selector: 'app-main-settings',
  templateUrl: './main-settings.component.html',
  styleUrl: './main-settings.component.scss'
})
export class MainSettingsComponent {
    form!: FormGroup;
    formWasSubmitted = false;
    settings: any;

    constructor(
        private router: Router,
        private apiService: ApiService,
        private formBuilder: FormBuilder,
        private errorService: ErrorService,
        private successService: SuccessService,
    ) {
        this.initForm();
        this.getSettings();
    }

    // инициализация формы
    initForm(): void {
        // инициализируем поля из формы и валидируем
        this.form = this.formBuilder.group({
            min_quantity_notify: ['', [Validators.pattern(/^[0-9]+$/)]],
            min_order_sum: ['', [Validators.pattern(/^[0-9]+$/)]],
        });
    }

    // получение данных для формы
    getSettings() {
        this.apiService
            .get(EndpointTypeEnum.Main, '/settings', true)
            .subscribe({
                next: (response: any) => {
                    this.settings = response.data;
                    // установка значения полей
                    this.form.patchValue({
                        min_quantity_notify: this.settings.min_quantity_notify,
                        min_order_sum: this.settings.min_order_sum,
                    });
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    // отправка формы
    onFormSubmit(form: FormGroup): void {
        this.formWasSubmitted = true;

        // если есть ошибки валидации, не даем отправить форму
        if (form.invalid) {
            this.errorService.addError(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.FORM_ERROR]);
            return;
        }

        // получаем значения из формы
        const formValue = form.value;
        formValue.min_quantity_notify = parseInt(formValue.min_quantity_notify, 10);
        formValue.min_order_sum = parseInt(formValue.min_order_sum, 10);

        // отправка формы
        this.apiService
            .put(EndpointTypeEnum.Main, '/settings', true, form.value)
            .subscribe({
                next: (response: any) => {
                    // this.router.navigate(['/main/catalog/categories']);
                    this.successService.add(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.SUCCESS]);
                },
                error: (err: any) => {
                    this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                },
            });
    }
}
