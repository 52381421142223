<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Testimonials</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Testimonials</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Testimonials - 1</h5>
    </mat-card-header>
    <mat-card-content>
        <div class="row justify-content-center testimonials-list">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-box bg-gray mb-25 border-radius">
                    <img src="assets/img/user/user6.jpg" class="rounded-circle mb-15" width="100" alt="user">
                    <h5 class="mb-10">Alina Smith</h5>
                    <span class="d-block gray-color mb-12 fs-14">Web Developer</span>
                    <div class="rating mb-12">
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                    </div>
                    <p>"We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-box bg-gray mb-25 border-radius">
                    <img src="assets/img/user/user7.jpg" class="rounded-circle mb-15" width="100" alt="user">
                    <h5 class="mb-10">James Andy</h5>
                    <span class="d-block gray-color mb-12 fs-14">Web Designer</span>
                    <div class="rating mb-12">
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-2"></i>
                    </div>
                    <p>"We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-box bg-gray mb-25 border-radius">
                    <img src="assets/img/user/user8.jpg" class="rounded-circle mb-15" width="100" alt="user">
                    <h5 class="mb-10">Sarah Taylor</h5>
                    <span class="d-block gray-color mb-12 fs-14">Web Developer</span>
                    <div class="rating mb-12">
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star"></i>
                    </div>
                    <p>"We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."</p>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Testimonials - 2</h5>
    </mat-card-header>
    <mat-card-content>
        <div class="row justify-content-center testimonials-list">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-box bg-gray text-center mb-25 border-radius">
                    <img src="assets/img/user/user9.jpg" class="rounded-circle mb-15" width="100" alt="user">
                    <h5 class="mb-10">Alina Smith</h5>
                    <span class="d-block gray-color mb-12 fs-14">Web Developer</span>
                    <div class="rating mb-12">
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                    </div>
                    <p>"We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-box bg-gray text-center mb-25 border-radius">
                    <img src="assets/img/user/user10.jpg" class="rounded-circle mb-15" width="100" alt="user">
                    <h5 class="mb-10">James Andy</h5>
                    <span class="d-block gray-color mb-12 fs-14">Web Designer</span>
                    <div class="rating mb-12">
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-2"></i>
                    </div>
                    <p>"We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-box bg-gray text-center mb-25 border-radius">
                    <img src="assets/img/user/user11.jpg" class="rounded-circle mb-15" width="100" alt="user">
                    <h5 class="mb-10">Sarah Taylor</h5>
                    <span class="d-block gray-color mb-12 fs-14">Web Developer</span>
                    <div class="rating mb-12">
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star"></i>
                    </div>
                    <p>"We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."</p>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Testimonials - 3</h5>
    </mat-card-header>
    <mat-card-content>
        <div class="row justify-content-center testimonials-list">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-box bg-gray mb-25 border-radius">
                    <div class="d-flex align-items-center mb-15">
                        <img src="assets/img/user/user12.jpg" class="rounded-circle" width="100" alt="user">
                        <div class="title ms-20">
                            <h5 class="mb-8">Alina Smith</h5>
                            <span class="d-block gray-color mb-12 fs-14">Web Developer</span>
                            <div class="rating">
                                <i class="flaticon-star-1"></i>
                                <i class="flaticon-star-1"></i>
                                <i class="flaticon-star-1"></i>
                                <i class="flaticon-star-1"></i>
                                <i class="flaticon-star-1"></i>
                            </div>
                        </div>
                    </div>
                    <p>"We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-box bg-gray mb-25 border-radius">
                    <div class="d-flex align-items-center mb-15">
                        <img src="assets/img/user/user13.jpg" class="rounded-circle" width="100" alt="user">
                        <div class="title ms-20">
                            <h5 class="mb-8">James Andy</h5>
                            <span class="d-block gray-color mb-12 fs-14">Web Designer</span>
                            <div class="rating">
                                <i class="flaticon-star-1"></i>
                                <i class="flaticon-star-1"></i>
                                <i class="flaticon-star-1"></i>
                                <i class="flaticon-star-1"></i>
                                <i class="flaticon-star-2"></i>
                            </div>
                        </div>
                    </div>
                    <p>"We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-box bg-gray mb-25 border-radius">
                    <div class="d-flex align-items-center mb-15">
                        <img src="assets/img/user/user14.jpg" class="rounded-circle" width="100" alt="user">
                        <div class="title ms-20">
                            <h5 class="mb-8">Sarah Taylor</h5>
                            <span class="d-block gray-color mb-12 fs-14">Web Developer</span>
                            <div class="rating">
                                <i class="flaticon-star-1"></i>
                                <i class="flaticon-star-1"></i>
                                <i class="flaticon-star-1"></i>
                                <i class="flaticon-star-1"></i>
                                <i class="flaticon-star"></i>
                            </div>
                        </div>
                    </div>
                    <p>"We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."</p>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Testimonials - 4</h5>
    </mat-card-header>
    <mat-card-content>
        <div class="row justify-content-center testimonials-list">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-box bg-gray mb-25 border-radius">
                    <div class="d-flex align-items-center mb-20">
                        <img src="assets/img/user/user15.jpg" class="rounded-circle" width="70" alt="user">
                        <div class="title ms-20">
                            <h5 class="mb-8">Alina Smith</h5>
                            <span class="d-block gray-color fs-14">Web Developer</span>
                        </div>
                    </div>
                    <div class="rating mb-10">
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                    </div>
                    <p><i>"We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."</i></p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-box bg-gray mb-25 border-radius">
                    <div class="d-flex align-items-center mb-20">
                        <img src="assets/img/user/user16.jpg" class="rounded-circle" width="70" alt="user">
                        <div class="title ms-20">
                            <h5 class="mb-8">James Andy</h5>
                            <span class="d-block gray-color fs-14">Web Designer</span>
                        </div>
                    </div>
                    <div class="rating mb-10">
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-2"></i>
                    </div>
                    <p><i>"We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."</i></p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-box bg-gray mb-25 border-radius">
                    <div class="d-flex align-items-center mb-20">
                        <img src="assets/img/user/user17.jpg" class="rounded-circle" width="70" alt="user">
                        <div class="title ms-20">
                            <h5 class="mb-8">Sarah Taylor</h5>
                            <span class="d-block gray-color fs-14">Web Developer</span>
                        </div>
                    </div>
                    <div class="rating mb-10">
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star"></i>
                    </div>
                    <p><i>"We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."</i></p>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Testimonials - 5</h5>
    </mat-card-header>
    <mat-card-content>
        <div class="row justify-content-center testimonials-list">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-box mb-25 border-radius p-0">
                    <img src="assets/img/user/user18.jpg" class="rounded-circle mb-15" width="100" alt="user">
                    <h5 class="mb-10">Alina Smith</h5>
                    <span class="d-block gray-color mb-12 fs-14">Web Developer</span>
                    <div class="rating mb-12">
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                    </div>
                    <p>"We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-box mb-25 border-radius p-0">
                    <img src="assets/img/user/user19.jpg" class="rounded-circle mb-15" width="100" alt="user">
                    <h5 class="mb-10">James Andy</h5>
                    <span class="d-block gray-color mb-12 fs-14">Web Designer</span>
                    <div class="rating mb-12">
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-2"></i>
                    </div>
                    <p>"We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-box mb-25 border-radius p-0">
                    <img src="assets/img/user/user20.jpg" class="rounded-circle mb-15" width="100" alt="user">
                    <h5 class="mb-10">Sarah Taylor</h5>
                    <span class="d-block gray-color mb-12 fs-14">Web Developer</span>
                    <div class="rating mb-12">
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star"></i>
                    </div>
                    <p>"We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."</p>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Testimonials - 6</h5>
    </mat-card-header>
    <mat-card-content>
        <div class="row justify-content-center testimonials-list">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-box p-0 text-center mb-25 border-radius">
                    <img src="assets/img/user/user6.jpg" class="rounded-circle mb-15" width="100" alt="user">
                    <h5 class="mb-10">Alina Smith</h5>
                    <span class="d-block gray-color mb-12 fs-14">Web Developer</span>
                    <div class="rating mb-12">
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                    </div>
                    <p>"We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-box p-0 text-center mb-25 border-radius">
                    <img src="assets/img/user/user7.jpg" class="rounded-circle mb-15" width="100" alt="user">
                    <h5 class="mb-10">James Andy</h5>
                    <span class="d-block gray-color mb-12 fs-14">Web Designer</span>
                    <div class="rating mb-12">
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-2"></i>
                    </div>
                    <p>"We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-box p-0 text-center mb-25 border-radius">
                    <img src="assets/img/user/user8.jpg" class="rounded-circle mb-15" width="100" alt="user">
                    <h5 class="mb-10">Sarah Taylor</h5>
                    <span class="d-block gray-color mb-12 fs-14">Web Developer</span>
                    <div class="rating mb-12">
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star-1"></i>
                        <i class="flaticon-star"></i>
                    </div>
                    <p>"We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."</p>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>