<div class="chart">
    <apx-chart
        [series]="chartOptions.series!"
        [chart]="chartOptions.chart!"
        [legend]="chartOptions.legend!"
        [dataLabels]="chartOptions.dataLabels!"
        [grid]="chartOptions.grid!"
        [plotOptions]="chartOptions.plotOptions!"
        [yaxis]="chartOptions.yaxis!"
        [xaxis]="chartOptions.xaxis!"
    ></apx-chart>
</div>