<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Members Grid</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Contacts</li>
        <li class="breadcrumb-item fw-semibold">Members Grid</li>
    </ol>
</div>

<div class="row" [class.component-rtl-enabled]="themeService.isRTLEnabled()">
    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
        <mat-card class="mb-25 text-center tagus-card">
            <mat-card-content>
                <img src="assets/img/user/user12.jpg" class="rounded-circle mb-15" width="150" alt="user">
                <h5 class="mb-5 fw-semibold">Alvarado Turner</h5>
                <span class="d-block muted-color">&#64;alvaradoturner</span>
                <div class="btn-box">
                    <a mat-flat-button href="mailto:alvaradoturner&#64;tagus.com" class="tagus">Message</a>
                    <button mat-flat-button class="gray">Projects</button>
                </div>
                <ul class="list d-flex align-items-center justify-content-space-between text-center mb-0">
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Post</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">18k</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Followers</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">5.21k</span>
                    </li>
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Followings</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">32k</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
        <mat-card class="mb-25 text-center tagus-card">
            <mat-card-content>
                <img src="assets/img/user/user13.jpg" class="rounded-circle mb-15" width="150" alt="user">
                <h5 class="mb-5 fw-semibold">Evangelina Mcclain</h5>
                <span class="d-block muted-color">&#64;evangelinamcclain</span>
                <div class="btn-box">
                    <a mat-flat-button href="mailto:evangelinamcclain&#64;tagus.com" class="tagus">Message</a>
                    <button mat-flat-button class="gray">Projects</button>
                </div>
                <ul class="list d-flex align-items-center justify-content-space-between text-center mb-0">
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Post</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">10k</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Followers</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">1.11k</span>
                    </li>
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Followings</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">12k</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
        <mat-card class="mb-25 text-center tagus-card">
            <mat-card-content>
                <img src="assets/img/user/user11.jpg" class="rounded-circle mb-15" width="150" alt="user">
                <h5 class="mb-5 fw-semibold">Candice Munoz</h5>
                <span class="d-block muted-color">&#64;candicemunoz</span>
                <div class="btn-box">
                    <a mat-flat-button href="mailto:candicemunoz&#64;tagus.com" class="tagus">Message</a>
                    <button mat-flat-button class="gray">Projects</button>
                </div>
                <ul class="list d-flex align-items-center justify-content-space-between text-center mb-0">
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Post</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">28k</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Followers</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">2.12k</span>
                    </li>
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Followings</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">21k</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
        <mat-card class="mb-25 text-center tagus-card">
            <mat-card-content>
                <img src="assets/img/user/user14.jpg" class="rounded-circle mb-15" width="150" alt="user">
                <h5 class="mb-5 fw-semibold">Bernard Langley</h5>
                <span class="d-block muted-color">&#64;bernardlangley</span>
                <div class="btn-box">
                    <a mat-flat-button href="mailto:bernardlangley&#64;tagus.com" class="tagus">Message</a>
                    <button mat-flat-button class="gray">Projects</button>
                </div>
                <ul class="list d-flex align-items-center justify-content-space-between text-center mb-0">
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Post</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">21k</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Followers</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">1.21k</span>
                    </li>
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Followings</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">42k</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
        <mat-card class="mb-25 text-center tagus-card">
            <mat-card-content>
                <img src="assets/img/user/user8.jpg" class="rounded-circle mb-15" width="150" alt="user">
                <h5 class="mb-5 fw-semibold">Kristie Hall</h5>
                <span class="d-block muted-color">&#64;kristiehall</span>
                <div class="btn-box">
                    <a mat-flat-button href="mailto:kristiehall&#64;tagus.com" class="tagus">Message</a>
                    <button mat-flat-button class="gray">Projects</button>
                </div>
                <ul class="list d-flex align-items-center justify-content-space-between text-center mb-0">
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Post</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">20k</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Followers</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">5k</span>
                    </li>
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Followings</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">52k</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
        <mat-card class="mb-25 text-center tagus-card">
            <mat-card-content>
                <img src="assets/img/user/user23.jpg" class="rounded-circle mb-15" width="150" alt="user">
                <h5 class="mb-5 fw-semibold">Bolton Obrien</h5>
                <span class="d-block muted-color">&#64;boltonobrien</span>
                <div class="btn-box">
                    <a mat-flat-button href="mailto:boltonobrien&#64;tagus.com" class="tagus">Message</a>
                    <button mat-flat-button class="gray">Projects</button>
                </div>
                <ul class="list d-flex align-items-center justify-content-space-between text-center mb-0">
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Post</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">5k</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Followers</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">1.11k</span>
                    </li>
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Followings</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">11k</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
        <mat-card class="mb-25 text-center tagus-card">
            <mat-card-content>
                <img src="assets/img/user/user18.jpg" class="rounded-circle mb-15" width="150" alt="user">
                <h5 class="mb-5 fw-semibold">Dee Alvarado</h5>
                <span class="d-block muted-color">&#64;deealvarado</span>
                <div class="btn-box">
                    <a mat-flat-button href="mailto:deealvarado&#64;tagus.com" class="tagus">Message</a>
                    <button mat-flat-button class="gray">Projects</button>
                </div>
                <ul class="list d-flex align-items-center justify-content-space-between text-center mb-0">
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Post</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">18k</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Followers</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">5.21k</span>
                    </li>
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Followings</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">32k</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
        <mat-card class="mb-25 text-center tagus-card">
            <mat-card-content>
                <img src="assets/img/user/user22.jpg" class="rounded-circle mb-15" width="150" alt="user">
                <h5 class="mb-5 fw-semibold">Cervantes Kramer</h5>
                <span class="d-block muted-color">&#64;cervanteskramer</span>
                <div class="btn-box">
                    <a mat-flat-button href="mailto:cervanteskramer&#64;tagus.com" class="tagus">Message</a>
                    <button mat-flat-button class="gray">Projects</button>
                </div>
                <ul class="list d-flex align-items-center justify-content-space-between text-center mb-0">
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Post</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">8k</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Followers</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">3k</span>
                    </li>
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Followings</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">10k</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
        <mat-card class="mb-25 text-center tagus-card">
            <mat-card-content>
                <img src="assets/img/user/user16.jpg" class="rounded-circle mb-15" width="150" alt="user">
                <h5 class="mb-5 fw-semibold">Dejesus Michael</h5>
                <span class="d-block muted-color">&#64;dejesusmichael</span>
                <div class="btn-box">
                    <a mat-flat-button href="mailto:dejesusmichael&#64;tagus.com" class="tagus">Message</a>
                    <button mat-flat-button class="gray">Projects</button>
                </div>
                <ul class="list d-flex align-items-center justify-content-space-between text-center mb-0">
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Post</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">9k</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Followers</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">21k</span>
                    </li>
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Followings</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">42k</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
        <mat-card class="mb-25 text-center tagus-card">
            <mat-card-content>
                <img src="assets/img/user/user15.jpg" class="rounded-circle mb-15" width="150" alt="user">
                <h5 class="mb-5 fw-semibold">Alissa Nelson</h5>
                <span class="d-block muted-color">&#64;alissanelson</span>
                <div class="btn-box">
                    <a mat-flat-button href="mailto:alissanelson&#64;tagus.com" class="tagus">Message</a>
                    <button mat-flat-button class="gray">Projects</button>
                </div>
                <ul class="list d-flex align-items-center justify-content-space-between text-center mb-0">
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Post</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">5k</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Followers</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">5k</span>
                    </li>
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Followings</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">5k</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
        <mat-card class="mb-25 text-center tagus-card">
            <mat-card-content>
                <img src="assets/img/user/user9.jpg" class="rounded-circle mb-15" width="150" alt="user">
                <h5 class="mb-5 fw-semibold">English Haney</h5>
                <span class="d-block muted-color">&#64;englishhaney</span>
                <div class="btn-box">
                    <a mat-flat-button href="mailto:englishhaney&#64;tagus.com" class="tagus">Message</a>
                    <button mat-flat-button class="gray">Projects</button>
                </div>
                <ul class="list d-flex align-items-center justify-content-space-between text-center mb-0">
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Post</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">10k</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Followers</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">4.10k</span>
                    </li>
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Followings</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">13k</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
        <mat-card class="mb-25 text-center tagus-card">
            <mat-card-content>
                <img src="assets/img/user/user17.jpg" class="rounded-circle mb-15" width="150" alt="user">
                <h5 class="mb-5 fw-semibold">Edwards Mckenzie</h5>
                <span class="d-block muted-color">&#64;edwardsmckenzie</span>
                <div class="btn-box">
                    <a mat-flat-button href="mailto:edwardsmckenzie&#64;tagus.com" class="tagus">Message</a>
                    <button mat-flat-button class="gray">Projects</button>
                </div>
                <ul class="list d-flex align-items-center justify-content-space-between text-center mb-0">
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Post</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">18k</span>
                    </li>
                    <li class="muted-color fw-medium">
                        <span class="d-block">Followers</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">5.21k</span>
                    </li>
                    <li class="muted-color fw-medium down">
                        <span class="d-block">Followings</span>
                        <span class="gray-color d-inline-block position-relative fw-semibold">32k</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
</div>