import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ApiService} from "../../../../services/api.service";
import {ErrorService} from "../../../common/notifications/errors/error.service";
import {SuccessService} from "../../../common/notifications/success/success.service";
import {EndpointTypeEnum} from "../../../../enums/endpoint-type.enum";
import {ErrorTypeEnum} from "../../../../enums/error-type.enum";
import {DEFAULT_MESSAGES} from "../../../../constants/default-messages";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-payment-method-create',
  templateUrl: './payment-method-create.component.html',
  styleUrl: './payment-method-create.component.scss'
})
export class PaymentMethodCreateComponent {
    backendUrl: string = environment.backendUrl;
    form!: FormGroup;
    formWasSubmitted = false;
    image: any;

    constructor(
        private router: Router,
        private apiService: ApiService,
        private formBuilder: FormBuilder,
        private errorService: ErrorService,
        private successService: SuccessService,
    ) {
        this.initForm();
    }

    // инициализация формы
    initForm(): void {
        // инициализируем поля из формы и валидируем
        this.form = this.formBuilder.group({
            name_ua: ['', [Validators.required]],
            name_ru: ['', [Validators.required]],
            is_paid: ['1', Validators.required],
            description_ua: ['', [Validators.required]],
            description_ru: ['', [Validators.required]],
            is_active: ['1', Validators.required],
        });
    }

    // отправка формы
    onFormSubmit(form: FormGroup): void {
        this.formWasSubmitted = true;

        // если есть ошибки валидации, не даем отправить форму
        if (form.invalid) {
            return;
        }

        // получаем значения из формы
        const formValue = form.value;
        formValue.image = this.image;

        // отправка формы
        this.apiService
            .post(EndpointTypeEnum.Main, '/payment-methods', true, form.value)
            .subscribe({
                next: (response: any) => {
                    this.router.navigate(['/main/sales/payment-methods']);
                    this.successService.add(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.SUCCESS]);
                },
                error: (err: any) => {
                    this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                },
            });
    }

    uploadImage(event: any) {
        if (event.target.files.length !== 0) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('image', file);
            this.apiService
                .postMultipartFormData(EndpointTypeEnum.Requests, '/upload-files', true, formData)
                .subscribe({
                    next: (response: any) => {
                        this.image = response.data;
                        this.errorService.clearErrors(ErrorTypeEnum.Local);
                    },
                    error: (err: any) => {
                        this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                    },
                });
        } else {
            this.image = '';
        }
    }
}
