<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Forms Layout</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Forms Layout</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header>
        <h5 class="mb-0">Textual Inputs</h5>
    </mat-card-header>
    <mat-card-content>
        <form>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">First Name</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-user-3-line"></i>
                            <mat-label>Enter name</mat-label>
                            <input matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Last Name</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-user-3-line"></i>
                            <mat-label>Enter name</mat-label>
                            <input matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Email Address <span>*</span></label>
                        <mat-form-field appearance="fill">
                            <i class="ri-mail-line"></i>
                            <mat-label>Enter email address</mat-label>
                            <input matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Phone</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-phone-line"></i>
                            <mat-label>Enter phone number</mat-label>
                            <input matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Address</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-map-pin-line"></i>
                            <mat-label>Enter location</mat-label>
                            <input matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Country</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-map-2-line"></i>
                            <mat-label>Enter country</mat-label>
                            <input matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="tagus-form-group without-icon">
                        <label class="d-block mb-12 fw-semibold gray-color">Town/City</label>
                        <mat-form-field appearance="fill">
                            <mat-label>Enter town/city</mat-label>
                            <mat-select>
                                <mat-option value="1">London</mat-option>
                                <mat-option value="2">Tokyo</mat-option>
                                <mat-option value="3">New York</mat-option>
                                <mat-option value="4">Beijing</mat-option>
                                <mat-option value="5">Paris</mat-option>
                                <mat-option value="6">Osaka</mat-option>
                                <mat-option value="6">Chicago</mat-option>
                                <mat-option value="6">İstanbul</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="tagus-form-group without-icon">
                        <label class="d-block mb-12 fw-semibold gray-color">State</label>
                        <mat-form-field appearance="fill">
                            <mat-label>Enter state</mat-label>
                            <mat-select>
                                <mat-option value="1">London</mat-option>
                                <mat-option value="2">Tokyo</mat-option>
                                <mat-option value="3">New York</mat-option>
                                <mat-option value="4">Beijing</mat-option>
                                <mat-option value="5">Paris</mat-option>
                                <mat-option value="6">Osaka</mat-option>
                                <mat-option value="6">Chicago</mat-option>
                                <mat-option value="6">İstanbul</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="tagus-form-group without-icon">
                        <label class="d-block mb-12 fw-semibold gray-color">Zip Code</label>
                        <mat-form-field appearance="fill">
                            <mat-label>Enter zip code</mat-label>
                            <input matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Description</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-information-line"></i>
                            <mat-label>Some description text...</mat-label>
                            <textarea matInput rows="6"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </mat-card-content>
</mat-card>

<div class="row">
    <div class="col-lg-6 col-md-12">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header>
                <h5 class="mb-0">Basic Form</h5>
            </mat-card-header>
            <mat-card-content>
                <div class="tagus-form-group without-icon">
                    <label class="d-block mb-12 fw-semibold gray-color">Name</label>
                    <mat-form-field appearance="fill">
                        <mat-label>Enter name</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="tagus-form-group without-icon">
                    <label class="d-block mb-12 fw-semibold gray-color">Email Address <span>*</span></label>
                    <mat-form-field appearance="fill">
                        <mat-label>Enter email address</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="tagus-form-group without-icon">
                    <label class="d-block mb-12 fw-semibold gray-color">Password <span>*</span></label>
                    <mat-form-field appearance="fill">
                        <mat-label>Enter your password</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'">
                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <div class="tagus-form-group mb-12">
                    <mat-checkbox>Remember me</mat-checkbox>
                </div>
                <button mat-flat-button class="tagus fw-semibold">Login Now</button>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-12">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header>
                <h5 class="mb-0">Custom Styles Form</h5>
            </mat-card-header>
            <mat-card-content>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="tagus-form-group custom-style">
                            <mat-form-field appearance="fill">
                                <mat-label>Enter name</mat-label>
                                <input matInput>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="tagus-form-group custom-style">
                            <mat-form-field appearance="fill">
                                <mat-label>Enter email address</mat-label>
                                <input matInput>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="tagus-form-group custom-style">
                            <mat-form-field appearance="fill">
                                <mat-label>Some description text...</mat-label>
                                <textarea matInput rows="6"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="text-end">
                    <button mat-flat-button class="gray fw-semibold">Send Message</button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div class="row">
    <div class="col-lg-6 col-md-12">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header>
                <h5 class="mb-0">Search Box</h5>
            </mat-card-header>
            <mat-card-content>
                <form class="tagus-search-box">
                    <input type="text" class="input-search border-radius" placeholder="Search here">
                    <button type="submit"><i class="ri-search-line"></i></button>
                </form>
                <div class="mb-15"></div>
                <form class="tagus-search-box">
                    <input type="text" class="input-search" placeholder="Search here">
                    <button type="submit" class="bg-color"><i class="ri-search-line"></i></button>
                </form>
                <div class="mb-15"></div>
                <form class="tagus-search-box">
                    <input type="text" class="input-search" placeholder="Search here">
                    <button mat-flat-button class="tagus">Search</button>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>