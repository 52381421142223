import { Component } from '@angular/core';

@Component({
  selector: 'app-dp-open-method',
  templateUrl: './dp-open-method.component.html',
  styleUrls: ['./dp-open-method.component.scss']
})
export class DpOpenMethodComponent {

}
