import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {Router} from "@angular/router";
import {ApiService} from "../../../../services/api.service";
import {ErrorService} from "../../../common/notifications/errors/error.service";
import {SuccessService} from "../../../common/notifications/success/success.service";
import {ErrorTypeEnum} from "../../../../enums/error-type.enum";
import {EndpointTypeEnum} from "../../../../enums/endpoint-type.enum";
import {DEFAULT_MESSAGES} from "../../../../constants/default-messages";

@Component({
  selector: 'app-main-unit-list',
  templateUrl: './main-unit-list.component.html',
  styleUrl: './main-unit-list.component.scss'
})
export class MainUnitListComponent implements OnInit, OnDestroy, AfterViewInit {
    displayedColumns: string[] = ['name', 'action'];
    ELEMENT_DATA: PeriodicElement[] = [];
    dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
    units: any[] = [];
    showConfirmation = false;
    selectedUnitId: any;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    constructor(
        private router: Router,
        private apiService: ApiService,
        private errorService: ErrorService,
        private successService: SuccessService,
    ) {}

    ngOnInit() {
        this.load();
    }

    ngOnDestroy() {
        this.successService.clear(ErrorTypeEnum.Global);
        this.successService.clear(ErrorTypeEnum.Local);
        this.errorService.clearErrors(ErrorTypeEnum.Global);
        this.errorService.clearErrors(ErrorTypeEnum.Local);
    }

    load() {
        this.apiService
            .get(EndpointTypeEnum.Main, '/units', true)
            .subscribe({
                next: (response: any) => {
                    // присваиваем данные переменной ELEMENT_DATA
                    this.ELEMENT_DATA = response.data;
                    // обновляем данные в MatTableDataSource
                    this.dataSource.data = this.ELEMENT_DATA;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    remove(unitId: string) {
        this.showConfirmation = true;
        this.selectedUnitId = unitId;
    }

    onConfirmationRemove(confirmed: boolean) {
        if (confirmed) {
            this.apiService.delete(EndpointTypeEnum.Main, '/units', true, this.selectedUnitId)
                .subscribe({
                    next: (response: any) => {
                        this.load();
                        this.successService.add(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.SUCCESS]);
                    },
                    error: (err) => {
                        this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                    },
                });
            this.showConfirmation = false;
        } else {
            this.showConfirmation = false;
            this.selectedUnitId = null;
        }
    }
}

export interface PeriodicElement {
    name_ua: string;
    name_ru: string;
}
