<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Категорії на головнiй</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Дашборд
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Налаштування</li>
        <li class="breadcrumb-item fw-semibold">Категорії на головнiй</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header>
        <h5 class="mb-0">Категорії на головнiй</h5>
        <div class="d-flex align-items-center">
            <button mat-raised-button class="tagus small margin-right-10px" form="form" type="submit">Зберегти</button>
<!--            <a class="small" mat-stroked-button routerLink="/main/settings/categories-home" color="warn">Вийти</a>-->
        </div>
    </mat-card-header>
    <mat-card-content>
        <app-local-success></app-local-success>
        <app-local-error></app-local-error>
        <form [formGroup]="form" (submit)="onFormSubmit(form)" id="form">
            <div *ngFor="let addedCategory of addedCategories; let i = index">
                <div class="row" style="margin: 0">
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <mat-form-field appearance="fill" class="width-100">
                            <mat-label>Категорiя</mat-label>
                            <mat-select [(value)]="addedCategories[i].category" (selectionChange)="onUpdateCategory(i, 'category', $event)">
                                <mat-option *ngFor="let category of categoriesForDropdown" [value]="category._id">
                                    {{category.name_ua}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <mat-form-field class="width-100">
                            <mat-label>Заголовок (укр)</mat-label>
                            <input matInput (change)="onUpdateCategory(i, 'header_ua', $event)" [value]="addedCategories[i].header_ua || null">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <mat-form-field class="width-100">
                            <mat-label>Заголовок (рус)</mat-label>
                            <input matInput (change)="onUpdateCategory(i, 'header_ru', $event)" [value]="addedCategories[i].header_ru || null">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="width-100">
                            <label class="d-block mb-12 fw-semibold gray-color">Зображення (укр) ПК</label>
                            <input type="file" accept="image/*" (change)="uploadImage(i, $event, 'image_pc_ua')">
                            <br/>
                            <img *ngIf="addedCategories[i].image_pc_ua" [src]="backendUrl + '/uploads/' + addedCategories[i].image_pc_ua" class="border-radius mt-15" style="height:150px">
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="width-100">
                            <label class="d-block mb-12 fw-semibold gray-color">Зображення (укр) Моб</label>
                            <input type="file" accept="image/*" (change)="uploadImage(i, $event, 'image_mob_ua')">
                            <br/>
                            <img *ngIf="addedCategories[i].image_mob_ua" [src]="backendUrl + '/uploads/' + addedCategories[i].image_mob_ua" class="border-radius mt-15" style="height:150px">
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <mat-form-field class="width-100">
                            <mat-label>Посилання (укр) - починаючи з http/https</mat-label>
                            <input matInput (change)="onUpdateCategory(i, 'link_ua', $event)" [value]="addedCategories[i].link_ua || null">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="width-100">
                            <label class="d-block mb-12 fw-semibold gray-color">Зображення (рус) ПК</label>
                            <input type="file" accept="image/*" (change)="uploadImage(i, $event, 'image_pc_ru')">
                            <br/>
                            <img *ngIf="addedCategories[i].image_pc_ru" [src]="backendUrl + '/uploads/' + addedCategories[i].image_pc_ru" class="border-radius mt-15" style="height:150px">
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="width-100">
                            <label class="d-block mb-12 fw-semibold gray-color">Зображення (рус) Моб</label>
                            <input type="file" accept="image/*" (change)="uploadImage(i, $event, 'image_mob_ru')">
                            <br/>
                            <img *ngIf="addedCategories[i].image_mob_ru" [src]="backendUrl + '/uploads/' + addedCategories[i].image_mob_ru" class="border-radius mt-15" style="height:150px">
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <mat-form-field class="width-100">
                            <mat-label>Посилання (рус) - починаючи з http/https</mat-label>
                            <input matInput (change)="onUpdateCategory(i, 'link_ru', $event)" [value]="addedCategories[i].link_ru || null">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <mat-form-field class="width-100">
                            <mat-label>Кількість</mat-label>
                            <input matInput type="number" (change)="onUpdateCategory(i, 'count', $event)" [value]="addedCategories[i].count || null">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <mat-form-field class="width-100">
                            <mat-label>Позицiя</mat-label>
                            <input matInput type="number" (change)="onUpdateCategory(i, 'position', $event)" [value]="addedCategories[i].position || null">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <button mat-stroked-button color="warn" (click)="removeCategory(i)">Видалити категорiю</button>
                    </div>
                </div>
                <hr/>
            </div>

            <div class="row" style="margin: 0;">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <button type="button" mat-raised-button class="tagus" (click)="addCategory()">Додати</button>
                </div>
            </div>
        </form>
    </mat-card-content>
</mat-card>
