<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Reports</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Reports</li>
    </ol>
</div>

<div class="row">
    <div class="col-xxl-8">
        <div class="row">
            <div class="col-lg-8">
                <app-ar-revenue-report></app-ar-revenue-report>
            </div>
            <div class="col-lg-4">
                <app-ar-average-report></app-ar-average-report>
            </div>
        </div>
    </div>
    <div class="col-xxl-4">
        <app-ar-sessions></app-ar-sessions>
    </div>
</div>

<app-ar-browser-used-traffic-reports></app-ar-browser-used-traffic-reports>