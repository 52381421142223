<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Form Field</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">UI Kit</li>
        <li class="breadcrumb-item fw-semibold">Form Field</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Simple Form Field</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>Input</mat-label>
                    <input matInput>
                </mat-form-field>
                <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>Select</mat-label>
                    <mat-select>
                        <mat-option value="one">First option</mat-option>
                        <mat-option value="two">Second option</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>Textarea</mat-label>
                    <textarea matInput rows="5"></textarea>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Form Field Appearance Variants</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field appearance="fill" class="example-full-width mb-25">
                    <mat-label>Fill form field</mat-label>
                    <input matInput placeholder="Placeholder">
                    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                    <mat-hint class="mt-5">Hint</mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Outline form field</mat-label>
                    <input matInput placeholder="Placeholder">
                    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                    <mat-hint class="mt-5">Hint</mat-hint>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Form Field With Error Messages</h5>
            </mat-card-header>
            <mat-card-content>
                <div class="example-container">
                    <mat-form-field appearance="fill" class="example-full-width">
                        <mat-label>Enter your email</mat-label>
                        <input matInput placeholder="pat@example.com" [formControl]="email" required>
                        <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Form Field Theming</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field class="example-full-width" appearance="fill" [color]="colorControl.value!">
                    <mat-label>Color</mat-label>
                    <mat-select [formControl]="colorControl">
                        <mat-option value="primary">Primary</mat-option>
                        <mat-option value="accent">Accent</mat-option>
                        <mat-option value="warn">Warn</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Form Field with Prefix & Suffix</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>Enter your password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'">
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field appearance="fill" floatLabel="always" class="example-full-width">
                    <mat-label>Amount</mat-label>
                    <input matInput type="number" class="example-right-align" placeholder="0">
                    <span matTextPrefix>$&nbsp;</span>
                    <span matTextSuffix>.00</span>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25 tagus-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Form Field With Label</h5>
            </mat-card-header>
            <mat-card-content>
                <app-ff-with-label></app-ff-with-label>
            </mat-card-content>
        </mat-card>
    </div>
</div>