import { Component } from '@angular/core';

@Component({
  selector: 'app-fm-assets',
  templateUrl: './fm-assets.component.html',
  styleUrls: ['./fm-assets.component.scss']
})
export class FmAssetsComponent {

}
