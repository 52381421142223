import { Component } from '@angular/core';

@Component({
  selector: 'app-sales-by-locations',
  templateUrl: './sales-by-locations.component.html',
  styleUrls: ['./sales-by-locations.component.scss']
})
export class SalesByLocationsComponent {

}
