import { Component, OnInit } from '@angular/core';
import { ErrorService } from "../error.service";
import { ErrorTypeEnum } from "../../../../../enums/error-type.enum";

@Component({
  selector: 'app-local-error',
  templateUrl: './local-error.component.html',
  styleUrl: './local-error.component.css'
})
export class LocalErrorComponent implements OnInit {
  errors: string[] = [];

  constructor(
    private errorService: ErrorService,
  ) {}

  ngOnInit() {
    // подписка на ошибки из сервиса
    this.errorService.getErrors(ErrorTypeEnum.Local).subscribe((errors) => {
      this.errors = errors;
    });
  }
}
