import { Component } from '@angular/core';

@Component({
  selector: 'app-fm-personal',
  templateUrl: './fm-personal.component.html',
  styleUrls: ['./fm-personal.component.scss']
})
export class FmPersonalComponent {

}
