<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Editors</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Editors</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header>
        <h5 class="mb-0">NGX Editor</h5>
    </mat-card-header>
    <mat-card-content>
        <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
            <ngx-editor [editor]="editor" [(ngModel)]="html" name="html" [disabled]="false" [placeholder]="'Some demo text ...'"></ngx-editor>
        </div>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header>
        <h5 class="mb-0">Quill Editor</h5>
    </mat-card-header>
    <mat-card-content>
        <quill-editor [styles]="{height: '200px'}" (onFocus)="focus($event)" (onEditorChanged)="changedEditor($event)" (onBlur)="blur($event)" (onEditorCreated)="created($event)"></quill-editor>
    </mat-card-content>
</mat-card>