import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PageService {
    // currentPage: string = '';
    private _currentPage: BehaviorSubject<string> = new BehaviorSubject<string>('');

    constructor(private router: Router) {
    // подписываемся на события роутера
    this.router.events.subscribe(event => {
      // проверяем, является ли событие завершением навигации
      if (event instanceof NavigationEnd) {
        // обновляем текущую страницу согласно URL
        // this.currentPage = event.url;
        this._currentPage.next(event.url);
      }
    });
    }

    get currentPage$() {
        return this._currentPage.asObservable();
    }
}
