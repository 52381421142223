<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Toolbar</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item gray-color">UI Kit</li>
        <li class="breadcrumb-item fw-semibold">Toolbar</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Basic Toolbar</h5>
    </mat-card-header>
    <mat-card-content>
        <mat-toolbar>
            <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
                <mat-icon>menu</mat-icon>
            </button>
            <span>My App</span>
            <span class="example-spacer"></span>
            <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
                <mat-icon>favorite</mat-icon>
            </button>
            <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
                <mat-icon>share</mat-icon>
            </button>
        </mat-toolbar>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Multi-row Toolbar</h5>
    </mat-card-header>
    <mat-card-content>
        <mat-toolbar color="primary">
            <mat-toolbar-row>
                <span class="fw-semibold">Custom Toolbar</span>
            </mat-toolbar-row>

            <mat-toolbar-row>
                <span class="fw-semibold">Second Line</span>
                <span class="example-spacer"></span>
                <mat-icon aria-hidden="false" aria-label="Example user verified icon">verified_user</mat-icon>
            </mat-toolbar-row>

            <mat-toolbar-row>
                <span class="fw-semibold">Third Line</span>
                <span class="example-spacer"></span>
                <mat-icon aria-hidden="false" aria-label="Example heart icon">favorite</mat-icon>
                <mat-icon class="ms-15" aria-hidden="false" aria-label="Example delete icon">delete</mat-icon>
            </mat-toolbar-row>
        </mat-toolbar>
    </mat-card-content>
</mat-card>

<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Toolbar Overview</h5>
    </mat-card-header>
    <mat-card-content>
        <p>
            <mat-toolbar>
                <span>My Application</span>
            </mat-toolbar>
        </p>
        <p>
            <mat-toolbar>
                <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
                    <mat-icon>menu</mat-icon>
                </button>
                <span>My App</span>
                <span class="example-spacer"></span>
                <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
                    <mat-icon>favorite</mat-icon>
                </button>
                <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
                    <mat-icon>share</mat-icon>
                </button>
            </mat-toolbar>
        </p>
        <p>
            <mat-toolbar color="primary">
                <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
                    <mat-icon>menu</mat-icon>
                </button>
                <span>My App</span>
                <span class="example-spacer"></span>
                <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
                    <mat-icon>favorite</mat-icon>
                </button>
                <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
                    <mat-icon>share</mat-icon>
                </button>
            </mat-toolbar>
        </p>
        <p>
            <mat-toolbar color="primary">
                <mat-toolbar-row>
                    <span>My App</span>
                    <span class="example-spacer"></span>
                    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
                        <mat-icon>menu</mat-icon>
                    </button>
                </mat-toolbar-row>
                <mat-toolbar-row>
                    <span>Second Line</span>
                    <span class="example-spacer"></span>
                    <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
                        <mat-icon>favorite</mat-icon>
                    </button>
                    <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
                        <mat-icon>share</mat-icon>
                    </button>
                </mat-toolbar-row>
            </mat-toolbar>
        </p>
    </mat-card-content>
</mat-card>