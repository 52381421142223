import { Component } from '@angular/core';

@Component({
  selector: 'app-fm-projects',
  templateUrl: './fm-projects.component.html',
  styleUrls: ['./fm-projects.component.scss']
})
export class FmProjectsComponent {

}
