<div class="chart">
    <apx-chart
        [series]="chartOptions.series!"
        [chart]="chartOptions.chart!"
        [xaxis]="chartOptions.xaxis!"
        [stroke]="chartOptions.stroke!"
        [tooltip]="chartOptions.tooltip!"
        [dataLabels]="chartOptions.dataLabels!"
        [legend]="chartOptions.legend!"
        [markers]="chartOptions.markers!"
        [colors]="chartOptions.colors!"
        [grid]="chartOptions.grid!"
        [yaxis]="chartOptions.yaxis!"
        [title]="chartOptions.title!"
    ></apx-chart>
</div>