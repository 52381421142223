<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Pricing Plans - 1</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Pricing</li>
    </ol>
</div>

<div class="row" [class.component-rtl-enabled]="themeService.isRTLEnabled()">
    <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
        <mat-card class="mb-25 pricing-box tagus-card">
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-space-between">
                    <div class="title">
                        <h5 class="mb-5">Basic Plan</h5>
                        <p>A simple start for everyone</p>
                    </div>
                    <img src="assets/img/icon/icon14.png" alt="icon">
                </div>
                <div class="price fw-semibold">
                    $50/<span class="gray-color">per month</span>
                </div>
                <div class="text-center">
                    <button mat-flat-button class="tagus">Sign Up Now</button>
                </div>
                <ul class="features-list ps-0 mb-0 list-unstyled">
                    <li class="position-relative">Free Live Support</li>
                    <li class="position-relative">30GB Disk Space</li>
                    <li class="position-relative">Scalable Bandwith</li>
                    <li class="position-relative">Free Setup</li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
        <mat-card class="mb-25 pricing-box tagus-card">
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-space-between">
                    <div class="title">
                        <h5 class="mb-5">Professional Plan</h5>
                        <p>A simple start for everyone</p>
                    </div>
                    <img src="assets/img/icon/icon15.png" alt="icon">
                </div>
                <div class="price fw-semibold">
                    $100/<span class="gray-color">per month</span>
                </div>
                <div class="text-center">
                    <button mat-flat-button class="tagus">Sign Up Now</button>
                </div>
                <ul class="features-list ps-0 mb-0 list-unstyled">
                    <li class="position-relative">Free Live Support</li>
                    <li class="position-relative">40GB Disk Space</li>
                    <li class="position-relative">Scalable Bandwith</li>
                    <li class="position-relative">Free Setup</li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
        <mat-card class="mb-25 pricing-box tagus-card">
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-space-between">
                    <div class="title">
                        <h5 class="mb-5">Enterprise Plan</h5>
                        <p>A simple start for everyone</p>
                    </div>
                    <img src="assets/img/icon/icon16.png" alt="icon">
                </div>
                <div class="price fw-semibold">
                    $150/<span class="gray-color">per month</span>
                </div>
                <div class="text-center">
                    <button mat-flat-button class="tagus">Sign Up Now</button>
                </div>
                <ul class="features-list ps-0 mb-0 list-unstyled">
                    <li class="position-relative">Free Live Support</li>
                    <li class="position-relative">50GB Disk Space</li>
                    <li class="position-relative">Scalable Bandwith</li>
                    <li class="position-relative">Free Setup</li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
        <mat-card class="mb-25 pricing-box tagus-card">
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-space-between">
                    <div class="title">
                        <h5 class="mb-5">Unlimited Plan</h5>
                        <p>A simple start for everyone</p>
                    </div>
                    <img src="assets/img/icon/icon17.png" alt="icon">
                </div>
                <div class="price fw-semibold">
                    $200/<span class="gray-color">per month</span>
                </div>
                <div class="text-center">
                    <button mat-flat-button class="tagus">Sign Up Now</button>
                </div>
                <ul class="features-list ps-0 mb-0 list-unstyled">
                    <li class="position-relative">Free Live Support</li>
                    <li class="position-relative">60GB Disk Space</li>
                    <li class="position-relative">Scalable Bandwith</li>
                    <li class="position-relative">Free Setup</li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Pricing Plans - 2</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Pricing</li>
    </ol>
</div>

<div class="row" [class.component-rtl-enabled]="themeService.isRTLEnabled()">
    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6">
        <mat-card class="mb-25 pricing-item tagus-card">
            <mat-card-content>
                <div class="title position-relative">
                    <h5 class="mb-5">Basic Plan</h5>
                    <p>Perfect for an individual or a small team starting to get bigger</p>
                </div>
                <div class="price fw-semibold">
                    $50/<span class="gray-color">per month</span>
                </div>
                <button mat-flat-button class="gray fw-semibold d-block">Get Started</button>
                <ul class="features-list ps-0 mb-0 list-unstyled">
                    <li class="position-relative fw-medium gray-color"><i class="flaticon-check"></i> 100 Responses A Month</li>
                    <li class="position-relative fw-medium gray-color"><i class="flaticon-check"></i> Unlimited Forms And Surveys</li>
                    <li class="position-relative fw-medium gray-color"><i class="flaticon-check"></i> Unlimited Fields</li>
                    <li class="position-relative fw-medium gray-color"><i class="flaticon-check"></i> Basic Form Creation Tools</li>
                    <li class="position-relative fw-medium gray-color"><i class="flaticon-check"></i> Up To 2 Subdomains</li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6">
        <mat-card class="mb-25 pricing-item tagus-card">
            <mat-card-content>
                <div class="title position-relative">
                    <h5 class="mb-5">Premium Plan</h5>
                    <p>Perfect for an individual or a small team starting to get bigger</p>
                </div>
                <div class="price fw-semibold">
                    $100/<span class="gray-color">per month</span>
                </div>
                <button mat-flat-button class="tagus fw-semibold d-block">Start a 14-days Free Trial</button>
                <ul class="features-list ps-0 mb-0 list-unstyled">
                    <li class="position-relative fw-medium gray-color"><i class="flaticon-check"></i> 100 Responses A Month</li>
                    <li class="position-relative fw-medium gray-color"><i class="flaticon-check"></i> Unlimited Forms And Surveys</li>
                    <li class="position-relative fw-medium gray-color"><i class="flaticon-check"></i> Unlimited Fields</li>
                    <li class="position-relative fw-medium gray-color"><i class="flaticon-check"></i> Basic Form Creation Tools</li>
                    <li class="position-relative fw-medium gray-color"><i class="flaticon-check"></i> Up To 4 Subdomains</li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6">
        <mat-card class="mb-25 pricing-item tagus-card">
            <mat-card-content>
                <div class="title position-relative">
                    <h5 class="mb-5">Enterprise Plan</h5>
                    <p>Perfect for an individual or a small team starting to get bigger</p>
                </div>
                <div class="price fw-semibold">
                    $150/<span class="gray-color">per month</span>
                </div>
                <button mat-flat-button class="gray fw-semibold d-block">Start a 7-days Free Trial</button>
                <ul class="features-list ps-0 mb-0 list-unstyled">
                    <li class="position-relative fw-medium gray-color"><i class="flaticon-check"></i> 100 Responses A Month</li>
                    <li class="position-relative fw-medium gray-color"><i class="flaticon-check"></i> Unlimited Forms And Surveys</li>
                    <li class="position-relative fw-medium gray-color"><i class="flaticon-check"></i> Unlimited Fields</li>
                    <li class="position-relative fw-medium gray-color"><i class="flaticon-check"></i> Basic Form Creation Tools</li>
                    <li class="position-relative fw-medium gray-color"><i class="flaticon-check"></i> Up To 6 Subdomains</li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
</div>