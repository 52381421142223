<div class="row justify-content-center">
    <div class="col-md-6 col-lg-4 col-xxl-2/5">
        <mat-card class="single-stats-box tagus-card mb-25">
            <div class="d-flex align-items-center">
                <div class="icon">
                    <i class="flaticon-goal"></i>
                </div>
                <div class="title">
                    <h3>120</h3>
                    <span class="gray-color d-block">Enrolled Courses</span>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="col-md-6 col-lg-4 col-xxl-2/5">
        <mat-card class="single-stats-box tagus-card mb-25">
            <div class="d-flex align-items-center">
                <div class="icon bg2">
                    <i class="flaticon-learning"></i>
                </div>
                <div class="title">
                    <h3>17</h3>
                    <span class="gray-color d-block">Active Courses</span>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="col-md-6 col-lg-4 col-xxl-2/5">
        <mat-card class="single-stats-box tagus-card mb-25">
            <div class="d-flex align-items-center">
                <div class="icon bg3">
                    <i class="flaticon-struggle"></i>
                </div>
                <div class="title">
                    <h3>64</h3>
                    <span class="gray-color d-block">Completed Courses</span>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="col-md-6 col-lg-4 col-xxl-2/5">
        <mat-card class="single-stats-box tagus-card mb-25">
            <div class="d-flex align-items-center">
                <div class="icon bg4">
                    <i class="flaticon-purpose"></i>
                </div>
                <div class="title">
                    <h3>2.1M</h3>
                    <span class="gray-color d-block">Total Students</span>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="col-md-6 col-lg-4 col-xxl-2/5">
        <mat-card class="single-stats-box tagus-card mb-25">
            <div class="d-flex align-items-center">
                <div class="icon bg5">
                    <i class="flaticon-idea"></i>
                </div>
                <div class="title">
                    <h3>345</h3>
                    <span class="gray-color d-block">Expert Mentors</span>
                </div>
            </div>
        </mat-card>
    </div>
</div>