import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../../../services/api.service";
import {MarketResolver} from "../../../../resolvers/market.resolver";
import {ErrorService} from "../../../common/notifications/errors/error.service";
import {SuccessService} from "../../../common/notifications/success/success.service";
import {StorageService} from "../../../../services/storage.service";
import {EndpointTypeEnum} from "../../../../enums/endpoint-type.enum";
import {ErrorTypeEnum} from "../../../../enums/error-type.enum";
import {DEFAULT_MESSAGES} from "../../../../constants/default-messages";

@Component({
  selector: 'app-market-products-by-brand-list',
  templateUrl: './market-products-by-brand-list.component.html',
  styleUrl: './market-products-by-brand-list.component.scss'
})
export class MarketProductsByBrandListComponent implements OnInit, OnDestroy, AfterViewInit {
    backendUrl: string = environment.backendUrl;
    market: any;
    currentBrand: string;
    brandId: any = this.route.snapshot.paramMap.get('id');
    displayedColumns: string[] = ['image', 'name', 'category', 'brand', 'article', 'price', 'stock', 'availability', 'action'];
    ELEMENT_DATA: PeriodicElement[] = [];
    dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
    products: any[] = [];
    name_ua: string;
    article: string;
    showConfirmation = false;
    selectedCategoryId: any;
    pageIndex: number = 0;
    timeout: any = null;
    pageSizeOptions: any[] = [5, 15, 25, 50, 75, 100];
    totalRecords = 0;
    pageSize: number = this.pageSizeOptions[0];
    urlParams = {
        search: null
    };

    @ViewChild('searchBlock') searchBlock: any;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private apiService: ApiService,
        private marketResolver: MarketResolver,
        private errorService: ErrorService,
        private successService: SuccessService,
        public storageService: StorageService,
    ) {
        this.marketResolver.market$.subscribe(market => {
            this.market = market;
        });
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params: any) => {
            this.pageIndex = params['page'] ? +params['page'] - 1 : 0;
            this.urlParams = params;
        });
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;

        setTimeout(() => {
            this.paginator.pageIndex = this.pageIndex;
            this.load(this.brandId);
        });

        this.paginator.page.subscribe((event: PageEvent) => this.onPageChange(event));
        this.urlParams.search && (this.searchBlock.nativeElement.value = this.urlParams.search);
        // поиск
        this.dataSource.filterPredicate = (data: PeriodicElement, filter: string) => {
            const nameUa = data.name_ua ? data.name_ua.toLowerCase() : '';
            const article = data.article ? data.article.toLowerCase() : '';
            const descriptionUa = data.description_ua ? data.description_ua.toLowerCase() : '';

            return nameUa.includes(filter) || article.includes(filter) || descriptionUa.includes(filter);
        };
    }

    error(err: any) {
        this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
    }

    load(brandId: string) {
        const search: string = this.searchBlock.nativeElement.value;

        this.apiService
            .get(EndpointTypeEnum.Market, '/products/by-brand', true, brandId, {
                'market_id': this.market._id,
                'page': this.pageIndex,
                'limit': this.pageSize,
                ...(search && { 'name': search })

            })
            .subscribe({
                next: (response: any) => {
                    this.ELEMENT_DATA = response.data;
                    this.totalRecords = response.total;
                    this.dataSource.data = this.ELEMENT_DATA;
                    this.currentBrand = response.data.length && response.data[0].brand?.name_ua;
                },
                error: this.error.bind(this),
            });
    }

    onClearSearch() {
        this.searchBlock.nativeElement.value = '';
        this.pageIndex = 0;
        this.changeURLAsMerge({
            page: this.pageIndex,
            limit: this.pageSize,
            search: null
        })
        this.load(this.brandId);
    }


    onPageChange(event: PageEvent): void {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;

        this.changeURLAsMerge({
            page: this.pageIndex + 1,
            limit: this.pageSize
        })
        this.load(this.brandId);
    }

    ngOnDestroy() {
        this.successService.clear(ErrorTypeEnum.Global);
        this.successService.clear(ErrorTypeEnum.Local);
        this.errorService.clearErrors(ErrorTypeEnum.Global);
        this.errorService.clearErrors(ErrorTypeEnum.Local);
    }

    remove(productId: string) {
        this.showConfirmation = true;
        this.selectedCategoryId = productId;
    }

    onConfirmationRemove(confirmed: boolean) {
        if (confirmed) {
            this.apiService
                .delete(EndpointTypeEnum.Market, '/products', true, this.selectedCategoryId)
                .subscribe({
                    next: (response: any) => {
                        this.load(this.brandId);
                        this.successService.add(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.SUCCESS]);
                    },
                    error: this.error.bind(this),
                });
            this.showConfirmation = false;
        } else {
            this.showConfirmation = false;
            this.selectedCategoryId = null;
        }
    }

    applyFilter(event: any) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() =>  {
            if (event.key !== '13') {
                this.pageIndex = 0;
                this.changeURLAsMerge({
                    page: 0,
                    limit: this.pageSize,
                    search: event.target.value
                })
                this.load(this.brandId);
            }
        }, 1000);
    }

    changeURLAsMerge(json: any, preserve?: any) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: json,
            queryParamsHandling: preserve || 'merge'
        });
    }
}

export interface PeriodicElement {
    name_ua: any;
    main_category: any;
    amount: any;
    article: any;
    description_ua: any;
}
