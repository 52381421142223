import { Component } from '@angular/core';
import {SuccessService} from "../success.service";
import { ErrorTypeEnum } from "../../../../../enums/error-type.enum";

@Component({
  selector: 'app-global-success',
  templateUrl: './global-success.component.html',
  styleUrl: './global-success.component.css'
})
export class GlobalSuccessComponent {
  messages: string[] = [];

  constructor(
    private successService: SuccessService,
  ) {}

  ngOnInit() {
    // подписка на ошибки из сервиса
    this.successService.get(ErrorTypeEnum.Global).subscribe((messages) => {
      this.messages = messages;
    });
  }
}
