import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateApi',
})
export class TranslateApiPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(key: string): string {
    if (!key) {
      return '';
    }

    return this.translateService.instant(key);
  }
}
