import { Component } from '@angular/core';

@Component({
    selector: 'app-expansion',
    templateUrl: './expansion.component.html',
    styleUrls: ['./expansion.component.scss']
})
export class ExpansionComponent {

    panelOpenState = false;

    step = 0;

    setStep(index: number) {
        this.step = index;
    }

    nextStep() {
        this.step++;
    }

    prevStep() {
        this.step--;
    }

}