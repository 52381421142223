import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ApiService} from "../../../../services/api.service";
import {ErrorService} from "../../../common/notifications/errors/error.service";
import {SuccessService} from "../../../common/notifications/success/success.service";
import {EndpointTypeEnum} from "../../../../enums/endpoint-type.enum";
import {ErrorTypeEnum} from "../../../../enums/error-type.enum";
import {DEFAULT_MESSAGES} from "../../../../constants/default-messages";
import {MarketResolver} from "../../../../resolvers/market.resolver";
import {priceValidator} from "../../../../validators/price.validator";
import {UtilityService} from "../../../../services/utility.service";
import {Editor, Toolbar} from "ngx-editor";
import {TOOLBAR_CONFIG} from "../../../../configs/toolbar.config";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {ToolbarEditor2ConfigService} from "../../../../configs/toolbar-editor-2.config";

@Component({
  selector: 'app-market-product-create',
  templateUrl: './market-product-create.component.html',
  styleUrl: './market-product-create.component.scss'
})
export class MarketProductCreateComponent implements OnInit, OnDestroy {
    market: any;
    // форма
    form!: FormGroup;
    formWasSubmitted = false;
    // для выпадающих списков
    categoriesForDropdown: any;
    attributesForDropdown: any;
    optionsForDropdown: any;
    unitsForDropdown: any;
    // выбранные объекты
    addedAttributes: any[] = [];
    addedOptions: any[] = [];
    selectedCategories: string[] = [];
    // изображения
    gallery: { image: string | null; is_main: boolean; video: string | null }[] = [];
    imageOpenGraph: string;
    base64openGraph: any;
    // wysiwyg
    editorDescriptionRu: Editor;
    editorDescriptionUa: Editor;
    toolbar: Toolbar = TOOLBAR_CONFIG;
    editorConfig: AngularEditorConfig;
    // для валидации полей при добавлении attribute value
    fieldNameRuInAttributes: any[] = [];
    fieldNameUaInAttributes: any[] = [];
    fieldAddedAttributesInAttributes: any[] = [];
    // для валидации полей при добавлении options
    fieldNameRuInOptions: any[] = [];
    fieldNameUaInOptions: any[] = [];
    fieldPriceInOptions: any[] = [];
    fieldUrlInOptions: any[] = [];
    fieldAddedOptionsInOptions: any[] = [];
    // для блока "С этими товарами смотрели"
    optionsControlForViewedWith = new FormControl('');
    optionsForViewedWith: any[] = [];
    selectedOptionsViewedWith: any[] = [];
    filteredOptionsViewedWith: any[] = [];

    @ViewChild('input') input: ElementRef<HTMLInputElement>;

    constructor(
        private router: Router,
        private apiService: ApiService,
        private formBuilder: FormBuilder,
        private marketResolver: MarketResolver,
        private utilityService: UtilityService,
        private errorService: ErrorService,
        private successService: SuccessService,
        private toolbarEditor2ConfigService: ToolbarEditor2ConfigService,
    ) {
        // получаем market
        this.marketResolver.market$.subscribe(market => {
            this.market = market;
        });

        this.editorConfig = this.toolbarEditor2ConfigService.getConfig();

        this.getCategoriesForDropdown();
        this.getAttributesForDropdown();
        this.getOptionsForDropdown();
        this.getUnitsForDropdown();
        this.getProductOptions();
        this.initForm();
        this.filteredOptionsViewedWith = this.optionsForViewedWith.slice() || [];
        // this.subscribeToNameChanges();
    }

    // инициализация формы
    initForm(): void {
        // инициализируем поля из формы и валидируем
        this.form = this.formBuilder.group({
            // ru
            name_ru:             ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
            description_ru:      [''],
            meta_title_ru:       ['', [Validators.minLength(2), Validators.maxLength(80)]],
            meta_description_ru: ['', [Validators.minLength(2), Validators.maxLength(255)]],
            // ua
            name_ua:             ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
            description_ua:      [''],
            meta_title_ua:       ['', [Validators.minLength(2), Validators.maxLength(80)]],
            meta_description_ua: ['', [Validators.minLength(2), Validators.maxLength(255)]],
            // общие поля
            url:                 ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255), Validators.pattern(/^[a-z0-9-]+$/i)]],
            article:             ['', [Validators.required, Validators.minLength(2), Validators.maxLength(40)]],
            upc:                 ['', [Validators.minLength(2), Validators.maxLength(40)]],
            ean:                 ['', [Validators.minLength(2), Validators.maxLength(40)]],
            jan:                 ['', [Validators.minLength(2), Validators.maxLength(40)]],
            mpn:                 ['', [Validators.minLength(2), Validators.maxLength(40)]],
            purchase_price:      ['', [priceValidator, Validators.minLength(2), Validators.maxLength(14)]],
            availability:        ['', Validators.required],
            amount:              ['', [Validators.pattern(/^[0-9]+$/)]],
            status:              [1, Validators.required],
            is_new:              [''],
            is_product_of_week:  ['', Validators.required],
            opengraph_header_ru: [''],
            opengraph_header_ua: [''],
            opengraph_description_ru: [''],
            opengraph_description_ua: [''],
            opengraph_image:     ['', [Validators.pattern(/\.(jpg|png|svg)$/i)]],
            // связи
            mainGalleryVideo:    [''],
            mainGalleryImage:    ['', [Validators.pattern(/\.(jpg|png|svg)$/i)]],
            secondaryGalleryImages: ['', [Validators.pattern(/\.(jpg|png|svg)$/i)]],
            price:               ['', [Validators.required, priceValidator, Validators.minLength(2), Validators.maxLength(14)]],
            price_new:           ['', [priceValidator, Validators.minLength(2), Validators.maxLength(14)]],
            main_category:       ['', Validators.required],
            unit:                [''],
            categories:          [''],
            added_attributes:    [''],
            // отправитель
            market:              [this.market._id]
        });
    }

    ngOnInit() {
        this.getCategoriesForDropdown();
        this.editorDescriptionRu = new Editor();
        this.editorDescriptionUa = new Editor();
    }

    ngOnDestroy(): void {
        this.editorDescriptionRu.destroy();
        this.editorDescriptionUa.destroy();
    }

    // -- логика для: attributes --
    // добавление полей для attributes
    addAttribute() {
        this.addedAttributes.push({ attribute: '', name_ru: '', name_ua: '' });

        const newIndex = this.addedAttributes.length - 1;
        this.fieldAddedAttributesInAttributes[newIndex] = { isValid: false };
        this.fieldNameRuInAttributes[newIndex]          = { isValid: false };
        this.fieldNameUaInAttributes[newIndex]          = { isValid: false };
    }

    // -- логика для: attributes --
    // удаление полей для attributes
    removeAttribute(index: number) {
        this.addedAttributes.splice(index, 1);

        this.fieldAddedAttributesInAttributes.splice(index, 1);
        this.fieldNameRuInAttributes.splice(index, 1);
        this.fieldNameUaInAttributes.splice(index, 1);
    }

    // -- логика для: attributes --
    // обновление значений в полях для attributes
    onUpdateAttribute(index: number, field: string, event: any): void {
        switch (field) {
            case 'attribute':
                // записываем значение поля attribute в addedAttributes
                this.addedAttributes[index].attribute = event.value;
                // записываем значение поля isValid в fieldAddedAttributesInAttributes для дальнейшей валидации
                this.fieldAddedAttributesInAttributes[index].isValid = !(event.value === '');
                break;
            case 'name_ru':
                // записываем значение поля name_ru в addedAttributes
                this.addedAttributes[index].name_ru = event.target.value;
                // записываем значение поля isValid в fieldNameRuInAttributes для дальнейшей валидации
                this.fieldNameRuInAttributes[index].isValid = !(event.target.value === '');
                break;
            case 'name_ua':
                // записываем значение поля name_ua в addedAttributes
                this.addedAttributes[index].name_ua = event.target.value;
                // записываем значение поля isValid в fieldNameUaInAttributes для дальнейшей валидации
                this.fieldNameUaInAttributes[index].isValid = !(event.target.value === '');
                break;
            default:
                break;
        }
    }

    // -- логика для: options --
    // добавление полей для options
    addOption() {
        this.addedOptions.push({
            main_option: '',
            name_ru: '',
            name_ua: '',
            price: '',
            price_new: '',
            url: '',
            is_color: false,
            color: '#000',
        });

        const newIndex = this.addedOptions.length - 1;
        this.fieldAddedOptionsInOptions[newIndex] = { isValid: false };
        this.fieldNameRuInOptions[newIndex]       = { isValid: false };
        this.fieldNameUaInOptions[newIndex]       = { isValid: false };
        this.fieldPriceInOptions[newIndex]        = { isValid: false };
        this.fieldUrlInOptions[newIndex]          = { isValid: false };
    }

    // -- логика для: options --
    // удаление полей для options
    removeOption(index: number) {
        this.addedOptions.splice(index, 1);

        this.fieldAddedOptionsInOptions.splice(index, 1);
        this.fieldNameRuInOptions.splice(index, 1);
        this.fieldNameUaInOptions.splice(index, 1);
        this.fieldPriceInOptions.splice(index, 1);
        this.fieldUrlInOptions.splice(index, 1);
    }

    // -- логика для: С этими товарами смотрят --
    filterOptionsViewedWith(): void {
        const filterValue = this.input.nativeElement.value.toLowerCase();
        this.filteredOptionsViewedWith = this.optionsForViewedWith.filter(o => o.value_ua && o.value_ua.toLowerCase().includes(filterValue));
    }

    // -- логика для: С этими товарами смотрят --
    onUpdateOptionsViewedWith(event: any) {
        const selectedOption = event.option.value;
        if (!this.selectedOptionsViewedWith.includes(selectedOption)) {
            this.selectedOptionsViewedWith.push(selectedOption);
        }
        this.optionsControlForViewedWith.setValue('');
    }

    // -- логика для: С этими товарами смотрят --
    removeOptionViewedWith(index: number) {
        this.selectedOptionsViewedWith.splice(index, 1);
    }

    // -- логика для: options --
    // обновление значений в полях для options
    onUpdateOption(index: number, field: string, event: any): void {
        switch (field) {
            case 'main_option':
                // записываем значение поля в addedOptions
                this.addedOptions[index].main_option = event.value;
                // записываем значение поля isValid в fieldAddedOptionsInOptions для дальнейшей валидации
                this.fieldAddedOptionsInOptions[index].isValid = !(event.value === '');
                break;
            case 'name_ru':
                // записываем значение поля в addedOptions
                this.addedOptions[index].name_ru = event.target.value;
                // записываем значение поля isValid в fieldNameRuInOptions для дальнейшей валидации
                this.fieldNameRuInOptions[index].isValid = !(event.target.value === '');
                break;
            case 'name_ua':
                // записываем значение поля в addedOptions
                this.addedOptions[index].name_ua = event.target.value;
                // записываем значение поля isValid в fieldNameUaInOptions для дальнейшей валидации
                this.fieldNameUaInOptions[index].isValid = !(event.target.value === '');
                break;
            case 'price':
                // записываем значение поля в addedOptions
                this.addedOptions[index].price = event.target.value;
                // записываем значение поля isValid в fieldNameUaInOptions для дальнейшей валидации
                this.fieldPriceInOptions[index].isValid = !(event.target.value === '');
                break;
            case 'price_new':
                // записываем значение поля в addedOptions
                this.addedOptions[index].price_new = event.target.value;
                break;
            case 'url':
                // записываем значение поля в addedOptions
                this.addedOptions[index].url = event.target.value;
                // записываем значение поля isValid в fieldNameUaInOptions для дальнейшей валидации
                this.fieldUrlInOptions[index].isValid = !(event.target.value === '');
                break;
            case 'is_color':
                // записываем значение поля в addedOptions
                this.addedOptions[index].is_color = event.value || false;
                break;
            case 'color':
                // записываем значение поля в addedOptions
                this.addedOptions[index].color = event.target.value;
                break;
            default:
                break;
        }
    }

    // -- логика для: categories --
    // чекбокс для выбора категорий
    updateSelectedCategories(categoryId: string): void {
        if (this.isSelectedCategories(categoryId)) {
            this.selectedCategories = this.selectedCategories.filter(id => id !== categoryId);
        } else {
            this.selectedCategories.push(categoryId);
        }
    }

    // -- логика для: categories --
    // чекбокс для отображения выбранных категорий
    isSelectedCategories(categoryId: string): boolean {
        return this.selectedCategories.includes(categoryId);
    }

    // получение данных для выпадающего списка
    getCategoriesForDropdown(): void {
        this.apiService
            .get(EndpointTypeEnum.DropdownLists, '/categories', true)
            .subscribe({
                next: (response: any) => {
                    this.categoriesForDropdown = response.data;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    // получение данных для выпадающего списка
    getAttributesForDropdown(): void {
        this.apiService
            .get(EndpointTypeEnum.DropdownLists, '/attributes', true)
            .subscribe({
                next: (response: any) => {
                    this.attributesForDropdown = response.data;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    // получение данных для выпадающего списка
    getOptionsForDropdown(): void {
        this.apiService
            .get(EndpointTypeEnum.DropdownLists, '/options', true)
            .subscribe({
                next: (response: any) => {
                    this.optionsForDropdown = response.data;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    // получение данных для выпадающего списка
    getProductOptions(): void {
        this.apiService
            .get(EndpointTypeEnum.DropdownLists, '/product-options', true)
            .subscribe({
                next: (response: any) => {
                    this.optionsForViewedWith = response.data;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    // получение данных для выпадающего списка
    getUnitsForDropdown(): void {
        this.apiService
            .get(EndpointTypeEnum.DropdownLists, '/units', true)
            .subscribe({
                next: (response: any) => {
                    this.unitsForDropdown = response.data;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    // подписка на изменения в поле name для генерации url
    subscribeToFieldChanges(fieldName: string, targetFieldName: string, value: any): void {
        const field = this.form.get(fieldName);
        if (field) {
            const newValue = value;
            const targetField = this.form.get(targetFieldName);
            if (targetField) {
                targetField.setValue(this.utilityService.convertToUrl(newValue));
            }
        }
    }

    onUpdateVideo(event: any) {
        // сначала очищаем предыдущее видео (если оно есть)
        const mainVideoIndex = this.gallery.findIndex(item => item.video);
        if (mainVideoIndex !== -1) {
            this.gallery.splice(mainVideoIndex, 1);
        }
        this.gallery.push({image: null, is_main: false, video: event.target.value});
    }

    // конвертация изображений в base64
    convertToBase64multiple(event: any, type: 'mainGalleryImage' | 'secondaryGalleryImages' | 'openGraphImage'): void {
        this.utilityService.convertToBase64multiple(event)
            .then(base64Array => {
                if (type === 'secondaryGalleryImages') {
                    this.gallery = this.gallery.filter(item => item.is_main);
                    base64Array.forEach(base64 => {
                        this.gallery.push({image: base64, is_main: false, video: null});
                    });
                } else if (type === 'mainGalleryImage') {
                    // сначала очищаем предыдущее изображение (если оно есть)
                    // у которого is_main: true
                    const mainImageIndex = this.gallery.findIndex(item => item.is_main);
                    if (mainImageIndex !== -1) {
                        this.gallery.splice(mainImageIndex, 1);
                    }
                    // добавляем новое изображения
                    base64Array.forEach(base64 => {
                        this.gallery.push({image: base64, is_main: true, video: null});
                    });
                } else if (type === 'openGraphImage') {
                    this.base64openGraph = base64Array[0];
                }
            })
            .catch(error => {
                this.errorService.addError(ErrorTypeEnum.Local, ['Ошибка изображений:', error]);
            });
    }

    uploadImage(event: any, type: 'mainGalleryImage' | 'secondaryGalleryImages' | 'openGraphImage'): void {
        // const files = event.target.files;
        const filesList: FileList = event.target.files;
        const files = Array.from(filesList);
        const formData = new FormData();

        if (type === 'secondaryGalleryImages') {
            // this.gallery = this.gallery.filter(item => item.is_main);
            files.forEach((file: any) => {
                formData.append('image', file);
                this.apiService
                    .postMultipartFormData(EndpointTypeEnum.Requests, '/upload-files', true, formData)
                    .subscribe({
                        next: (response: any) => {
                            this.gallery.push({image: response.data, is_main: false, video: null});
                            this.errorService.clearErrors(ErrorTypeEnum.Local);
                        },
                        error: (err: any) => {
                            this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                        },
                    });
            });
        } else if (type === 'mainGalleryImage') {
            // сначала очищаем предыдущее изображение (если оно есть)
            // у которого is_main: true
            const mainImageIndex = this.gallery.findIndex(item => item.is_main);
            if (mainImageIndex !== -1) {
                this.gallery.splice(mainImageIndex, 1);
            }
            // добавляем новое изображения
            files.forEach((file: any) => {
                formData.append('image', file);
                this.apiService
                    .postMultipartFormData(EndpointTypeEnum.Requests, '/upload-files', true, formData)
                    .subscribe({
                        next: (response: any) => {
                            this.gallery.push({image: response.data, is_main: true, video: null});
                            this.errorService.clearErrors(ErrorTypeEnum.Local);
                        },
                        error: (err: any) => {
                            this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                        },
                    });
            });
        } else if (type === 'openGraphImage') {
            this.base64openGraph = files[0];
            formData.append('image', files[0]);
            this.apiService
                .postMultipartFormData(EndpointTypeEnum.Requests, '/upload-files', true, formData)
                .subscribe({
                    next: (response: any) => {
                        this.imageOpenGraph = response.data;
                        this.errorService.clearErrors(ErrorTypeEnum.Local);
                    },
                    error: (err: any) => {
                        this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                    },
                });
        }
    }

    // отправка формы
    onFormSubmit(form: FormGroup): void {
        this.formWasSubmitted = true;

        // если есть ошибки валидации в полях атрибутов, не даем отправить форму
        const allFieldAttributesArrays = [
            this.fieldNameRuInAttributes,
            this.fieldNameUaInAttributes,
            this.fieldAddedAttributesInAttributes,
        ];
        for (const fieldArray of allFieldAttributesArrays) {
            for (const field of fieldArray) {
                if (!field.isValid) {
                    this.errorService.addError(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.FORM_ERROR]);
                    return;
                }
            }
        }

        // если есть ошибки валидации в полях атрибутов, не даем отправить форму
        const allFieldOptionsArrays = [
            this.fieldNameRuInOptions,
            this.fieldNameUaInOptions,
            this.fieldAddedOptionsInOptions,
            this.fieldPriceInOptions,
            this.fieldUrlInOptions,
        ];
        for (const fieldArray of allFieldOptionsArrays) {
            for (const field of fieldArray) {
                if (!field.isValid) {
                    this.errorService.addError(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.FORM_ERROR]);
                    return;
                }
            }
        }

        // если есть ошибки валидации в полях формы, не даем отправить форму
        if (form.invalid) {
            this.errorService.addError(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.FORM_ERROR]);
            return;
        }

        // получаем значения из формы
        const formValue = form.value;
        formValue.opengraph_image = this.imageOpenGraph;
        formValue.gallery = this.gallery; // поле 'gallery' используется для отправки массива в нужном формате
        formValue.categories = this.selectedCategories;
        formValue.added_attributes = this.addedAttributes;
        formValue.added_options = this.addedOptions;
        formValue.selected_options_viewed_with = this.selectedOptionsViewedWith;
        if (formValue.is_new === '') {
            formValue.is_new = 0;
        }
        if (formValue.is_product_of_week === '') {
            formValue.is_product_of_week = 0;
        }

        // отправка формы
        this.apiService
            .post(EndpointTypeEnum.Market, '/products', true, form.value)
            .subscribe({
                next: (response: any) => {
                    this.router.navigate(['/market', this.market._id, 'products']);
                    this.successService.add(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.SUCCESS]);
                },
                error: (err: any) => {
                    this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                },
            });
    }
}
