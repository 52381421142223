<div class="row">
    <div class="col-xxl-9">

        <app-crypto-stats></app-crypto-stats>

        <app-market-graph></app-market-graph>

        <app-current-rate></app-current-rate>

    </div>
    <div class="col-xxl-3">

        <app-crypto-my-profile></app-crypto-my-profile>

        <app-user-activities></app-user-activities>
        
    </div>
</div>

<app-order-activities></app-order-activities>

<div class="row">
    <div class="col-xxl-8">
        <app-my-currencies></app-my-currencies>
    </div>
    <div class="col-xxl-4">
        <app-trading></app-trading>
    </div>
</div>