<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Visits by Day</h5>
        <span class="sub-title">Total 248.5k Visits</span>
    </mat-card-header>
    <mat-card-content>
        <div class="chart visitsByDayChart">
            <apx-chart
                [series]="chartOptions.series!"
                [chart]="chartOptions.chart!"
                [xaxis]="chartOptions.xaxis!"
                [legend]="chartOptions.legend!"
                [colors]="chartOptions.colors!"
                [yaxis]="chartOptions.yaxis!"
                [responsive]="chartOptions.responsive!"
            ></apx-chart>
        </div>
    </mat-card-content>
</mat-card>