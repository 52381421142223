<div class="row">
    <div class="col-md-4">
        <mat-card class="single-stats-box tagus-card mb-25">
            <div class="d-flex align-items-center">
                <div class="icon">
                    <i class="flaticon-donut-chart"></i>
                </div>
                <div class="title">
                    <span class="d-block gray-color">Sessions</span>
                    <h3>24K</h3>
                </div>
            </div>
            <p class="position-relative up"><i class="flaticon-trend"></i> <span>9.5%</span> new session</p>
        </mat-card>
    </div>
    <div class="col-md-4">
        <mat-card class="single-stats-box tagus-card mb-25">
            <div class="d-flex align-items-center">
                <div class="icon">
                    <i class="flaticon-goal"></i>
                </div>
                <div class="title">
                    <span class="d-block gray-color">Total Orders</span>
                    <h3>155K</h3>
                </div>
            </div>
            <p class="position-relative up"><i class="flaticon-trend"></i> <span>7.5%</span> vs. previous month</p>
        </mat-card>
    </div>
    <div class="col-md-4">
        <mat-card class="single-stats-box tagus-card mb-25">
            <div class="d-flex align-items-center">
                <div class="icon">
                    <i class="flaticon-award"></i>
                </div>
                <div class="title">
                    <span class="d-block gray-color">Total Earning</span>
                    <h3>$50.5M</h3>
                </div>
            </div>
            <p class="position-relative down"><i class="flaticon-trend-1"></i> <span>3.5%</span> bounce rate</p>
        </mat-card>
    </div>
</div>