import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthStorageService } from "../services/auth-storage.service";

@Injectable({
  providedIn: 'root'
})
export class GuestGuard implements CanActivate {
  constructor(
    private authStorageService: AuthStorageService,
    private router: Router,
  ) {}

  // этот Guard для использования только на страницах аутентификации
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.authStorageService.getUserFromToken();

    return !user;
  }
}
