<mat-form-field appearance="fill" class="example-full-width">
    <mat-label>Choose a date</mat-label>
    <input matInput [matDatepicker]="datepicker">
    <mat-hint class="mt-5">MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
    <mat-datepicker #datepicker>
        <mat-datepicker-actions>
        <button mat-button matDatepickerCancel>Cancel</button>
        <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
        </mat-datepicker-actions>
    </mat-datepicker>
</mat-form-field>