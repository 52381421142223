<div
    class="h-100vh pt-50 pb-50"
    [class.component-dark-theme]="themeService.isDark()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.card-border-radius]="themeService.isCardBorderRadius()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="tagus-form ms-auto me-auto border-top-radius">
                <div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
                    <h5 class="mb-0">Доступні маркети</h5>
                </div>
                <mat-card class="mb-25 tagus-card" *ngFor="let market of markets">
                    <mat-card-content>
                        <h5>{{ market.name }}</h5>
                        <a [routerLink]="['/market', market._id, 'products']" mat-flat-button class="tagus d-block fw-semibold">Перейти в дашборд</a>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>
